import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { xml2js } from 'xml-js';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import * as writtenNumber from 'written-number';
import { ComponentsService } from '../components/components.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class DteService {

    constructor(
        private http: HttpClient,
        public auth: AuthService,
        public components: ComponentsService
    ) {

    }

    async signDte(json: any, type = 'reception') {

        return new Promise(async (resolve, reject) => {
            const certificado = await this.recuperarCertificado(json);
            if (certificado) {

                let postData = {
                    private_key: certificado.privateKey.encodied._text,
                    jsonData: json.dteJson,
                }

                this.http.post(`${environment.api}/sign_json`, postData).subscribe(response => {
                    let signed_json = response['token'];

                    let authPostData = {
                        user: this.auth.account_data.fiscal.nit,
                        pwd: this.auth.account_data.fiscal.passwordMH
                    };


                    this.http.post(`${environment.mh.url}/seguridad/auth?user=${authPostData.user}&pwd=${authPostData.pwd}`, {}).subscribe(response => {
                        const headers = {
                            Authorization: `${response['body']['token']}`,
                        };

                        if (type == 'reception') {
                            this.http.post(`${environment.mh.url}/fesv/recepciondte`, {
                                "ambiente": environment.mh.environment,
                                "idEnvio": "01",
                                "version": json.dteJson.identificacion.version,
                                "tipoDte": json.dteJson.identificacion.tipoDte,
                                "documento": signed_json,
                                "codigoGeneracion": json.dteJson.identificacion.codigoGeneracion
                            }, { headers: headers }).subscribe(response => {
                                resolve(response);
                            }, err => {
                                reject({ error: err, msg: err.error.descripcionMsg });
                                // reject({ error: err, msg: "NO SE HA PROPORCIONADO DATOS PARA VALIDAR" });
                            })
                        } else if (type == 'invalidation') {
                            this.http.post(`${environment.mh.url}/fesv/anulardte`, {
                                "ambiente": environment.mh.environment,
                                "idEnvio": "02",
                                "version": json.dteJson.identificacion.version,
                                "documento": signed_json,
                            }, { headers: headers }).subscribe(response => {
                                resolve(response);
                            }, err => {
                                reject({ error: err, msg: err.error.descripcionMsg });
                            })
                        } else if (type == 'contingency') {
                            this.http.post(`${environment.mh.url}/fesv/contingencia`, {
                                "nit": this.auth.account_data.fiscal.nit,
                                "documento": signed_json,
                            }, { headers: headers }).subscribe(response => {
                                resolve(response);
                            }, err => {
                                reject({ error: err, msg: err.error.descripcionMsg });
                            })
                        }
                    }, err => {
                        reject({ error: err, msg: '' });
                    });

                }, err => {
                    reject({ error: err, msg: '' });
                })
            } else {
                reject({ error: '', msg: 'La contrase�a no es v�lida o el certificado no se pudo recuperar' });
            }
        })
    }

    encrypt(p: string): string {
        const diget = CryptoJS.algo.SHA512.create();
        diget.update(p);
        const encodedhash = diget.finalize();
        return this.bytesToHex(encodedhash);
    }

    private bytesToHex(hash: any): string {
        let hexString = '';
        for (let i = 0; i < hash.words.length; i++) {
            const word = hash.words[i];
            const byteLen = 4;
            for (let j = 0; j < byteLen; j++) {
                const byte = (word >> (24 - j * 8)) & 0xff;
                const hex = byte.toString(16).padStart(2, '0');
                hexString += hex;
            }
        }
        return hexString;
    }

    async recuperarCertificado(json: any): Promise<any | null> {
        try {
            const xmlOptions = { compact: true, spaces: 4 }; // Opciones para la conversi�n XML a JS
            const crypto = this.encrypt(json.passwordPri);
            const contenido = this.auth.account_data.fiscal.certificate;
            const certificadoObj: any = xml2js(contenido, xmlOptions);

            const certificado: any = certificadoObj.CertificadoMH;

            if (certificado.privateKey.clave._text === crypto) {
                return certificado;
            } else {
                console.log(`Password no v�lido: ${certificado.nit._text}`);
                return null;
            }
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }


    padZero(num: number): string {
        return num < 10 ? `0${num}` : `${num}`;
    }

    padLeadingZeros(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        if (!isNaN(Number(s))) {
            return s;
        }
    }

    getJson(ticket, items, discounts, payments) {

        return new Promise((resolve, reject) => {
            const currentTime = new Date();
            const hours = this.padZero(currentTime.getHours());
            const minutes = this.padZero(currentTime.getMinutes());

            const seconds = this.padZero(currentTime.getSeconds());
            let formattedTime = `${hours}:${minutes}:${seconds}`;

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = this.padZero(currentDate.getMonth() + 1); // Los meses en JavaScript son base 0
            const day = this.padZero(currentDate.getDate());

            let formattedDate = `${year}-${month}-${day}`;

            let typeDte;
            let version;
            let control_number;
            if (ticket.fiscal.type == 'invoice') {
                typeDte = '01';
                version = 1;
                control_number = `DTE-${typeDte}-${this.auth.branch_data.fiscal.code}${this.auth.device_data.fiscal_code}-${this.padLeadingZeros(String(ticket.fiscal.current_invoice_number), 15)}`;
            } else if (ticket.fiscal.type == 'ccf') {
                typeDte = '03';
                version = 3;
                control_number = `DTE-${typeDte}-${this.auth.branch_data.fiscal.code}${this.auth.device_data.fiscal_code}-${this.padLeadingZeros(String(ticket.fiscal.current_ccf_number), 15)}`;
            }

            let generation_code = uuidv4().toUpperCase();

            ticket.total = this.components.formatCurrency(ticket.total);

            let total_in_words = writtenNumber(ticket.total, { lang: 'es' })

            const partes = ticket.total.toString().split('.');

            const dolares = writtenNumber(partes[0], { lang: 'es' }).toUpperCase();
            const centavos = writtenNumber(partes[1] || '0', { lang: 'es' }).toUpperCase();
            total_in_words = `${dolares.toUpperCase()} D�LARES CON ${centavos.toUpperCase()} CENTAVOS`;

            if (partes[1] == 0 || partes[1] == undefined) {
                total_in_words = `${dolares.toUpperCase()} D�LARES`;
            }

            let json = {
                "nit": this.auth.account_data.fiscal.nit,
                "active": true,
                "passwordPri": this.auth.account_data.fiscal.passwordCert,
                "dteJson": {
                    "identificacion": {
                        "version": version,
                        "ambiente": environment.mh.environment,
                        "tipoDte": typeDte,
                        "numeroControl": control_number,
                        "codigoGeneracion": generation_code,
                        "tipoModelo": 1,
                        "tipoOperacion": 1,
                        "tipoContingencia": null,
                        "motivoContin": null,
                        "fecEmi": formattedDate,
                        "horEmi": formattedTime,
                        "tipoMoneda": "USD"
                    },
                    "emisor": {
                        "nit": this.auth.account_data.fiscal.nit,
                        "nrc": this.auth.account_data.fiscal.nrc,
                        "nombre": this.auth.account_data.fiscal.business_name,
                        "codActividad": String(this.auth.account_data.fiscal.activity.code),
                        "descActividad": this.auth.account_data.fiscal.activity.value,
                        "nombreComercial": null,
                        "tipoEstablecimiento": this.auth.branch_data.fiscal.branch_type,
                        "direccion": {
                            "departamento": String(this.auth.branch_data.fiscal.depto.code),
                            "municipio": String(this.auth.branch_data.fiscal.munic.code),
                            "complemento": String(this.auth.branch_data.address)
                        },
                        "telefono": this.auth.branch_data.phone,
                        "correo": this.auth.account_data.email,
                        "codEstableMH": null,
                        "codEstable": null,
                        "codPuntoVentaMH": null,
                        "codPuntoVenta": null
                    },
                    "receptor": <any>{
                        "tipoDocumento": null,
                        "numDocumento": null,
                        "nombre": 'Consumidor Final',
                        "correo": null,
                        "nrc": null, //Opcional null
                        "telefono": null, //Opcional null
                        "codActividad": null, //Opcional null
                        "descActividad": null, //Opcional null
                        "direccion": null, //Opcional null
                    },
                    "cuerpoDocumento": [],
                    "resumen": {},
                    "documentoRelacionado": null,
                    "otrosDocumentos": null,
                    "ventaTercero": null,
                    "extension": null,
                    "apendice": null
                }
            }

            let descuentos = 0;

            if (discounts) {
                discounts.forEach(discount => {
                    descuentos += discount.total_discount
                    if (ticket.fiscal.type == 'invoice') {
                        descuentos += discount.iva_discount;
                    }
                });
            };

            let resumen;

            if (ticket.fiscal.type == 'invoice') {

                let total_iva = ticket.hidden_taxes - ticket.exempt;

                if (total_iva < 0) {
                    total_iva = 0;
                }
                
                resumen = {
                    "totalNoSuj": 0,
                    "totalExenta": 0,
                    "totalGravada": this.components.fixed(ticket.subtotal + ticket.hidden_taxes + descuentos + ticket.iva_rete),
                    "subTotalVentas": this.components.fixed(ticket.subtotal + ticket.hidden_taxes - ticket.exempt + descuentos + ticket.iva_rete),
                    "descuNoSuj": 0,
                    "descuExenta": 0,
                    "descuGravada": this.components.fixed(descuentos),
                    "porcentajeDescuento": 0,
                    "totalDescu": this.components.fixed(descuentos),
                    "tributos": null,
                    "subTotal": this.components.fixed(ticket.subtotal + ticket.hidden_taxes - ticket.exempt + ticket.iva_rete),
                    "ivaRete1": ticket.iva_rete,
                    "reteRenta": 0,
                    "montoTotalOperacion": this.components.fixed(ticket.subtotal + ticket.hidden_taxes - ticket.exempt + ticket.iva_rete),
                    "totalNoGravado": this.components.fixed(ticket.tip),
                    "totalPagar": this.components.fixed(Number(ticket.total)),
                    "totalLetras": total_in_words,
                    "totalIva": this.components.fixed(total_iva),
                    "condicionOperacion": 1,
                    "pagos": [],
                    "saldoFavor": 0,
                    "numPagoElectronico": null
                }


                if (ticket.iva_rete > 0) {
                    resumen['ivaRete1'] = ticket.iva_rete;
                }

                if (ticket.client !== undefined) {
                    if (ticket.client.type == 'regular') {
                        json.dteJson.receptor = {
                            "tipoDocumento": ticket.client.document_type.code ? String(ticket.client.document_type.code) : null,
                            "numDocumento": ticket.client.document ? ticket.client.document : null,
                            "nombre": ticket.client.name, //Opcional null
                            "correo": ticket.client.email,
                            "nrc": null, //Opcional null
                            "telefono": null, //Opcional null
                            "codActividad": null, //Opcional null
                            "descActividad": null, //Opcional null
                            "direccion": null, //Opcional null
                        }
                    } else {
                        json.dteJson.receptor = {
                            "tipoDocumento": "36",
                            "numDocumento": ticket.client.nit,
                            "nombre": ticket.client.name, //Opcional null
                            "correo": ticket.client.email,
                            "nrc": null, //Opcional null
                            "telefono": null, //Opcional null
                            "codActividad": null, //Opcional null
                            "descActividad": null, //Opcional null
                            "direccion": null, //Opcional null
                        }

                    }
                }

                if (ticket.exempt > 0) {
                    resumen.totalGravada = 0;
                    resumen.totalExenta = this.components.fixed(ticket.subtotal + descuentos);
                    resumen.montoTotalOperacion = this.components.fixed(ticket.subtotal);
                    resumen.subTotal = this.components.fixed(ticket.subtotal);
                    resumen.descuGravada = 0;
                    resumen.descuExenta = this.components.fixed(descuentos);
                    resumen.totalDescu = this.components.fixed(descuentos);
                }
            } else if (ticket.fiscal.type == 'ccf') {

                resumen = {
                    "totalNoSuj": 0,
                    "totalExenta": 0,
                    "totalGravada": this.components.fixed(ticket.subtotal + descuentos + ticket.iva_rete),
                    "subTotalVentas": this.components.fixed(ticket.subtotal + descuentos + ticket.iva_rete),
                    "descuNoSuj": 0,
                    "descuExenta": 0,
                    "descuGravada": this.components.fixed(descuentos),
                    "porcentajeDescuento": 0,
                    "totalDescu": this.components.fixed(descuentos),
                    "subTotal": this.components.fixed(ticket.subtotal + ticket.iva_rete),
                    "ivaPerci1": 0,
                    "ivaRete1": ticket.iva_rete,
                    "reteRenta": 0,
                    "montoTotalOperacion": this.components.fixed(ticket.subtotal + ticket.hidden_taxes + ticket.taxes - ticket.exempt + ticket.iva_rete),
                    "totalNoGravado": this.components.fixed(ticket.tip),
                    "totalPagar": this.components.fixed(ticket.subtotal + ticket.tip + ticket.hidden_taxes + ticket.taxes - ticket.exempt),
                    "totalLetras": total_in_words,
                    "saldoFavor": 0,
                    "condicionOperacion": 1,
                    "pagos": [],
                    "numPagoElectronico": null,
                    "tributos": null
                }

                if (ticket.exempt == 0) {
                    resumen['tributos'] = [{
                        "codigo": "20",
                        "descripcion": "Impuesto al Valor Agregado 13%",
                        "valor": this.components.fixed(ticket.hidden_taxes + ticket.taxes)
                    }]
                } else {
                    resumen.totalExenta = this.components.fixed(ticket.subtotal + descuentos)
                    resumen.totalGravada = 0;
                    resumen.montoTotalOperacion = this.components.fixed(ticket.subtotal);
                    resumen.subTotal = this.components.fixed(ticket.subtotal);
                    resumen.descuGravada = 0;
                    resumen.descuExenta = this.components.fixed(descuentos);
                    resumen.totalDescu = this.components.fixed(descuentos);

                }

                if (ticket.client == undefined) {
                    //2DO agregar excepcion
                    console.log('no se puede pasar debe de agregar cliente de ccf');
                } else {
                    json.dteJson.receptor = {
                        "nrc": ticket.client.nrc,
                        "nit": ticket.client.nit,
                        "nombre": ticket.client.name,
                        "codActividad": String(ticket.client.activity.code),
                        "descActividad": ticket.client.activity.value,
                        "correo": ticket.client.email,
                        "nombreComercial": ticket.client.name,
                        "direccion": {
                            "departamento": String(ticket.client.depto.code),
                            "municipio": String(ticket.client.munic.code),
                            "complemento": String(ticket.client.address)
                        },
                        "telefono": ticket.client.phone
                    }
                }
            }

            let counter_items = 1;

            items.forEach((item, index) => {
                let element_item;

                let name = item.name;
                if (item.is_promotion) {
                    name = item.promotion.name;
                }
                if (item.is_variant) {
                    name = `${item.parent.name}-${item.name}`;
                }

                if (ticket.fiscal.type == 'invoice') {
                    element_item = {
                        "numItem": counter_items++,
                        "tipoItem": 1,
                        "cantidad": item.quantity,
                        "codigo": null,
                        "uniMedida": 59,
                        "descripcion": name,
                        "precioUni": this.components.fixed(item.price),
                        "montoDescu": 0,
                        "ventaNoSuj": 0,
                        "ventaExenta": 0,
                        "ventaGravada": this.components.fixed((item.price * item.quantity)),
                        "tributos": null,
                        "psv": 0,
                        "noGravado": 0,
                        "numeroDocumento": null,
                        "codTributo": null,
                        "ivaItem": this.components.fixed(item.hidden_taxes)
                    }

                    if (ticket.exempt > 0) {
                        let taxes = item.hidden_taxes / item.quantity;
                        let exempt_price = this.components.fixed(item.price - taxes);
                        element_item.ivaItem = 0;
                        element_item.precioUni = this.components.fixed(exempt_price);
                        element_item.ventaGravada = 0;
                        element_item.ventaExenta = this.components.fixed(exempt_price * item.quantity);
                    }

                } else if (ticket.fiscal.type == 'ccf') {

                    let taxes = item.hidden_taxes / item.quantity;
                    let price = item.price - taxes;
                    price = price;

                    element_item = {
                        "numItem": counter_items++,
                        "tipoItem": 1,
                        "numeroDocumento": null,
                        "codigo": null,
                        "codTributo": null,
                        "descripcion": name,
                        "cantidad": item.quantity,
                        "uniMedida": 59,
                        "precioUni": parseFloat(price.toFixed(3)),
                        "montoDescu": 0,
                        "ventaNoSuj": 0,
                        "ventaExenta": 0,
                        "ventaGravada": parseFloat((Number(price) * Number(item.quantity)).toFixed(3)),
                        "tributos": ["20"],
                        "psv": 0,
                        "noGravado": 0
                    }


                    if (ticket.exempt > 0) {
                        let taxes = item.hidden_taxes / item.quantity;
                        let exempt_price = this.components.fixed(item.price - taxes);
                        element_item.tributos = null;
                        element_item.ventaGravada = 0;
                        element_item.ventaExenta = this.components.fixed(exempt_price * item.quantity);
                    }
                }

                json.dteJson.cuerpoDocumento.push(element_item)
            });

            if (ticket.tip > 0) {
                if (ticket.fiscal.type == 'invoice') {
                    json.dteJson.cuerpoDocumento.push({
                        "numItem": counter_items++,
                        "tipoItem": 2,
                        "cantidad": 1,
                        "codigo": null,
                        "uniMedida": 99,
                        "descripcion": 'Propina',
                        "precioUni": 0,
                        "montoDescu": 0,
                        "ventaNoSuj": 0,
                        "ventaExenta": 0,
                        "ventaGravada": 0,
                        "tributos": null,
                        "psv": 0,
                        "noGravado": this.components.fixed(ticket.tip),
                        "ivaItem": 0,
                        "numeroDocumento": null,
                        "codTributo": null
                    })
                } else if (ticket.fiscal.type == 'ccf') {
                    json.dteJson.cuerpoDocumento.push({
                        "numItem": counter_items++,
                        "tipoItem": 2,
                        "numeroDocumento": null,
                        "codigo": null,
                        "codTributo": null,
                        "descripcion": 'Propina',
                        "cantidad": 1,
                        "uniMedida": 99,
                        "precioUni": 0,
                        "montoDescu": 0,
                        "ventaNoSuj": 0,
                        "ventaExenta": 0,
                        "ventaGravada": 0,
                        "tributos": null,
                        "psv": 0,
                        "noGravado": this.components.fixed(ticket.tip)
                    });
                }
            }

            payments.forEach(payment => {

                let payment_code = '01';

                if (payment.method == 'card') {
                    payment_code = '02';
                } else if (payment.method == 'check') {
                    payment_code = '04';
                } else if (payment.method == 'transfer') {
                    payment_code = '05';
                } else if (payment.method == 'giftcard') {
                    payment_code = '99';
                }

                resumen['pagos'].push({
                    "montoPago": payment.total,
                    "referencia": null,
                    "codigo": payment_code,
                    "plazo": null,
                    "periodo": null
                })
            });

            if (ticket.type == 'delivery' || ticket.was_credit == true) {
                resumen.condicionOperacion = 2;
                resumen.pagos = null;
            }
            json.dteJson.resumen = resumen;

            resolve(json)
        })
    }

    invalidationJson(ticket, note, doc) {
        return new Promise((resolve, reject) => {
            const currentTime = new Date();
            const hours = this.padZero(currentTime.getHours());
            const minutes = this.padZero(currentTime.getMinutes());

            const seconds = this.padZero(currentTime.getSeconds());
            let formattedTime = `${hours}:${minutes}:${seconds}`;

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = this.padZero(currentDate.getMonth() + 1); // Los meses en JavaScript son base 0
            const day = this.padZero(currentDate.getDate());

            let formattedDate = `${year}-${month}-${day}`;

            let generation_code = uuidv4().toUpperCase();
            let json = {
                "nit": this.auth.account_data.fiscal.nit,
                "active": true,
                "passwordPri": this.auth.account_data.fiscal.passwordCert,
                "dteJson": {
                    "identificacion": {
                        "version": 2,
                        "ambiente": environment.mh.environment,
                        "codigoGeneracion": generation_code,
                        "fecAnula": formattedDate,
                        "horAnula": formattedTime,
                    },
                    "emisor": {
                        "nit": this.auth.account_data.fiscal.nit,
                        "nombre": this.auth.account_data.fiscal.business_name,
                        "tipoEstablecimiento": this.auth.branch_data.fiscal.branch_type,
                        "telefono": this.auth.branch_data.phone,
                        "correo": this.auth.account_data.email,
                        "codEstable": this.auth.branch_data.fiscal.code,
                        "codPuntoVenta": this.auth.device_data.fiscal_code,
                        "nomEstablecimiento": this.auth.branch_data.name
                    },
                    "documento": {
                        "tipoDte": ticket.fiscal.dte.json.identificacion.tipoDte,
                        "codigoGeneracion": ticket.fiscal.dte.json.identificacion.codigoGeneracion,
                        "selloRecibido": ticket.fiscal.dte.selloRecibido,
                        "numeroControl": ticket.fiscal.dte.json.identificacion.numeroControl,
                        "fecEmi": ticket.fiscal.dte.json.identificacion.fecEmi,
                        "montoIva": ticket.hidden_taxes,
                        "codigoGeneracionR": null, //Esto solamente si se reemplaza por algun otro
                        "tipoDocumento": null,
                        "numDocumento": null,
                        "nombre": null,
                    },
                    "motivo": {
                        "tipoAnulacion": 2,
                        "motivoAnulacion": note,
                        "nombreResponsable": this.auth.account_data.user.name,
                        "tipDocResponsable": String(doc.document_type.code),
                        "numDocResponsable": String(doc.document),
                        "nombreSolicita": String(ticket.client.name),
                    }
                }
            }

            if (ticket.client.legal_person) {
                json.dteJson.motivo['tipDocSolicita'] = '36';
                json.dteJson.motivo['numDocSolicita'] = String(ticket.client.nit);
            } else {
                json.dteJson.motivo['tipDocSolicita'] = (!ticket.client.document_type.code) ? '' : String(ticket.client.document_type.code);
                json.dteJson.motivo['numDocSolicita'] = (!ticket.client.document) ? '' : String(ticket.client.document);
            }

            if (ticket.fiscal.dte.json.receptor) {
                let tipoDocumento;
                let numDocumento;

                if (ticket.fiscal.dte.json.receptor.nit) {
                    tipoDocumento = '36'
                    numDocumento = ticket.fiscal.dte.json.receptor.nit;
                } else {
                    tipoDocumento = ticket.fiscal.dte.json.receptor.tipoDocumento;
                    numDocumento = ticket.fiscal.dte.json.receptor.numDocumento;
                }
                json.dteJson.documento.tipoDocumento = tipoDocumento;
                json.dteJson.documento.numDocumento = numDocumento;
            }

            resolve(json);
        })
    }

    // sendContingency(ticket) {
    //     return new Promise(async (resolve, reject) => {

    //     })
    // }

}