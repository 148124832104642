import { EventEmitter, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Plugins } from "@capacitor/core";
import { AlertController, GestureController, LoadingController, ModalController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { Timestamp } from 'firebase/firestore';
import 'moment-timezone';
import * as moment from 'moment-timezone';
import { TicketComponent } from 'src/app/components/pos/ticket/ticket.component';
import { setDecimals } from 'src/app/utils/decimal';
import { SelectTypeComponent } from '../../components/shared/select-type/select-type.component';
const { Network } = Plugins;
@Injectable({
	providedIn: 'root'
})
export class ComponentsService {

	public ChangeFilterEvent: EventEmitter<number> = new EventEmitter();
	private loading;
	isToastPresent = false;
	isModalPresent = false;
	small_sidebar: any = true;
	isDesktop: any;
	dates: any = [];
	isPortraitMode: boolean;

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		public modalController: ModalController,
		private loadingCtrl: LoadingController,
		private toastCtrl: ToastController,
		public popoverController: PopoverController,
		public platform: Platform,
		public alertController: AlertController,
		private gestureController: GestureController
	) {
		this.setInitDates();
	}

	setInitDates() {
		let date_1: any = new Date();
		let date_2: any = new Date();
		date_1.setHours(0, 0, 0, 0);
		date_2.setHours(23, 59, 59, 999);
		this.dates = [date_1, date_2];
	}

	changeSidebarSize() {
		this.isPortraitMode = this.platform.isPortrait()
		this.small_sidebar = !this.small_sidebar;
	}

	openTicket() {
		this.modalController.create({
			component: TicketComponent,
			cssClass: 'ticket-modal',
			animated: true,
			backdropDismiss: true
		}).then(modal => {
			modal.present();
		});
	}

	showAlert(title, message, buttons) {
		this.alertController.create({
			header: title,
			message: message,
			buttons: buttons
		}).then(alert => alert.present());
	}

	showLoader(message = 'Cargando...', colorClass = '') {
		return new Promise((resolve, reject) => {
			if (!this.loading) {
				this.loadingCtrl.create({
					message: message,
					cssClass: 'quanto-loader ' + colorClass,
					spinner: 'circular'
				}).then(res => {
					this.loading = res;
					this.loading.present();
					resolve(true);
				})
			}
		})
	}

	dismissLoader() {
		if (this.loading) {
			this.loading.dismiss();
			this.loading = null;
		}
	}

	isApp() {
		if (this.platform.width() > 767) {
			return false;
		} else {
			return true;
		}
	}

	buttonLoad(ev) {
		ev.srcElement.innerHTML += '<ion-spinner name="crescent"></ion-spinner>';
		ev.srcElement.setAttribute('loading', 'true');
	}

	dismissButtonLoad(ev) {
		let text = ev.srcElement.innerText;
		ev.srcElement.setAttribute('loading', 'false');
		ev.srcElement.innerHTML = text;
	}

	async selectType() {
		return new Promise(async (resolve, reject) => {
			const modal = await this.modalController.create({
				component: SelectTypeComponent,
				cssClass: 'type-modal',
				showBackdrop: true,
				backdropDismiss: false,
				componentProps: {
					is_pos: true
				}
			});

			modal.onDidDismiss().then(data => {
				if (data.data) {
					resolve(data.data);
				}
			})

			await modal.present();
		})
	}

	decimalOnly(event): boolean {
		let pattern = /^[0-9]*\.?[0-9]*$/;
		let result = pattern.test(event.key);
		return result;
	}

	numericOnly(event): boolean {
		let pattern = /^[0-9]*$/;
		let result = pattern.test(event.key);
		return result;
	}

	async showToast(message, type: any = 'success', duration: number = 2000) {
		if (!this.isToastPresent) {
			let icon = '';
			if (type == 'success') {
				icon = 'checkmark-circle';
			} else {
				icon = 'close-circle';
			}
			this.isToastPresent = true;
			const toast = await this.toastCtrl.create({
				message: message,
				cssClass: type,
				icon: icon,
				position: 'top',
				duration: 2000,
				htmlAttributes: { tabindex: undefined }
			});

			this.enableSwipeToClose(toast, 'y');

			await toast.present();
			await toast.onDidDismiss().then(() => (this.isToastPresent = false));
		}
	}

	enableSwipeToClose(element, direction) {
		const gesture = this.gestureController.create({
			el: element,
			gestureName: 'swipe-to-close',
			direction: direction,
			onEnd: () => {
				element.dismiss();
			},
		});

		gesture.enable();
	}

	preventTouch(ev: any) {
		ev.preventDefault();
		ev.stopPropagation();
		ev.stopImmediatePropagation();
	}

	checkValidate(form, value) {
		if (form.controls[value].value != '') {
			return form.controls[value].status;
		}
	}

	scrollTo(id, behavior: any = 'smooth') {
		setTimeout(() => {
			document.getElementById(id).scrollIntoView({
				behavior,
				block: 'center',
				inline: 'nearest'
			});
		}, 200);
	}

	convertToHours(date) {
		return date.toLocaleTimeString('en-GB');
	}

	convertToDate(date) {
		return `${new Date(date).getDate()}/${new Date(date).getMonth() + 1}/${new Date(date).getFullYear()}`;
	}

	/**
	 * It takes the first and last dates of the current date range, adds or subtracts the number of days in the range, and then sets the new dates as the first and
	 * last dates of the new date range
	 * @param value - The value of the button that was clicked.
	 */
	changeDate(value, datetime) {
		if (value) {
			let dates = this.dates;
			this.dates = [];
			const oneDay = 24 * 60 * 60 * 1000;
			let num_days = Math.round(Math.abs((dates[0] - dates[1]) / oneDay));

			if (value == 'next') {
				dates[0].setDate(dates[0].getDate() + num_days);
				dates[1].setDate(dates[1].getDate() + num_days);
			} else {
				dates[0].setDate(dates[0].getDate() - num_days);
				dates[1].setDate(dates[1].getDate() - num_days);
			}

			const origenTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const localTimezone: string = 'America/El_Salvador';

			const originFirstDay: moment.Moment = moment(dates[0]).tz(origenTimezone);
			const localFirstDay: moment.Moment = originFirstDay.clone().tz(localTimezone);
			dates[0] = new Date(localFirstDay.format('YYYY-MM-DD HH:mm:ss'))

			const originLastDay: moment.Moment = moment(dates[1]).tz(origenTimezone);
			const localLastDay: moment.Moment = originLastDay.clone().tz(localTimezone);
			dates[1] = new Date(localLastDay.format('YYYY-MM-DD HH:mm:ss'))

			// dates[0] = firebase.firestore.Timestamp.fromDate(dates[0])
			// dates[1] = firebase.firestore.Timestamp.fromDate(dates[1])

			setTimeout(() => {
				this.dates = dates;
				this.ChangeFilterEvent.emit();
			}, 0.1);
		} else {
			if (datetime['old_dates'] !== this.dates) {
				this.dates[0].setHours(0, 0, 0, 0);
				this.dates[1].setHours(23, 59, 59, 999);

				const origenTimezone: string = 'America/El_Salvador';
				const localTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

				const originFirstDay: moment.Moment = moment(this.dates[0]).tz(origenTimezone);
				const localFirstDay: moment.Moment = originFirstDay.clone().tz(localTimezone);
				this.dates[0] = new Date(localFirstDay.format('YYYY-MM-DD HH:mm:ss'))

				const originLastDay: moment.Moment = moment(this.dates[1]).tz(origenTimezone);
				const localLastDay: moment.Moment = originLastDay.clone().tz(localTimezone);
				this.dates[1] = new Date(localLastDay.format('YYYY-MM-DD HH:mm:ss'))

				this.dates[0] = Timestamp.fromDate(this.dates[0]).toDate();
				this.dates[1] = Timestamp.fromDate(this.dates[1]).toDate();
				this.ChangeFilterEvent.emit();
			}
		}
	}

	fixed(numberToConvert) {
		return parseFloat(numberToConvert.toFixed(2));
	}


	formatCurrency(valor: number): any {
		if (valor !== undefined) {
			const valorFormateado = valor.toLocaleString(this.locale, {
				style: 'currency',
				currency: 'USD',
			});
			return Number(valorFormateado.replace(/[\$,]/g, ''));
		} else {
			return Number(0);
		}
	}

	dateToString(date) {
		return `${String(date.getDate())}/${String(date.getMonth() + 1)}/${String(date.getFullYear())}`;
	}

	getTaxes(item, tax_selected) {
		if (!item.hidden_taxes) item.hidden_taxes = 0;

		if (!tax_selected.included) {
			let taxes = (item.price) * (Number(tax_selected.rate) / 100);

			item.hidden_taxes += taxes * Number(item.quantity);
			item.price = item.price + taxes;

		} else {
			let tax = (item.price) / ((Number(tax_selected.rate) * 0.01) + 1);
			tax = (item.price) - tax;

			if (!tax_selected.value) {
				tax_selected.value = 0;
			}
			tax_selected.value = tax;

			item.hidden_taxes += tax * Number(item.quantity);
			item.real_price = item.price - item.hidden_taxes;
			item.real_price = setDecimals(item.real_price);
		}

		tax_selected.included = true;
	}


	parseTicketNumber(country, ticket_number) {
		// Extraemos las partes del string original

		if (country == 'Honduras') {
			ticket_number = String(ticket_number);
			ticket_number = '00000' + ticket_number;
			const part1 = String(ticket_number).slice(0, 3);  // Extrae los primeros tres caracteres
			const part2 = String(ticket_number).slice(3, 6);  // Extrae los siguientes tres caracteres
			const part3 = String(ticket_number).slice(6, 8);  // Extrae los siguientes dos caracteres
			const part4 = String(ticket_number).slice(8);     // Extrae el resto del string

			// Concatenamos las partes con los guiones para formatear
			const formatted = `${part1}-${part2}-${part3}-${part4}`;
			return formatted;
		} else {
			return ticket_number;
		}
	}

}
