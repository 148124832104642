
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globalization } from '@ionic-native/globalization/ngx';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class TranslateConfigService {

    constructor(
        private translate: TranslateService,
        private globalization: Globalization,
        public auth: AuthService
    ) { }

    getDeviceLanguage() {
        return new Promise((resolve, reject) => {
            this.globalization.getPreferredLanguage().then(res => {
                let lang = res.value.substr(0, res.value.indexOf('-'))
                console.log(lang);

                resolve(lang);
            }).catch(e => {
                reject(e);
            });
        })

    }

    getDefaultLanguage() {
        let language = this.translate.getBrowserLang();
        this.translate.setDefaultLang(language);
        return language;
    }

    setLanguage(setLang) {
        this.translate.use(setLang);
    }

    getTranslation(word: string) {
        let lang_word = `${word}.${this.auth.branch_data.country}`;
        const translation = this.translate.instant(lang_word);
        return translation !== lang_word ? translation : word;
    }

}
