import { Component, OnInit } from '@angular/core';
import { AlertController, IonNav, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { AddNoteComponent } from '../add-note/add-note.component';
import { CancelNotesComponent } from '../cancel-notes/cancel-notes.component';
import { ModifyPriceComponent } from '../modify-price/modify-price.component';
import { AddDiscountComponent } from '../add-discount/add-discount.component';

@Component({
	selector: 'app-edit-item',
	templateUrl: './edit-item.component.html',
	styleUrls: ['./edit-item.component.scss'],
})
export class EditItemComponent implements OnInit {

	item: any = {};
	order: any;
	item_key: any;
	new_quantity: any = 0;
	already_in_order: any = false;
	updated: any = false;
	can_cancel: any = true;
	notes: any;
	hidden_taxes: any = 0;

	constructor(
		public modalController: ModalController,
		private nav: IonNav,
		public alertController: AlertController,
		public navParams: NavParams,
		private api: ApiService,
		public components: ComponentsService,
		public auth: AuthService,
		public pos: PosService
	) {
		let params = JSON.parse(JSON.stringify(navParams.data));
		this.already_in_order = params.already_in_order;

		if (Object.keys(params).length > 0) {
			this.item = params.item;

			this.hidden_taxes = Number((JSON.parse(JSON.stringify(navParams.data.item.hidden_taxes)) / navParams.data.item.quantity));

			if (this.item.notes) this.notes = this.item.notes;

			this.order = params.order;
			if (this.order) {
				if (this.order.items.length == 1) {
					this.can_cancel = false;
				}
			}

			if (!this.item.miscellaneous && !this.item.is_promotion) {
				this.api.getRef(`accounts/${this.auth.account}/items_branches`).ref
					.doc(this.item.item_branch.$key)
					.onSnapshot(snapshot => {
						this.item.item_branch = snapshot.data();
						this.item.item_branch.$key = snapshot.id;
					}, err => {
						console.log(err);
					})
			}
		}
	}

	ngOnInit() {
	}

	addQuantity() {
		this.updated = true;

		if (this.item.miscellaneous) {
			this.item.quantity = Number(this.item.quantity) + 1;
		} else {
			if (this.item.inventory_management) {
				if (this.item.out_stock) {
					this.item.quantity = Number(this.item.quantity) + 1;
				} else {
					if (this.item.quantity < this.item.item_branch.quantity) {
						this.item.quantity = Number(this.item.quantity) + 1;
					}
				}
			} else {
				this.item.quantity = Number(this.item.quantity) + 1;
			}
		}

		this.item.hidden_taxes = this.hidden_taxes * this.item.quantity;
	}

	decreaseQuantity() {
		this.updated = true;

		if (this.item.quantity > 1) {
			this.item.quantity = Number(this.item.quantity) - 1;
		}

		this.item.hidden_taxes = this.hidden_taxes * this.item.quantity;
	}

	openAddNote() {
		this.nav.push(AddNoteComponent, {
			level: 1,
			item: this.item
		});
	}

	openModifyPrice() {
		this.nav.push(ModifyPriceComponent, {
			level: 1,
			item: this.item
		});
	}

	openDiscounts() {
		this.nav.push(AddDiscountComponent, {
			level: 1,
			item: this.item,
			already_in_order: this.already_in_order
		});
	}

	async removeItem() {
		const alert = await this.alertController.create({
			header: 'Borrar producto',
			message: `�Seguro que deseas borrar el producto ${this.item.parent ? this.item.parent.name : this.item.name}?, esta acci�n no se puede revertir.`,
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel'
				},
				{
					text: 'Borrar',
					role: 'destructive',
					handler: () => {
						this.item.current_quantity = this.navParams.data.quantity;
						this.pos.removeItem(this.item);
						this.modalController.dismiss();
					}
				}
			],
		});
		await alert.present();
	}

	async cancelItem() {
		if (this.can_cancel) {
			this.nav.push(CancelNotesComponent, {
				level: 1,
				item: this.item,
				order: this.order,
				type: 'return_item'
			});
		} else {
			this.components.showAlert('Anular orden', `Este es el �nico �tem en la cuenta, si lo anulas, se anular� la cuenta completa.`,
				[
					{
						text: 'Cancelar',
						role: 'cancel'
					},
					{
						text: 'Anular orden',
						role: 'destructive',
						handler: () => {
							this.nav.push(CancelNotesComponent, {
								level: 1,
								order: this.order,
								type: 'order'
							});
						}
					}
				])
		}
	}

	updateItem() {
		if (this.already_in_order) {
			let item = JSON.parse(JSON.stringify(this.item));
			item.notes = this.notes;
			item.hidden_taxes = this.hidden_taxes * this.item.quantity;

			delete item.$key;
			this.api.updateDocument(`accounts/${this.auth.account}/ticket_items`, this.item.$key, item).then(data => {
				this.modalController.dismiss();
				this.components.showToast('Producto actualizado correctamente.');
			}, err => {
				console.log(err);
			})
		} else {
			console.log(this.item);
			this.item.quantity = Number(this.item.quantity);
			this.item.hidden_taxes = this.hidden_taxes * this.item.quantity;
			this.components.showToast('Producto actualizado correctamente.');
			this.pos.updateItem(this.item, this.notes);
			this.modalController.dismiss();
		}
	}
}
