import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonItemSliding, ModalController, PopoverController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { PrintService } from 'src/app/services/print/print.service';
import { PaymentMethodComponent } from '../../payments/payment-method/payment-method.component';
import { SuccessPaymentComponent } from '../../payments/success-payment/success-payment.component';
import { ModalBaseComponent } from '../../shared/modal-base/modal-base.component';
import { NumberComponent } from '../../tables/number/number.component';
import { ClientsComponent } from '../clients/clients.component';
import { EditItemComponent } from '../edit-item/edit-item.component';
import { TicketOptionsComponent } from '../ticket-options/ticket-options.component';
import { TransferTicketsComponent } from './../../tables/transfer-tickets/transfer-tickets.component';
import { DeliveriesComponent } from '../deliveries/deliveries.component';
import { EditClientComponent } from '../../clients/edit-client/edit-client.component';
import { deleteField } from 'firebase/firestore';
@Component({
    selector: 'app-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.scss'],
})

export class TicketComponent implements OnInit, OnChanges {
    @ViewChild(IonItemSliding) item_slides: IonItemSliding;
    @Input() order_pos: any;
    branch: any;
    order: any;
    total: any = 0;
    total_payments: any = 0;
    payments: any = [];
    client: any = false;
    loading: any = true;
    tip: any = 0;
    taxes: any = 0;
    iva: any = 0;
    gran_discount: any;
    hidden_taxes: any = 0;
    open_payment_modal = false;
    iva_discount: any = 0;
    iva_rete: any = 0;
    discount_card: any = null;
    totalWithoutDiscount = 0;
    new_order: any = false;

    constructor(
        public popoverController: PopoverController,
        public modalController: ModalController,
        public api: ApiService,
        public auth: AuthService,
        public components: ComponentsService,
        public router: Router,
        public actvRoute: ActivatedRoute,
        public alertController: AlertController,
        public pos: PosService,
        public printer: PrintService,
        public platform: Platform
    ) {

        /* This is a subscription to an event that is triggered when an item is added to the ticket. */
        this.pos.AddItemEvent.subscribe((x) => {
            this.getTotal();
        });

        /* This is a subscription to an event that is triggered when an item is updated to the ticket. */
        this.pos.UpdateItemEvent.subscribe((x) => {
            this.getTotal();
        });

        /* This is a subscription to an event that is triggered when an item is removed from the ticket. */
        this.pos.removeItems.subscribe((x) => {
            this.getTotal();
        });

        /* This is a subscription to an event that is triggered when an item is removed from the ticket. */
        this.pos.changeFiscalType.subscribe((fiscal_type) => {
            if (fiscal_type) {
                this.order.tax_receipt = fiscal_type;
            }
        });

        this.pos.changeFiscalType.subscribe((x) => {
            this.getTotal();
        });

        this.pos.cancelOrderEvent.subscribe(async (order_key) => {
            this.discount_card = null;
            this.new_order = false;

            if (!pos.type) {
                this.order = false;
                this.payments
                this.getTotal();
            }
        });
    }

    ngOnInit() {
        this.discount_card = null;
        this.loading = true;
        this.branch = this.auth.branch_data;

        if (!this.order_pos) {
            this.order = false;
            this.pos.client = false;
            this.pos.exempt = false;
            this.loading = false;
            this.getTotal();
        } else {
            this.order = this.order_pos;
            this.order.items = [];
            this.order.discounts = [];
            this.pos.delivery = this.order.delivery;
            this.pos.client = this.order.client;
            this.discount_card = this.order.discount_card;

            if (this.order.client && this.order.client.exempt) {
                this.pos.exempt = true;
            }

            if (this.auth.account_data?.people_confirmation && this.order.type === 'tables' && !this.new_order) this.updatePeople();

            this.api.getRef(`accounts/${this.auth.account}/ticket_items`).ref
                .where('order_key', '==', this.order.$key)
                .onSnapshot((snapshots: any) => {
                    snapshots.docChanges().forEach(element => {
                        if (element.type == 'added') {
                            let item = element.doc.data();
                            item.$key = element.doc.id;
                            if (item.is_promotion) {
                                item.quantity = 1;
                            }
                            if (this.order.items) {
                                const found = this.order.items.findIndex(element => element.$key == item.$key);
                                if (found < 0) {
                                    this.order.items.push(item);
                                    this.pos.items_count += item.quantity;
                                }
                            }
                            if (this.order.items) {
                                this.order.items.sort(function (a, b) {
                                    var orderA = a.creation_date, orderB = b.creation_date;
                                    return orderA - orderB;
                                });
                            }
                        } else if (element.type == 'modified') {
                            let item = element.doc.data();
                            item.$key = element.doc.id;
                            const found = this.order.items.findIndex(element => element.$key == item.$key);
                            this.order.items[found] = item;
                            this.getTotal();
                        } else if (element.type == 'removed') {
                            let item = element.doc.data();
                            item.$key = element.doc.id;
                            const found = this.order.items.findIndex(element => element.$key == item.$key);
                            this.order.items.splice(found, 1);
                            this.getTotal();
                        }
                    });

                    this.getTotal();
                    this.pos.order = this.order;
                    this.loading = false;
                });

            this.api.getRef(`accounts/${this.auth.account}/ticket_discounts`).ref
                .where('order_key', '==', this.order.$key)
                .onSnapshot((snapshots: any) => {
                    this.order.discounts = [];
                    snapshots.docChanges().forEach(element => {
                        if (element.type == 'added') {
                            let discount = element.doc.data();
                            discount.$key = element.doc.id;
                            this.order.discounts.push(discount);
                            this.getTotal();
                        } else if (element.type == 'modified') {
                            let discount = element.doc.data();
                            discount.$key = element.doc.id;
                            this.order.discounts[element.oldIndex] = discount;
                            this.getTotal();
                        } else if (element.type == 'removed') {
                            this.order.discounts.splice(element.oldIndex, 1);
                            this.getTotal();
                        }
                    });

                    this.api.getRef(`accounts/${this.auth.account}/ticket_payments`).ref
                        .where('order_key', '==', this.order.$key)
                        .orderBy('creation_date')
                        .onSnapshot((snapshots: any) => {
                            snapshots.docChanges().forEach(element => {
                                if (element.type == 'added') {
                                    let payment = element.doc.data();
                                    payment.$key = element.doc.id;
                                    if (payment.creation_date !== null) {
                                        payment.creation_date = payment.creation_date.toDate();
                                    }
                                    this.payments.push(payment);
                                    this.getTotal();
                                }
                            });

                            if (this.open_payment_modal) {
                                this.open_payment_modal = false;
                                this.openPaymentModal();
                            }
                        }, err => {
                            console.log(err);
                        });
                });

        };
    };

    ngOnChanges(changes: SimpleChanges) {
        if (changes.order_pos && !changes.order_pos.firstChange) {
            this.order_pos = changes.order_pos.currentValue;
            this.ngOnInit();
        };
    };


    getTotal() {

        this.total = 0;
        this.total_payments = 0;
        this.gran_discount = 0;
        this.hidden_taxes = 0;
        this.taxes = 0;
        this.iva = 0;
        this.iva_discount = 0;
        this.pos.exempt = 0;

        let items = [...this.pos.items];
        let discounts = [...this.pos.discounts];
        if (this.order) {
            items = items.concat(this.order.items);
            discounts = discounts.concat(this.order.discounts);
        }
        if ((this.order.client && this.order.client.exempt) || this.pos.client && this.pos.client.exempt) {
            this.pos.exempt = true;
        }

        let item_discounts = 0;

        items.forEach(element => {
            element.quantity = Number(element.quantity);
            item_discounts += element.total_discount || 0;

            if (element.is_promotion) {
                element.x_items.concat(element.y_items).forEach(element_promo => {
                    if (element_promo.is_variant) {
                        if (element_promo.parent.taxes_item) {
                            element_promo.parent.taxes_item.forEach(tax => {
                                if (tax.iva) {
                                    this.iva += this.components.formatCurrency(element_promo.hidden_taxes);
                                }
                                this.hidden_taxes += this.components.formatCurrency(element_promo.hidden_taxes);
                            });
                        }
                    } else {
                        if (element_promo.taxes_item) {
                            element_promo.taxes_item.forEach(tax => {
                                if (tax.iva) {
                                    if (element_promo.hidden_taxes !== undefined) {
                                        this.iva += this.components.formatCurrency(this.components.fixed(element_promo.hidden_taxes));
                                    }
                                }
                                this.hidden_taxes += this.components.formatCurrency(this.components.fixed(element_promo.hidden_taxes));
                            })
                        }
                    }
                });

            } else {
                if (!element.is_variant) {
                    if (element.taxes_item) {
                        element.taxes_item.forEach(tax => {
                            tax.rate = Number(tax.rate);
                            this.components.getTaxes(element, tax);
                            tax.value = tax.value * element.quantity;
                        });
                        element.hidden_taxes = this.components.fixed(element.hidden_taxes * element.quantity);
                    } else {
                        element.hidden_taxes = 0;
                    }
                } else {
                    if (element.parent.taxes_item) {
                        element.parent.taxes_item.forEach(tax => {
                            tax.rate = Number(tax.rate);
                            this.components.getTaxes(element, tax);
                            tax.value = tax.value * element.quantity;
                        });
                        element.hidden_taxes = this.components.fixed(element.hidden_taxes * element.quantity);
                    } else {
                        element.hidden_taxes = 0;
                    }
                }

                if (element.miscellaneous && element.taxes && this.auth.account_data.fiscal) {
                    this.components.getTaxes(element, element.taxes);
                    element.hidden_taxes = this.components.fixed(element.hidden_taxes * element.quantity);
                }

                if (element.hidden_taxes) {
                    element.hidden_taxes = this.components.formatCurrency(element.hidden_taxes);
                }

                if (element.is_variant) {
                    if (element.parent.taxes_item) {
                        element.parent.taxes_item.forEach(tax => {
                            if (tax.iva) {
                                this.iva = this.components.formatCurrency(element.hidden_taxes);
                            }
                            this.hidden_taxes += this.components.formatCurrency(element.hidden_taxes);
                        });
                    }
                } else {
                    if (element.taxes_item) {
                        element.taxes_item.forEach(tax => {
                            if (tax.iva) {
                                this.iva = this.components.formatCurrency(this.components.fixed(element.hidden_taxes));
                            }
                            this.hidden_taxes += this.components.formatCurrency(this.components.fixed(element.hidden_taxes));
                        })
                    } else {
                        if (element.miscellaneous && this.auth.account_data.fiscal) {
                            this.iva = this.components.formatCurrency(this.components.fixed(element.hidden_taxes));
                            this.hidden_taxes += this.components.formatCurrency(this.components.fixed(element.hidden_taxes));
                        }
                    }
                }
            }

            this.total += this.components.fixed(element.price * element.quantity);
        });

        this.payments.forEach(element => {
            this.total_payments += element.total;
        });

        this.total = this.components.fixed(this.total);
        this.totalWithoutDiscount = this.total;
        //CALCULO DE TAXES DE DESCUENTOS
        discounts.forEach(element => {
            if (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte') {
                if (element.type == 'percentage') {
                    // Para sacar el total de el descuentos sin los impuestos
                    let discount = (((this.total) * element.amount / 100) * 100) / 100;

                    let _tax_discount = discount / ((Number(13) * 0.01) + 1)
                    _tax_discount = this.components.formatCurrency(_tax_discount);
                    _tax_discount = discount - _tax_discount;
                    _tax_discount = this.components.formatCurrency(_tax_discount);
                    discount = this.components.formatCurrency(discount - _tax_discount);

                    this.iva_discount += (_tax_discount * 100) / 100;
                    element.iva_discount = (_tax_discount * 100) / 100;

                    if (this.pos.exempt) {
                        element.iva_discount = 0;
                    }

                    element.total_discount = discount + _tax_discount;

                    // Para sacar el total menos el descuenot						
                    let total = ((this.total - element.total_discount) * 100) / 100;

                    let _tax = (total) / ((Number(13) * 0.01) + 1)
                    _tax = (total) - _tax;
                    _tax = Number(_tax);
                    let hidden_taxes = this.components.fixed(_tax);

                    this.total = total;
                    this.gran_discount += discount;

                    this.hidden_taxes = hidden_taxes;

                    element.total_discount = discount;

                } else if (element.type == 'fixed') {

                    let discount = element.amount;
                    if (discount > this.total) {
                        discount = this.total;
                    }

                    // Para calcular los impuestos del descuento
                    let _tax_discount = discount / ((Number(13) * 0.01) + 1);
                    _tax_discount = this.components.formatCurrency(_tax_discount);
                    _tax_discount = discount - _tax_discount;
                    _tax_discount = this.components.formatCurrency(_tax_discount);

                    discount = this.components.formatCurrency(discount - _tax_discount);

                    this.iva_discount += _tax_discount;
                    element.iva_discount = _tax_discount;

                    if (this.order.exempt) {
                        element.iva_discount = 0;
                    }

                    let total = ((this.total - this.hidden_taxes - discount) * 100) / 100;

                    let hidden_taxes = (((total * ((Number(13) * 0.01) + 1)) - total) * 100) / 100;
                    total = total + hidden_taxes;

                    let gran_discount = discount;
                    let restante = (this.total - element.amount) - total;

                    if (total < 0) {
                        total = total - restante;
                        hidden_taxes = hidden_taxes - restante;
                    } else if (total > 0) {
                        total = total + restante;
                        hidden_taxes = hidden_taxes + restante;
                    }

                    this.total = this.components.formatCurrency(total);
                    this.gran_discount += this.components.formatCurrency(gran_discount);
                    this.hidden_taxes = this.components.formatCurrency(hidden_taxes);

                    element.total_discount = discount;

                }

                this.gran_discount = this.components.fixed(this.gran_discount);
            } else {
                if (element.type == 'percentage') {
                    let discount = Number(Number(this.total * (Number(element.amount) / 100)).toFixed(2));
                    element.total_discount = discount;
                    element.iva_discount = 0;
                    this.iva_discount = 0;
                    this.total = this.total - discount;
                    this.gran_discount += discount;
                } else if (element.type == 'fixed') {
                    element.total_discount = element.amount;
                    element.iva_discount = 0;
                    this.iva_discount = 0;
                    this.total = this.total - element.amount;
                    this.gran_discount += element.amount;
                }

                this.gran_discount = this.components.fixed(this.gran_discount);

                if (this.total < 0) {
                    this.total = 0;
                }

            }

            if (this.total < 0) {
                this.total = 0;
            }
        });

        if (this.branch.active_tip && (this.order.type == 'tables' || this.pos.type == 'tables')) {
            if (this.branch.active_tip && !this.order.tip_removed) {

                if (this.branch.include_taxes_tip) {

                    if (this.branch.include_discounts_tip) {
                        this.tip = (((this.total) * Number(this.branch.tip)) / 100);
                    } else {
                        this.tip = (((this.total + this.gran_discount + this.iva_discount) * Number(this.branch.tip)) / 100);
                    }
                } else {
                    if (this.branch.include_discounts_tip) {
                        this.tip = (((this.total) * Number(this.branch.tip)) / 100);
                    } else {
                        this.tip = ((((this.total + this.gran_discount + this.iva_discount)) * Number(this.branch.tip)) / 100);
                    }
                }
                this.tip = Math.round(this.tip * 100) / 100;
                this.tip = this.components.fixed(this.tip);
            } else {
                this.tip = 0;
            }

        } else {
            this.tip = 0;
        }

        //LARGE CONTRIBUTOR JAJA
        let total = this.total - this.hidden_taxes;
        const client = this.order?.client || this.pos?.client;

        // console.log(this.iva_rete);

        if (client && total > 100 && client.large_contributor) {
            this.iva_rete = this.components.fixed(total * 0.01);
            this.total -= this.iva_rete;
        }

        //DESCUENTOS
        discounts.forEach(pos_discount => {
            items.forEach(product => {
                if (pos_discount.type == 'percentage') {
                    let discount = Number(Number(product.price * (Number(pos_discount.amount) / 100)).toFixed(2));
                    product.discount = discount;
                    product.temp_price = product.price - product.discount;
                } else if (pos_discount.type == 'fixed') {
                    let discount_percent = this.gran_discount / (this.total + this.gran_discount);
                    discount_percent = discount_percent * 100;
                    let discount = Number(Number(product.price * (Number(discount_percent) / 100)).toFixed(2));
                    product.discount = discount;
                    product.temp_price = product.price - product.discount;
                }
            });
        });
        if (this.total < 0) {
            this.total = 0;
            this.total_payments = 0;
            this.gran_discount = 0;
            this.hidden_taxes = 0;
            this.taxes = 0;
            this.iva_discount = 0;
            this.pos.exempt = 0;
        }
    }


    saveOrder(print = false) {
        this.components.showLoader('Guardando orden...').then(() => {

            if (this.pos.items.length > 0 || (this.order && this.pos.discounts.length > 0)) {

                if (this.order) {
                    this.api.updateOrder(this.order).then(data => {
                        this.components.dismissLoader();
                    }, err => {
                        this.components.dismissLoader();
                        console.log(err);
                    })
                } else {
                    this.api.addOrder(this.discount_card).then(() => {
                        this.clearAll();
                        if (this.components.isApp()) this.modalController.dismiss();
                        this.components.dismissLoader();
                        this.components.showToast('Orden agregada con exito');
                    }, err => {
                        console.log(err);
                    })
                }
            } else {
                this.components.dismissLoader();
                this.components.showToast('Debes de agregar items para poder continuar', 'error')

            }
        })
    }

    async payNow(ev) {

        if (this.order.items || this.pos.items.length > 0) {
            this.components.buttonLoad(ev);
            if (this.order) {
                if (this.pos.items.length > 0) {
                    this.api.updateOrder(this.order).then(async data => {
                        await this.getTotal();
                        this.openPaymentModal();
                        this.components.dismissButtonLoad(ev);
                    }, err => {
                        console.log(err);
                        this.components.dismissButtonLoad(ev);
                    })
                } else {
                    this.openPaymentModal();
                    this.components.dismissButtonLoad(ev);

                }

            } else {
                this.api.addOrder(this.discount_card).then(respone => {
                    this.open_payment_modal = true;
                    this.order_pos = respone;
                    this.order_pos.creation_date = new Date();
                    this.new_order = true;
                    this.pos.clearOrder(this.order);
                    this.ngOnInit();
                    this.components.dismissButtonLoad(ev);
                })
            }
        } else {
            this.components.showToast('Debes de agregar items para poder continuar', 'error');
        }

    }

    clearAll() {
        this.order = false;
        this.payments = [];
        this.pos.clearOrder(undefined);
    }

    async openPaymentModal() {
        if (!this.components.isModalPresent) {
            const descuentoTotal = this.order.discounts.reduce((acc, discount) => {
                acc += parseFloat(discount.total_discount) + parseFloat(discount.iva_discount);
                return acc;
            }, 0);
            this.components.isModalPresent = true;
            let params = {
                order_key: this.order.$key,
                gran_total: Math.max(0, (this.total + this.tip + this.taxes)),
                gran_subtotal: Math.max(0, this.total),
                gran_discount: Math.max(0, this.gran_discount),
                gran_taxes: Math.max(0, this.taxes),
                gran_hidden_taxes: this.components.fixed(Math.max(0, this.hidden_taxes)),
                gran_tip: Math.max(0, this.tip),
                discounts: this.order.discounts,
                branch: this.branch,
                order: this.order,
                iva_rete: this.iva_rete,
                descuentoTotal
            }

            if (this.discount_card) {
                params['discount_card'] = this.discount_card
            }

            const modal_payment = await this.modalController.create({
                component: ModalBaseComponent,
                cssClass: 'pay-modal',
                showBackdrop: true,
                componentProps: {
                    rootPage: PaymentMethodComponent,
                    params: params
                },
            });


            let check_dte = true;
            let no_client = false;

            if (this.auth.account_data.fiscal) {
                if (this.auth.account_data.fiscal.type == 'dte') {
                    if (this.pos.client) {
                        if (this.pos.client.type == 'regular' && (!this.pos.client.name || !this.pos.client.email)) {
                            check_dte = false;
                        } else if ((this.pos.client.type == 'legal' || this.pos.client.type == 'natural') && (!this.pos.client.name || !this.pos.client.phone || !this.pos.client.email || !this.pos.client.nit || !this.pos.client.nrc || !this.pos.client.activity || !this.pos.client.address || !this.pos.client.depto || !this.pos.client.munic)) {
                            check_dte = false;
                        }
                    } else {

                        if (this.order.tax_receipt.type == 'ccf') {
                            check_dte = false;
                            no_client = true;
                        }
                    }
                }
            }

            if (check_dte) {
                await modal_payment.present().then(data => {
                    this.components.isModalPresent = false;
                    this.components.dismissLoader();
                })
            } else {
                this.components.dismissLoader();
                this.components.isModalPresent = false;

                if (no_client) {
                    this.components.showToast('Debes de seleccionar un cliente para el tipo de comprobante de CCF');
                    this.addClient(true);
                } else {
                    let new_class = '';
                    if (this.pos.client.type != 'regular') {
                        new_class = 'two-columns';
                    }
                    this.modalController.create({
                        component: EditClientComponent,
                        cssClass: 'auto-height ' + new_class,
                        animated: true,
                        backdropDismiss: true,
                        componentProps: {
                            client: this.pos.client,
                            dte_valid: true
                        }
                    }).then(async modal => {
                        await modal.present();
                        await modal.present().then(() => {
                            this.components.isModalPresent = false;
                            this.components.dismissLoader();
                        })
                        await modal.onDidDismiss().then(async data => {
                            if (data?.data?.success === false) {
                                await this.invalidDiscountCard(data?.data?.message)
                                return
                            }
                            if (data.data) {
                                this.pos.client = data.data;
                                this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
                                    client: data.data
                                })
                            }

                        })
                    });
                }
            }

            await modal_payment.onDidDismiss().then(async (data) => {
                if (data?.data?.success === false) {
                    await this.invalidDiscountCard(data?.data?.message)
                    return;
                }
                this.discount_card = null;
                if (data.data) {
                    if (data.data.completed) {
                        if (this.auth.license.membership.type == 'resto') {
                            this.order = '';
                            this.total = 0;
                            this.total_payments = 0;
                            this.payments = [];
                            this.client = false;
                            this.tip = 0;
                            this.taxes = 0;
                            this.hidden_taxes = 0;
                            this.gran_discount = '';
                        } else {
                            this.order = '';
                            this.total = 0;
                            this.taxes = 0;
                            this.total_payments = 0;
                            this.payments = [];
                            this.client = false;
                            this.tip = 0;
                            this.hidden_taxes = 0;
                            this.gran_discount = '';
                        }
                        if (this.components.isApp()) this.modalController.dismiss();
                        this.router.navigate(['/pos']);
                        this.successPaymentModal(data.data.ticket_key);
                    }
                }

            });
        }
    }

    async changeDelivery() {
        if (!this.components.isModalPresent) {
            this.components.isModalPresent = true;

            const modal = await this.modalController.create({
                component: DeliveriesComponent,
                showBackdrop: true
            });

            await modal.present().then(() => { this.components.isModalPresent = false })

            await modal.onDidDismiss().then(data => {
                if (data.data) {
                    let delivery = data.data;

                    if (this.order) {
                        this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
                            delivery: delivery
                        }).then(() => {
                            this.order.delivery = delivery;
                            this.pos.addDelivery(delivery);
                            this.components.showToast('Delivery agregado a tu orden');
                        }, err => {
                            console.log(err);
                        })
                    } else {
                        this.pos.addDelivery(delivery);
                    }
                }
            });
        }
    }

    async successPaymentModal(ticket_key) {
        const modal = await this.modalController.create({
            component: SuccessPaymentComponent,
            cssClass: 'full-modal',
            backdropDismiss: false,
            animated: false,
            componentProps: {
                ticket_key: ticket_key
            }
        });

        await modal.present().then(() => {

        });

        await modal.onDidDismiss().then((data) => {
        })
    }

    async openEditModal(element, already_in_order = true) {
        let item = JSON.parse(JSON.stringify(element));
        if (!this.components.isModalPresent) {
            this.components.isModalPresent = true;
            const modal = await this.modalController.create({
                component: ModalBaseComponent,
                cssClass: 'edit-item-modal',
                componentProps: {
                    rootPage: EditItemComponent,
                    params: {
                        item: item,
                        order: this.order,
                        already_in_order: already_in_order
                    }
                },
            });
            await modal.present().then(() => { this.components.isModalPresent = false })

            await modal.onDidDismiss().then(data => {
                if (data.data == 'returned') {
                    this.router.navigate([`pos`]);
                    this.clearAll();
                }
            })

        }
    }

    async addClient(is_ccf = false) {
        if (!this.components.isModalPresent) {
            this.components.isModalPresent = true;

            let modal = await this.modalController.create({
                component: ClientsComponent,
                showBackdrop: true,
                cssClass: 'clients-modal',
                componentProps: {
                    order: this.order,
                    is_ccf: is_ccf
                },
            });

            await modal.present().then(data => { this.components.isModalPresent = false })

            await modal.onDidDismiss().then(data => {
                if (data.data) {
                    let client = data.data;
                    if (typeof client.document_type?.code === 'number' && client.document_type) {
                        switch (client.document_type.code) {
                            case 2:
                                client.document_type.code = '02';
                                break;
                            case 3:
                                client.document_type.code = '03'
                                break;

                            default:
                                client.document_type.code = client.document_type.code.toString();
                                break;
                        }
                    }

                    let exempt = false;
                    if (client.exempt) {
                        exempt = true;
                    }

                    if (this.order) {
                        this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
                            client: client,
                            exempt: exempt,
                        }).then(() => {
                            this.order.client = client;
                            this.order.exempt = exempt;
                            this.pos.client = client;
                            this.pos.exempt = exempt;
                            this.pos.updateOrderTicket(this.order.$key);
                            this.components.showToast('Cliente agregado a tu orden');
                        }, err => {
                            console.log(err);
                        })
                        this.pos.addClient(client);
                    } else {
                        this.pos.exempt = exempt;
                        this.pos.addClient(client);
                    }
                }
            });
        }
    }

    removeClient(client) {
        this.components.showAlert('Eliminar cliente', `El cliente ${client.name} ser� eliminado del ticket.`,
            [
                {
                    text: 'Cancelar',
                    role: 'cancel'
                },
                {
                    text: 'Eliminar',
                    role: 'destructive',
                    handler: () => {
                        this.item_slides.closeOpened();
                        if (this.order) {

                            let data = {
                                client: deleteField(),
                                exempt: false,
                            };

                            if ((this.order.tax_receipt && this.order.tax_receipt.type == 'ccf') && (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte')) {
                                data['tax_receipt'] = {
                                    type: 'invoice'
                                }
                                this.order.type = 'invoice';
                                this.pos.type = 'invoice';
                            }

                            this.order.client = false;
                            this.order.exempt = false;
                            this.pos.client = false;
                            this.pos.exempt = false;

                            this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, data).then(() => {
                                this.pos.updateOrderTicket(this.order.$key);
                                this.components.showToast('Cliente eliminado correctamente.')
                            }, err => {
                                console.log(err);
                            })
                        } else {
                            this.pos.removeClient();
                        }
                    }
                },
            ]
        )
    }

    changeKdsType(value, index) {
        this.item_slides.closeOpened();
        this.pos.items[index].kds_type = value;
        this.components.showToast('Se cambio el tipo de item.');
    }

    async editDiscount(discount) {

        if (!discount.promotion) {
            const alert = await this.alertController.create({
                header: 'Eliminar descuento',
                message: `Estas seguro que deseas eliminar el descuento ${discount.name ?? discount.note}?, esta acci�n no se puede revertir.`,
                buttons: [
                    {
                        text: 'Cancelar',
                        role: 'cancel'
                    },
                    {
                        text: 'Remover descuento',
                        role: 'destructive',
                        handler: () => {
                            if (!discount.order_key) {
                                this.pos.removeDiscount(discount);
                                this.components.showToast('Descuento eliminado correctamente.');
                            } else {
                                const discounts = this.order.discounts;
                                this.api.deleteDocument(`accounts/${this.auth.account}/ticket_discounts`, discount.$key).then(() => {
                                    this.components.showToast('Descuento eliminado correctamente.');
                                    this.order.discounts = discounts.filter(({ $key }) => discount.$key !== $key);
                                    this.getTotal();
                                }, err => {
                                    console.log(err);
                                })
                            }
                        }
                    }
                ]
            });

            await alert.present();
        } else {
            const alert = await this.alertController.create({
                header: 'Eliminar promocion',
                message: `Estas seguro que deseas eliminar el descuento ${discount.name}?, esta acci�n no se puede revertir.`,
                buttons: [
                    {
                        text: 'Cancelar',
                        role: 'cancel'
                    },
                    {
                        text: 'Remover propina',
                        role: 'destructive',
                        handler: () => {
                            this.api.deleteDocument(`accounts/${this.auth.account}/ticket_discounts`, discount.$key).then(() => {
                                this.modalController.dismiss();
                                this.components.showToast('Descuento eliminado correctamente.');
                            }, err => {
                                console.log(err);
                            })
                        }
                    }
                ]
            });

            await alert.present();
        }
    }

    async ticketOptions() {
        if (!this.components.isModalPresent) {
            this.components.isModalPresent = true;
            const modal = await this.modalController.create({
                component: ModalBaseComponent,
                cssClass: 'ticket-options-modal',
                showBackdrop: true,
                backdropDismiss: true,
                componentProps: {
                    rootPage: TicketOptionsComponent,
                    params: {
                        order: this.order,
                        payments: this.payments,
                        total: this.total + this.tip + this.taxes,
                        discount_card: this.discount_card,
                        currentTotal: this.total
                    }
                }
            });
            await modal.present().then(() => { this.components.isModalPresent = false })

            await modal.onDidDismiss().then(async (data) => {
                if (data.data == 'returned') {
                    this.discount_card = null;
                    this.router.navigate([`pos`]);
                    this.clearAll();
                }
                if (data?.data?.$key) {
                    this.discount_card = data.data
                }
                if (data?.data?.code === 'deleted') {
                    this.discount_card = null
                    this.order.discount_card = null
                    this.components.showToast('Tarjeta de descuento fue removida.');
                }
                this.getTotal();
            })
        }
    }

    async printOrder() {
        if (!this.auth.license.membership.features.tickets.print) {
            this.components.showAlert('Actualiza tu licencia', 'Al actualizar tu licencia podr�s imprimir ordenes.', ['Aceptar']);
        } else {
            let total = this.components.fixed(this.total + this.tip + this.taxes);
            let subtotal = this.components.fixed(this.total);

            let tip = this.components.fixed(this.tip);
            let discount = this.components.fixed(this.gran_discount + this.iva_discount);
            let exempt = 0;

            if (this.pos.exempt) {
                exempt = this.hidden_taxes + this.taxes
            }

            let order = JSON.parse(JSON.stringify(this.order));

            let creation_date = this.order.creation_date;

            if (creation_date instanceof Date) {
                order.creation_date = creation_date;
            } else {
                order.creation_date = creation_date.toDate();
            }

            order.taxes = this.hidden_taxes + this.taxes;

            if (this.platform.is('cordova') || this.printer.quanto_prints) {
                this.printer.printOrder(order, subtotal, total, tip, discount, false, exempt);
            } else {
                if (this.printer.quanto_prints) {
                    this.printer.printOrder(order, subtotal, total, tip, discount, false, exempt);
                } else {
                    this.printer.pdfOrder(order, subtotal, total, tip, discount, false, exempt);
                }
            }

        }
    }

    async goToChangeTablePage() {
        const modal = await this.modalController.create({
            component: TransferTicketsComponent,
            cssClass: 'full-modal',
            canDismiss: true,
            showBackdrop: true,
            backdropDismiss: true,
            componentProps: {
                orders: [this.order]
            }
        });

        await modal.present();

        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.order.table = this.pos.table;
                this.order.table_area = this.pos.table_area;
            }
        })
    }

    async updatePeople() {
        const modal = await this.modalController.create({
            component: NumberComponent,
            cssClass: 'auto-height number-modal',
            canDismiss: true,
            showBackdrop: true,
            backdropDismiss: false,
            componentProps: {
                order: this.order,
                is_update: true,
                peopleConfirmation: true,
                table: {
                    text: this.pos.table
                }
            }
        });
        await modal.present();
    }

    cancelOrder() {
        this.pos.cancelOrder(undefined).then(() => {
            this.order = false;
            this.total = 0;
            this.total_payments = 0;
            this.payments = [];
            this.client = false;
            this.tip = 0;
            this.taxes = 0;
            this.iva = 0;
            this.gran_discount;
            this.hidden_taxes = 0;
            this.open_payment_modal = false;
            this.iva_discount = 0;
            this.iva_rete = 0;
        });

        if (this.components.isApp()) {
            this.modalController.dismiss();
        }
    }

    async removeDiscountCard(discount, messege = null) {
        const alert = await this.alertController.create({
            header: messege ?? 'Remover tarjeta de descuento',
            message: messege ? 'Para continuar con tu pago, remueve la tarjeta de descuento.' : 'Estas seguro que deseas remover tarjeta de descuento ?',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel'
                },
                {
                    text: 'Remover tarjeta',
                    role: 'destructive',
                    handler: () => {
                        if (this.order) {
                            const discounts = this.order.discounts;
                            this.api.deleteDocument(`accounts/${this.auth.account}/ticket_discounts`, discount.$key).then(async () => {
                                await this.api.removeDiscountCardFromOrder(this.order)
                                this.order.discounts = discounts.filter(({ $key }) => discount.$key !== $key);
                                this.components.showToast('Tarjeta de descuento fue removida.');
                                this.discount_card = null;
                                this.order.discount_card = null;
                            }, err => {
                                console.log(err);
                            })
                            this.getTotal();
                        }

                        if (!this.order) {
                            this.pos.removeDiscount(discount);
                            this.discount_card = null;
                            this.components.showToast('Tarjeta de descuento fue removida.');
                        }
                    }
                }
            ]
        });

        await alert.present();
    }
    async invalidDiscountCard(message,) {
        const discount = this.order.discounts.find(({ isDiscountCard }) => isDiscountCard === true);
        if (discount) {
            if (discount) {
                await this.removeDiscountCard(discount, message)
                return;
            }
        }
    }
}
