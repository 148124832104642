// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-connection {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,dce3ee+100 */
  --background: #ffffff;
  /* Old browsers */
  --background: -moz-linear-gradient(top, #ffffff 0%, #bfc1dd 100%);
  /* FF3.6-15 */
  --background: -webkit-linear-gradient(top, #ffffff 0%, #bfc1dd 100%);
  /* Chrome10-25,Safari5.1-6 */
  --background: linear-gradient(to bottom,
          #ffffff 0%,
          #bfc1dd 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#bfc1dd", GradientType=0);
  /* IE6-9 */
}

app-update {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,iHAAA;EACA,qBAAA;EACA,iBAAA;EACA,iEAAA;EACA,aAAA;EACA,oEAAA;EACA,4BAAA;EACA;;uBAAA;EAGA,qDAAA;EACA,kHAAA;EACA,UAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ","sourcesContent":[".no-connection {\n    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,dce3ee+100 */\n    --background: #ffffff;\n    /* Old browsers */\n    --background: -moz-linear-gradient(top, #ffffff 0%, #bfc1dd 100%);\n    /* FF3.6-15 */\n    --background: -webkit-linear-gradient(top, #ffffff 0%, #bfc1dd 100%);\n    /* Chrome10-25,Safari5.1-6 */\n    --background: linear-gradient(to bottom,\n            #ffffff 0%,\n            #bfc1dd 100%);\n    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */\n    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#bfc1dd', GradientType=0);\n    /* IE6-9 */\n}\n\napp-update {\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
