import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
    selector: 'app-separate-people',
    templateUrl: './separate-people.component.html',
    styleUrls: ['./separate-people.component.scss'],
})
export class SeparatePeopleComponent {
    availablePleople: any
    peopleToNewOrder: any = {};

    constructor(
        private readonly navParams: NavParams,
        private readonly modalController: ModalController,
    ) {
        this.availablePleople = navParams.get('availablePeople');
        this.peopleToNewOrder = { childrens: 0, mens: 0, womens: 0, number_persons: 0 };
    }

    peopleTypes = [
        { key: 'mens', label: 'Hombres' },
        { key: 'womens', label: 'Mujeres' },
        { key: 'childrens', label: 'Niños' }
    ];

    changeQuantity(action: 'add' | 'minus', type: string): void {
        if (action === 'add' && this.peopleToNewOrder[type] < this.availablePleople[type]) {
            this.peopleToNewOrder[type]++;
            this.peopleToNewOrder['number_persons']++;
        } else if (action === 'minus' && this.peopleToNewOrder[type] > 0) {
            this.peopleToNewOrder[type]--;
            this.peopleToNewOrder['number_persons']--;
        }
    }

    assignPeopleToOrder() {
        const result = Object.keys(this.availablePleople).reduce((acc, key) => {
            acc[key] = this.availablePleople[key] - this.peopleToNewOrder[key];
            return acc;
        }, {});

        console.log(result);
        console.log(this.peopleToNewOrder);

        this.modalController.dismiss({
            peopleAddedToNewOrder: this.peopleToNewOrder,
            peopleAvailable: result
        })
    }

    canContinue(): boolean {
        const sum = Object.values(this.peopleToNewOrder).reduce((acc: any, currentValue: any) => acc + currentValue, 0);

        if (sum === 0) {
            return false;
        }

        return this.peopleToNewOrder['number_persons'] < this.availablePleople['number_persons'];
    }
}
