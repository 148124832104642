// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-title {
  margin-left: 50px;
}

h6 {
  font-weight: 600;
  color: black;
  font-size: 17px;
  margin-top: 16px;
  margin-bottom: 10px;
}

ion-footer {
  padding: 6px;
}

.card-number {
  letter-spacing: 1.5px;
  word-spacing: 1.5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/payments/remote-payment/remote-payment.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,qBAAA;EACA,mBAAA;AACF","sourcesContent":["ion-title {\n  margin-left: 50px;\n}\n\nh6 {\n  font-weight: 600;\n  color: black;\n  font-size: 17px;\n  margin-top: 16px;\n  margin-bottom: 10px;\n}\n\nion-footer {\n  padding: 6px;\n}\n\n.card-number {\n  letter-spacing: 1.5px;\n  word-spacing: 1.5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
