// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner-content {
  background: var(--ion-color-light);
}

.title-text {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #333;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.table-text {
  font-size: 1rem;
  font-weight: bold;
  color: #666;
  margin-top: 0;
  font-style: capitalize;
}

h2 {
  font-size: 17px;
  margin-bottom: 8px;
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border: 1px solid var(--ion-color-light-tint);
  padding: 8px;
  border-radius: 10px;
}
.quantity ion-button {
  aspect-ratio: 1/1;
}
.quantity ion-icon,
.quantity h3 {
  font-size: 26px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/tables/separate-people/separate-people.component.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,0BAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,eAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,6CAAA;EACA,YAAA;EACA,mBAAA;AACJ;AACI;EACI,iBAAA;AACR;AAEI;;EAEI,eAAA;AAAR","sourcesContent":[".inner-content {\n    background: var(--ion-color-light);\n}\n\n.title-text {\n    font-size: 1.2rem;\n    font-weight: bolder;\n    color: #333;\n    margin-bottom: 8px;\n    text-transform: capitalize;\n}\n\n.table-text {\n    font-size: 1rem;\n    font-weight: bold;\n    color: #666;\n    margin-top: 0;\n    font-style: capitalize;\n}\n\nh2 {\n    font-size: 17px;\n    margin-bottom: 8px;\n}\n\n.quantity {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background: white;\n    border: 1px solid var(--ion-color-light-tint);\n    padding: 8px;\n    border-radius: 10px;\n\n    ion-button {\n        aspect-ratio: 1 / 1;\n    }\n\n    ion-icon,\n    h3 {\n        font-size: 26px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
