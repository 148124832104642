import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
	selector: 'app-add-note-order',
	templateUrl: './add-note-order.component.html',
	styleUrls: ['./add-note-order.component.scss'],
})
export class AddNoteOrderComponent implements OnInit {

	order: any;
	note: any;

	constructor(
		public api: ApiService,
		public components: ComponentsService,
		public auth: AuthService,
		public modalController: ModalController,
		public navParams: NavParams,
		public pos: PosService
	) {
		this.order = this.navParams.get('order');
	}

	ngOnInit() { }

	addNote() {
		if (this.order) {
			this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
				notes: this.note
			}).then(() => {
				this.components.showToast('Nota actualizada correctamente.', 'success');
				this.modalController.dismiss({ note: this.note});
			}, err => {
				console.log(err);
			});
		} else {
			this.pos.note = this.note;
			this.components.showToast('Nota actualizada correctamente.', 'success');
			this.modalController.dismiss({ note: this.note});
		}
	}

}
