import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
	AlertController,
	ModalController,
	NavParams,
	PopoverController,
} from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { tipo_documento, departamentos, municipios, actividad_economica } from "../../../../assets/dte";
import { SelectPopoverComponent } from '../../shared/select-popover/select-popover.component';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { TranslateConfigService } from 'src/app/services/language/languages.service';

@Component({
	selector: 'app-edit-client',
	templateUrl: './edit-client.component.html',
	styleUrls: ['./edit-client.component.scss'],
})
export class EditClientComponent implements OnInit {
	client: any;
	dte_valid: any;
	clientForm: UntypedFormGroup;
	documents_types: any = tipo_documento;
	activity: any = actividad_economica;
	deptos: any = departamentos;
	municipios: any = municipios;
	hasOrders: boolean;
	isFiscal: boolean;

	public isEmailRequired: boolean = false;
	public isDocumentRequired: boolean = false;

	readonly nit_legal_mask: MaskitoOptions = {
		mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/],
	};

	readonly nit_natural_mask: MaskitoOptions = {
		mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
	};

	readonly nrc_mask: MaskitoOptions = {
		mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
	};

	readonly document_mask: MaskitoOptions = {
		mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
	};

	readonly maskPredicate: MaskitoElementPredicate = async (el) => (el as HTMLIonInputElement).getInputElement();

	constructor(
		public modalController: ModalController,
		public popoverController: PopoverController,
		public formBuilder: UntypedFormBuilder,
		public components: ComponentsService,
		public navParams: NavParams,
		public api: ApiService,
		public auth: AuthService,
		public alertController: AlertController,
		public langService: TranslateConfigService
	) {
		this.isFiscal = !!this.auth.account_data?.fiscal;
		this.client = this.navParams.get('client');
		this.dte_valid = this.navParams.get('dte_valid');
		
		this.isDocumentRequired = this.navParams.get('isDocumentRequired') ? this.navParams.get('isDocumentRequired'): false;
		this.isEmailRequired = this.navParams.get('isEmailRequired') ? this.navParams.get('isEmailRequired'): false;
		console.log(this.isEmailRequired)
		if (this.client.type == 'regular') {
			this.clientForm = this.formBuilder.group({
				name: [this.client.name, [Validators.required]],
				phone: [this.client.phone],
				email: [this.client.email],
				document: [this.client.document],
				document_type: [''],
				type: ['regular'],
				address: [this.client.address],
				address_2: [this.client.address_2],
				city: [this.client.city],
				postal_code: [this.client.postal_code],
				legal_person: [this.client.legal_person],
				credit: [this.client.credit],
				limit_credit: [this.client.limit_credit],
				depto: [''],
				munic: [''],
				exempt: [this.client.exempt],
			});

			this.clientForm.get('credit').valueChanges.subscribe((credit) => {
				if (credit) {
					this.clientForm.controls['limit_credit'].setValidators([
						Validators.required,
					]);
					this.clientForm.controls['document_type'].setValidators([
						Validators.required,
					]);
				} else {
					this.clientForm.controls['limit_credit'].clearValidators();
					this.clientForm.controls['document_type'].clearValidators();
				}
				this.clientForm.controls['limit_credit'].updateValueAndValidity();
				this.clientForm.controls['document_type'].updateValueAndValidity();
			});
		} else if (this.client.type == 'natural' || this.client.type == 'legal') {
			this.clientForm = this.formBuilder.group({
				name: [this.client.name],
				email: [this.client.email],
				phone: [this.client.phone],
				address: [this.client.address],
				address_2: [this.client.address_2],
				city: [this.client.city],
				document_type: [''],
				postal_code: [this.client.postal_code],
				credit: [this.client.credit],
				limit_credit: [this.client.limit_credit],
				legal_person: [this.client.legal_person],
				large_contributor: [this.client.large_contributor],
				nit: [this.client.nit],
				nrc: [this.client.nrc],
				activity: [this.client.activity],
				type: [this.client.type],
				depto: [''],
				munic: [''],
				exempt: [this.client.exempt],
			});

			this.clientForm.get('credit').valueChanges.subscribe((credit) => {
				if (credit) {
					this.clientForm.controls['limit_credit'].setValidators([
						Validators.required,
					]);
				} else {
					this.clientForm.controls['limit_credit'].clearValidators();
				}
				this.clientForm.controls['limit_credit'].updateValueAndValidity();
			});
		}
	}

	ngOnInit() {
		this.makeFieldsRequired(this.isDocumentRequired, this.client.type, this.isEmailRequired)
	}

	ionViewDidEnter() {
		if (this.client.document_type) this.clientForm.controls.document_type.setValue(this.client.document_type);
		if (this.client.depto) this.clientForm.controls.depto.setValue(this.client.depto);
		if (this.client.munic) this.clientForm.controls.munic.setValue(this.client.munic);
	}

	saveChanges() {
		let client = this.clientForm.value;
		let valid = true;

		if (client.legal_person) {
			client.nit = client.nit.replace(/-/g, "");
			client.nrc = client.nrc.replace(/-/g, "");
		} else {
			if (client.document_type.value == 'NIT') {
				client.document = client.document.replace(/-/g, "");
			}
		}

		if (client.credit) {
			if (client.limit_credit <= 0) {
				valid = false;
				this.components.showToast(
					'El limite de cr�dito debe de ser mayor a 0 ',
					'error'
				);
				this.components.dismissLoader();
			}
		}

		if (valid) {
			this.api.updateDocument(`accounts/${this.auth.account}/clients`, this.client.$key, client).then(() => {
				this.components.showToast('Cliente actualizado correctamente.');
				this.modalController.dismiss(client);
			});
		}
	}

	changePersonType(e) {
		console.log(e.detail.checked);
	}


	async getOrdersByClient(client_id: string) {
		try {
			const querySnapshot = await this.api.getRef(`accounts/${this.auth.account}/tickets`).ref
				.where('status', '==', 'completed')
				.where('branch_key', '==', this.auth.branch)
				.orderBy('completed_date', 'desc')
				.limit(20)
				.get();
	
			const orders: any[] = [];
			querySnapshot.forEach((doc: any) => {
				const data = doc.data();
				if (data.client && data.client.$key === client_id) {
					orders.push(data);
				}
			});
	
			this.hasOrders = orders.length > 0;
			this.components.dismissLoader();
	
		} catch (err) {
			console.log(err);
		}
	}
	
	

	async deleteClient() {
		await Promise.all([
            this.components.showLoader('Buscando ordenes ...'),
            this.getOrdersByClient(this.client.$key)
        ]);
		if ( this.hasOrders) {
			const alert = await this.alertController.create({
			  header: 'No es posible eliminar cliente',
			  message: `El Cliente posee �rdenes registradas a su nombre y afectar� tus reportes. Si deseas eliminarlo, contacta con Quanto Support para recibir asistencia.`,
			  buttons: [
				{
				  text: 'Aceptar'
				}
			  ],
			});
			await alert.present();
		}
		if(!this.hasOrders){

			const alert = await this.alertController.create({
				header: 'Eliminar cliente',
				message: `�Seguro que deseas eliminar a ${this.client.name} de tus clientes?. Esta acci�n no se puede revertir.`,
				buttons: [
					{
						text: 'Cancelar',
						role: 'cancel',
					},
					{
						text: 'Eliminar',
						role: 'destructive',
						handler: () => {
							if (this.client.orders == 0) {
								this.components.showLoader('Eliminando cliente...').then(() => {
									this.api
									.deleteDocument(
										`accounts/${this.auth.account}/clients`,
										this.client.$key
									)
									.then(() => {
										this.components.showToast('Cliente eliminado.');
										this.modalController.dismiss();
										this.components.dismissLoader();
									});
								});
							} else {
								this.components.showToast(
									'No puedes eliminar un cliente que ya ha realizado una compra.',
									'error'
								);
							}
						},
					},
				],
			});
			await alert.present();
		}
	}

	async selectAct(ev) {
		console.log('a');

		let activity = JSON.parse(JSON.stringify(this.activity));

		const popover = await this.popoverController.create({
			component: SelectPopoverComponent,
			event: ev,
			size: 'cover',
			showBackdrop: false,
			componentProps: {
				list: activity
			}
		});

		popover.onDidDismiss().then((data) => {
			if (data.data) {
				this.clientForm.controls.activity.setValue(data.data);
			}
		});

		await popover.present();
	}

	async selectDocType(ev) {
		let docs_types = JSON.parse(JSON.stringify(this.documents_types));

		const popover = await this.popoverController.create({
			component: SelectPopoverComponent,
			event: ev,
			size: 'cover',
			showBackdrop: false,
			componentProps: {
				list: docs_types
			}
		});

		popover.onDidDismiss().then((data) => {
			let element = data.data;
			if (element && (element.value != this.clientForm.value.document_type.value)) {
				this.clientForm.controls.document_type.setValue(element);
			}
		});

		await popover.present();
	}

	async selectDepto(ev) {
		let deptos = JSON.parse(JSON.stringify(this.deptos))

		const popover = await this.popoverController.create({
			component: SelectPopoverComponent,
			event: ev,
			showBackdrop: false,
			componentProps: {
				list: deptos
			}
		});

		popover.onDidDismiss().then((data) => {
			let element = data.data;
			if (element && (element.value != this.clientForm.value.depto.value)) {
				this.clientForm.controls.depto.setValue(element);
				this.clientForm.controls.munic.setValue('');
			}
		});

		await popover.present();
	}

	async selectMunicipios(ev) {
		let municipios = JSON.parse(JSON.stringify(this.municipios));
		let municipios_filtrados = [];

		municipios.forEach(munic => {
			if (this.clientForm.value.depto.code == munic.depto) {
				municipios_filtrados.push(munic);
			}
		});

		const popover = await this.popoverController.create({
			component: SelectPopoverComponent,
			event: ev,
			showBackdrop: false,
			componentProps: {
				list: municipios_filtrados
			}
		});

		popover.onDidDismiss().then((data) => {
			let element = data.data;
			if (element && (element.value != this.clientForm.value.munic.value)) {
				this.clientForm.controls.munic.setValue(element);
			}
		});

		await popover.present();
	}

	getMask() {
		let document_type = this.clientForm.value.document_type.value;

		if (document_type == 'DUI') {
			return this.document_mask;
		} else if (document_type == 'NIT') {
			return this.nit_legal_mask;
		} else if (document_type == 'NRC') {
			return this.nrc_mask;
		} else {
			return null;
		}
	}
	
	makeFieldsRequired(isDocumentRequired: boolean,  clienteType: string, isEmailRequired: boolean) {
		if (isDocumentRequired && clienteType === 'regular') {
			const emailValue = this.clientForm.get('email').value;
			if (!emailValue) this.clientForm.get('email').setValidators([Validators.required, Validators.email]);
			this.clientForm.get('document').setValidators([Validators.required]);
			this.clientForm.get('document_type').setValidators([Validators.required]);
		} else if (isEmailRequired) {
			this.clientForm.get('email').setValidators([Validators.required, Validators.email]);
			this.clientForm.get('name').setValidators([Validators.required]);
			this.clientForm.get('document').clearValidators();
			this.clientForm.get('document_type').clearValidators();
		} else {
			this.clientForm.get('email').clearValidators();
			this.clientForm.get('document').clearValidators();
			this.clientForm.get('document_type').clearValidators();
		}
	
		this.clientForm.get('email').updateValueAndValidity();
		this.clientForm.get('document').updateValueAndValidity();
		this.clientForm.get('document_type').updateValueAndValidity();
	}
	

}
