import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
    selector: 'app-add-discount',
    templateUrl: './add-discount.component.html',
    styleUrls: ['./add-discount.component.scss'],
})
export class AddDiscountComponent implements OnInit {

    discountForm: UntypedFormGroup;
    type: any = 'custom';
    order: any;
    item: any;
    manager: any = false;
    discounts: any = [];
    selected_discount: any;
    loading: any = true;
    apply_to: any = 'all';

    selectedSegment: string = 'default';
    changeSegment(value: string) {
        this.selectedSegment = value;
    }

    constructor(
        public popoverController: PopoverController,
        public modalController: ModalController,
        public formBuilder: UntypedFormBuilder,
        public navParams: NavParams,
        public auth: AuthService,
        public components: ComponentsService,
        public api: ApiService,
        public pos: PosService
    ) {
        this.order = this.navParams.get('order');
        this.item = this.navParams.get('item');

        this.discountForm = this.formBuilder.group({
            type: [this.type, [Validators.required]],
            amount: ['', [Validators.required]],
            note: ['', [Validators.required]]
        });

        this.api.getRef(`accounts/${this.auth.account}/discounts`).ref
            .where('branches', 'array-contains', this.auth.branch)
            .where('available', '==', true)
            .get()
            .then(sanpshots => {
                sanpshots.forEach((element: any) => {
                    let discount = element.data();
                    discount.$key = element.id;
                    this.discounts.push(discount);
                });
                this.discounts.sort(function (a, b) {
                    var orderA = a.amount, orderB = b.amount;
                    return orderA - orderB;
                });
                this.loading = false;
            })
    }

    ngOnInit() {
    }

    changeType(ev) {
        this.type = ev.detail.value;
    }

    selectDiscount(ev) {
        this.selected_discount = ev.detail.value;
    }

    async addDiscount(type) {

        let discount;

        if (type == 'custom') {
            discount = this.discountForm.value;
            discount.amount = Number(discount.amount);
        } else {
            discount = this.selected_discount;
        }

        if (discount.amount > 0 && discount) {
            discount.$key = this.api.db.createId();

            if (this.item) {
                let new_price = 0;
                if (discount.type == 'percentage') {
                    let calc_discount = Number(Number(this.item.price * (Number(discount.amount) / 100)).toFixed(2));
                    new_price = Number(Number(this.item.price - calc_discount).toFixed(2));
                } else if (discount.type == 'fixed') {
                    new_price = this.item.price - discount.amount;
                }

                if (new_price < 0) {
                    new_price = 0;
                }

                this.editPrice(new_price, discount);
            } else {

                const hasCardDiscount = (discounts: { [key: string]: any }[] = []) =>
                    Array.isArray(discounts) && discounts.some(({ isDiscountCard }) => !!isDiscountCard);

                const hasNonCardDiscount = (discounts: { [key: string]: any }[] = []) =>
                    Array.isArray(discounts) && discounts.some(({ isDiscountCard }) => !isDiscountCard);

                const canAddDiscount = (discounts: { [key: string]: any }[] = [], discountToAdd: { [key: string]: any }) => {
                    if (discountToAdd.isDiscountCard) {
                        return !hasCardDiscount(discounts);
                    } else {
                        return !hasNonCardDiscount(discounts);
                    }
                };

                if (
                    canAddDiscount(this.pos.discounts, discount) &&
                    canAddDiscount(this.order.discounts, discount)
                ) {
                    if (this.order) {
                        const existingDiscounts = this.order.discounts;
                        const newDiscount = await this.api.addDiscountToOrder(this.order.$key, discount);
                        this.pos.discounts = [];
                        this.order.discounts = [...existingDiscounts, newDiscount];
                    } else {
                        this.pos.addDiscount(discount);
                    }

                    this.components.showToast('Descuento agregado correctamente');
                    this.modalController.dismiss();
                } else {
                    this.components.showAlert('Error', 'Solo se permite agregar un descuento a tu orden.', ['Aceptar']);
                    this.modalController.dismiss();
                }

            }

        } else {
            this.components.showToast('El valor de descuento debe ser mayor a 0.', 'error');
        }

    }

    editPrice(new_price, disocunt) {
        let already_in_order = this.navParams.get('already_in_order');

        if (this.apply_to == 'all' || (this.item.quantity == 1 && this.apply_to == 1)) {
            this.item.discount_item = disocunt;
            this.item.have_discount = true;
            if (this.item.taxes_item) {
                this.item.taxes_item.forEach(tax => {
                    this.components.getTaxes(this.item, tax);
                });
                this.item.hidden_taxes = this.components.fixed(this.item.hidden_taxes * this.item.quantity);
            }

            if (!already_in_order) {
                this.pos.updateItemPrice(this.item, Number(new_price));
                this.components.showToast('Precio actualizado correctamente.', 'success');
                this.modalController.dismiss();
            } else {
                this.item.price = Number(new_price);
                this.api.updateDocument(`accounts/${this.auth.account}/ticket_items`, this.item.$key, this.item).then(() => {
                    this.components.showToast('Precio actualizado correctamente.', 'success');
                    this.modalController.dismiss();
                }, err => {
                    console.log(err);
                })
            }
        } else {
            if (!already_in_order) {
                const found = this.pos.items.findIndex(element => element.id == this.item.id);
                this.pos.items[found].quantity = this.item.quantity - this.apply_to;
                this.item.quantity = Number(this.apply_to);
                this.item.price = Number(new_price);
                this.item.discount_item = disocunt;
                this.item.have_discount = true;
                this.pos.addItem(this.item);
                this.components.showToast('Precio actualizado correctamente.', 'success');
                this.modalController.dismiss();
            } else {
                this.item.quantity = this.item.quantity - this.apply_to;

                if (this.item.taxes_item) {
                    this.item.taxes_item.forEach(tax => {
                        this.components.getTaxes(this.item, tax);
                    });
                    this.item.hidden_taxes = this.components.fixed(this.item.hidden_taxes * this.item.quantity);
                }

                this.api.updateDocument(`accounts/${this.auth.account}/ticket_items`, this.item.$key, this.item).then(() => {
                    let new_item = this.item;
                    new_item.price = Number(new_price);
                    new_item.quantity = Number(this.apply_to);
                    new_item.discount_item = disocunt;
                    new_item.have_discount = true;

                    if (new_item.taxes_item) {
                        new_item.taxes_item.forEach(tax => {
                            this.components.getTaxes(new_item, tax);
                        });
                        new_item.hidden_taxes = this.components.fixed(new_item.hidden_taxes * new_item.quantity);
                    }

                    this.api.addDocument(`accounts/${this.auth.account}/ticket_items`, new_item).then(() => {
                        this.components.showToast('Precio actualizado correctamente.', 'success');
                        this.modalController.dismiss();
                    }, err => {
                        console.log(err);
                    })
                }, err => {
                    console.log(err);

                })
            }
        }
    }
}
