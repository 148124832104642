import { Component } from '@angular/core';
import { App } from "@capacitor/app";
import { Plugins } from "@capacitor/core";
import { Network } from '@capacitor/network';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ModalController, Platform } from '@ionic/angular';
import { AppTrackingTransparency } from "capacitor-ios-app-tracking";
import { register } from 'swiper/element/bundle';
import { UpdateAppComponent } from './components/shared/update-app/update-app.component';
import { ApiService } from './services/api/api.service';
import { AuthService } from './services/auth/auth.service';
import { ComponentsService } from './services/components/components.service';
import { PosService } from './services/pos/pos.service';
import { PrintService } from './services/print/print.service';
const { Device } = Plugins;
register();

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {

	device: any;
	connected: any = true;
	update_modal: any;

	constructor(
		private platform: Platform,
		public pos: PosService,
		public print: PrintService,
		public modalController: ModalController,
		public components: ComponentsService,
		public auth: AuthService,
		public api: ApiService
	) {
		this.initializeApp();
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.components.isDesktop = (!this.platform.is('cordova'));
			if (!this.components.isDesktop) {
				this.api.getRef(`quanto`).ref
					.onSnapshot(snapshots => {
						snapshots.docChanges().forEach(async element => {
							let general = JSON.parse(JSON.stringify(element.doc.data()));
							await Device.getInfo().then(async data_device => {
								await App.getInfo().then(data_app => {
									if (data_device.platform == 'ios') {
										if (this.compareVersions(data_app.version, general.pos_version.ios) < 0) {
											this.updateApp();
										} else {
											this.update_modal.dismiss();
										}
									} else if (data_device.platform == 'android') {
										if (this.compareVersions(data_app.version, general.pos_version.android) < 0) {
											this.updateApp();
										} else {
											this.update_modal.dismiss();
										}
									}
								})
							})
						});
					})
			}

			this.platform.pause.subscribe(() => {
				if (!this.pos.scanner_open) {
					this.pos.clearOrder(undefined);
					this.auth.lockUser();
				}
			});

			this.print.setPrinters()

			if (this.platform.is('ios') || this.platform.is('android')) {
				Network.addListener('networkStatusChange', (status) => {
					if (status.connected) {
						if (!this.auth.account_data) {
							this.auth.init();
						} else {
							this.connected = true;
						}
					} else {
						console.log(this.connected);
						this.connected = false;
						this.pos.clearOrder(undefined);
						this.auth.lockUser();
					}
				});
				this.tryConnection();
			}

			Device.getInfo().then(data => {
				this.device = data;
				if (data.platform == 'ios') {
					this.trackingRequest();
				}
			})

			if (this.platform.is('cordova')) {
				this.setStatusBar();
			}

			console.log('%c¡DETENTE!', 'font-size: 30px; background: black; color: red');
			console.log('%cEsta función del navegador está pensada para desarrolladores. Si alguien te indicó que copiaras y pegaras algo aquí para habilitar una función de Quanto o para "hackear" la cuenta de alguien, se trata de un fraude. Si lo haces, esta persona podrá acceder a tu cuenta. Gracias F.Yorch', 'font-size: 18px; background: black; color: white');

		});
	}

	compareVersions(current: string, latest: string): number {
		const currentParts = current.split('.').map(Number);
		const latestParts = latest.split('.').map(Number);

		for (let i = 0; i < Math.max(currentParts.length, latestParts.length); i++) {
			const currentPart = currentParts[i] || 0;
			const latestPart = latestParts[i] || 0;

			if (currentPart < latestPart) {
				return -1;
			}
			if (currentPart > latestPart) {
				return 1;
			}
		}
		return 0;
	}

	async trackingRequest() {

		AppTrackingTransparency.getTrackingStatus().then(async (status: any) => {
			if (status.status == 'unrequested') {
				AppTrackingTransparency.requestPermission().then((status) => {
					console.log(status);
				});
			}
		});

	}

	convertToHours(date) {
		var time = date;
		var hours = Number(time.match(/^(\d+)/)[1]);
		var minutes = Number(time.match(/:(\d+)/)[1]);
		var AMPM = time.match(/\s(.*)$/)[1];
		if (AMPM == "PM" && hours < 12) hours = hours + 12;
		if (AMPM == "AM" && hours == 12) hours = hours - 12;
		var sHours = hours.toString();
		var sMinutes = minutes.toString();
		if (hours < 10) sHours = "0" + sHours;
		if (minutes < 10) sMinutes = "0" + sMinutes;
		return sHours + ":" + sMinutes;
	}

	async setStatusBar() {
		await StatusBar.show();
		await StatusBar.setStyle({ style: Style.Light });
	}

	convertToDate(date) {
		return `${new Date(date).getDate()}/${new Date(date).getMonth() + 1}/${new Date(date).getFullYear()}`;
	}

	tryConnection() {
		Network.getStatus().then(data => {
			if (data.connected) {
				if (!this.auth.account_data) {
					this.auth.init();
				} else {
					this.connected = true;
				}
			} else {
				this.connected = false;
				this.pos.clearOrder(undefined);
				this.auth.lockUser();
			}
		})
	}

	async updateApp() {
		this.update_modal = await this.modalController.create({
			component: UpdateAppComponent,
			cssClass: 'full-modal',
			animated: false
		});

		return await this.update_modal.present();
	}
}
