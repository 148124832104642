// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  --padding-start: 24px;
  --padding-top: 8px;
  --padding-bottom: 8px;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pos/cancel-notes/cancel-notes.component.scss"],"names":[],"mappings":"AAAA;EACG,qBAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;AACH","sourcesContent":["ion-item {\n   --padding-start: 24px;\n   --padding-top: 8px;\n   --padding-bottom: 8px;\n   font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
