import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { ComponentsService } from '../../../services/components/components.service';

@Component({
	selector: 'app-modify-price',
	templateUrl: './modify-price.component.html',
	styleUrls: ['./modify-price.component.scss'],
})
export class ModifyPriceComponent implements OnInit {

	item: any;
	apply_to: any = 'all';
	note: any;
	new_price: any;

	constructor(
		public modalController: ModalController,
		public components: ComponentsService,
		public navParams: NavParams,
		public api: ApiService,
		public auth: AuthService,
		public pos: PosService,
		public nav: IonNav
	) {
		this.item = this.navParams.get('item');
		this.note = this.item.notes;
		if (this.item.notes == undefined) {
			this.item.notes = '';
		}

	}

	ngOnInit() { }

	editPrice() {
		let total_discount = 0;

		if (this.item.discount_item) {

			if (this.item.discount_item.type == 'percentage') {
				total_discount += Number(Number(this.new_price * (Number(this.item.discount_item.amount) / 100)).toFixed(2));

			} else if (this.item.discount_item.type == 'fixed') {
				let new_price = this.new_price - this.item.discount_item.amount;

				if (new_price < 0) {
					total_discount += this.new_price;
				} else {
					total_discount += this.item.discount_item.amount;
				}
			}
		}


		if (this.apply_to == 'all' || (this.item.quantity == 1 && this.apply_to == 1)) {
			this.item.price = Number(this.new_price) - total_discount;
			this.item.notes = this.note;

			if (!this.item.order_key) {

				this.pos.updateItemPrice(this.item);
				this.components.showToast('Precio actualizado correctamente.', 'success');
				this.modalController.dismiss();
			} else {

				this.api.updateDocument(`accounts/${this.auth.account}/ticket_items`, this.item.$key, this.item).then(() => {
					this.components.showToast('Precio actualizado correctamente.', 'success');
					this.modalController.dismiss();
				}, err => {
					console.log(err);
				})
			}
		} else {
			if (!this.item.order_key) {
				const found = this.pos.items.findIndex(element => element.id == this.item.id);
				this.pos.items[found].quantity = this.item.quantity - this.apply_to;

				this.item.quantity = Number(this.apply_to);
				this.item.price = Number(this.new_price) - total_discount;
				this.item.notes = this.note;
				this.pos.addItem(this.item);
				this.components.showToast('Precio actualizado correctamente.', 'success');
				this.modalController.dismiss();
			} else {
				this.item.quantity = this.item.quantity - this.apply_to;

				this.api.updateDocument(`accounts/${this.auth.account}/ticket_items`, this.item.$key, this.item).then(() => {
					let new_item = this.item;
					new_item.price = Number(this.new_price) - total_discount;
					new_item.notes = this.note;
					new_item.quantity = Number(this.apply_to);

					this.api.addDocument(`accounts/${this.auth.account}/ticket_items`, new_item).then(() => {
						this.components.showToast('Precio actualizado correctamente.', 'success');
						this.modalController.dismiss();
					}, err => {
						console.log(err);
					})
				}, err => {
					console.log(err);

				})
			}
		}
	}
}
