// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  padding: 0;
}

.full-width {
  width: 100%;
  display: flex;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pos/ticket-options/ticket-options.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AACA;EACE,WAAA;EACA,aAAA;EACA,SAAA;AAEF","sourcesContent":["ion-label {\n  padding: 0;\n}\n.full-width {\n  width: 100%;\n  display: flex;\n  gap: 10px; \n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
