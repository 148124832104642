import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    AlertController,
    IonNav,
    ModalController,
    NavParams,
    Platform
} from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { PrintService } from 'src/app/services/print/print.service';
import { ClientsComponent } from '../../pos/clients/clients.component';
import { CreditComponent } from '../credit/credit.component';
import { GiftcardComponent } from '../giftcard/giftcard.component';
import { LinkPaymentComponent } from '../link-payment/link-payment.component';
import { PaymentBoardComponent } from '../payment-board/payment-board.component';
import { PaymentSuccessComponent } from '../payment-success/payment-success.component';
import { RemotePaymentComponent } from '../remote-payment/remote-payment.component';
import { SeparateItemsComponent } from '../separate-items/separate-items.component';
import { SplitAmountComponent } from '../split-amount/split-amount.component';
import { TicketSelectionComponent } from '../ticket-selection/ticket-selection.component';
import { VoucherComponent } from '../voucher/voucher.component';
import { MoreMethodsComponent } from '../more-methods/more-methods.component';
import { ModalBaseComponent } from '../../shared/modal-base/modal-base.component';
import { TranslateConfigService } from 'src/app/services/language/languages.service';
import { deleteField } from 'firebase/firestore';
@Component({
    selector: 'app-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit {
    level = 0;
    payment_method: any;
    slideOptions = {
        slidesPerView: 'auto',
        zoom: false,
        autoplay: false,
        loop: false,
        spaceBetween: 16,
    };
    order: any = false;
    loading: any = false;
    payments: any = [];
    discounts: any = [];
    total_payments: any = 0;
    total: any = 0;
    tip: any;
    splitted_amount: any = 0;
    pending_total: any = 0;
    more_tables: any = false;
    isWeb: any = true;
    params: any;
    items: any;
    user: any;
    board_open: any = false;
    gran_total: any = 0;
    gran_exempt: any = 0;
    total_discount: any = 0;
    iva_discount: any = 0;
    iva_rete: any = 0;
    discount_card: any = null;
    global_discount = 0

    constructor(
        public modalController: ModalController,
        private nav: IonNav,
        public navParams: NavParams,
        private api: ApiService,
        public auth: AuthService,
        public router: Router,
        public platform: Platform,
        public components: ComponentsService,
        public alertController: AlertController,
        public pos: PosService,
        public print: PrintService,
        public langService: TranslateConfigService
    ) {
        /* Checking the width of the screen and setting the isWeb variable to true or false. */
        if (this.platform.width() > 600) {
            this.isWeb = true;
        } else {
            this.isWeb = false;
        }
    }

    ngOnInit() {

    }

    ionViewDidEnter() {
        let params = this.navParams.data;
        this.global_discount = params.descuentoTotal
        if (params?.discount_card) this.discount_card = params.discount_card
        if (Object.keys(params).length > 0) {
            this.params = params;
            this.tip = JSON.parse(JSON.stringify(this.params.gran_tip));

            this.gran_total = JSON.parse(
                JSON.stringify(this.params.gran_total)
            );

            this.order = this.params.order;
            this.tip = JSON.parse(JSON.stringify(this.params.gran_tip));
            if (this.order.tip_removed) {
                this.tip = 0;
                this.gran_total = this.params.gran_total - this.params.gran_tip;
            }

            if (this.auth.account_data.fiscal) {
                if (this.order.tax_receipt == undefined || this.order.tax_receipt.type == 'ticket') {
                    if (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte') {
                        this.order['tax_receipt'] = { type: 'invoice' };
                    }
                }
            }

            this.getTotal();

            this.payments = [];

            this.api
                .getRef(`accounts/${this.auth.account}/ticket_payments`)
                .ref.where('order_key', '==', this.params.order_key)
                .orderBy('creation_date')
                .onSnapshot((snapshots: any) => {
                    snapshots.docChanges().forEach((element) => {
                        if (element.type == 'added') {
                            let payment = element.doc.data();
                            payment.$key = element.doc.id;
                            if (payment.creation_date !== null) {
                                payment.creation_date = payment.creation_date.toDate();
                            }
                            this.payments.push(payment);
                            this.getTotal();
                        } else if (element.type == 'modified') {
                            let payment = element.doc.data();
                            payment.$key = element.doc.id;
                            payment.creation_date = payment.creation_date.toDate();
                            this.payments[element.oldIndex] = payment;
                            this.getTotal();
                        } else if (element.type == 'removed') {
                            this.payments.splice(element.oldIndex, 1);
                            this.getTotal();
                        }
                    });
                },
                    (err) => {
                        console.log(err);
                    }
                );

        }
    }

    /**
     * It calculates the total amount of the ticket, the tip, the total payments, and the pending total
     */
    getTotal() {
        this.total_payments = 0;
        this.total = this.params.gran_subtotal + this.tip;
        this.gran_total = this.params.gran_subtotal + this.tip;

        if (this.order.client) {
            if (this.order.client.exempt) {
                this.gran_exempt = this.navParams.data.gran_hidden_taxes;
            } else {
                this.gran_exempt = 0;
            }
        }

        this.gran_exempt = this.components.fixed(this.gran_exempt)

        this.total = this.total - this.gran_exempt;
        this.total = this.components.fixed(this.total);
        this.gran_total = this.gran_total - this.gran_exempt;
        this.gran_total = this.components.fixed(this.gran_total);
        this.order.iva_rete = this.iva_rete;

        this.payments.forEach((element) => {
            this.total_payments += this.components.fixed(element.total);
        });
        this.pending_total = this.components.fixed((this.gran_total) - this.total_payments);

        if (this.order.splitted) {
            if (this.order.splitted_payments <= this.order.splitted) {
                this.splitted_amount = 0;
                this.splitted_amount = (this.total) / this.order.splitted;
            } else {
                this.splitted_amount = this.pending_total;
            }
        }
        this.total = this.components.fixed(this.total)
    }

    /**
     * It sets the payment_method property to the method argument
     * @param method - The payment method you want to select.
     */
    selectPaymentMethod(method) {
        this.payment_method = method;
    }

    /**
     * The function openLinkPayment() is called when the user clicks the "Link Payment" button. The
     * function then pushes the LinkPaymentComponent onto the navigation stack
     */
    openLinkPayment() {
        this.nav.push(LinkPaymentComponent, { level: this.level + 1 });
    }

    async giftCardPayment() {
        const modal = await this.modalController.create({
            component: GiftcardComponent,
            showBackdrop: true,
        });

        await modal.present();

        await modal.onDidDismiss().then((data) => {
            if (data.data) {
                this.openBoardPayment('giftcard', data.data);
            }
        });
    }

    async openCredit(validate_client = false) {
        if (!this.auth.license?.membership?.features?.payments?.credit) {
            const alert = await this.alertController.create({
                header: 'Error',
                message:
                    'Tu plan no admite cuentas por cobrar para poder acceder a esta opción incrementa tu plan',
                buttons: ['Aceptar'],
            });

            await alert.present();
        } else {
            if (this.payments.length > 0) {
                const alert = await this.alertController.create({
                    header: 'No puedes realizar una cuenta por cobrar de esta orden.',
                    message:
                        'Tu orden ya tiene pagos realizados por lo cual no puedes seleccionar esta orden como cuenta por cobrar',
                    buttons: ['Aceptar'],
                });

                await alert.present();
            } else {


                let valid = true;

                if (this.order.type !== 'delivery') {
                    if (!this.order.client) {
                        valid = false;
                    }
                }

                if (validate_client) {
                    valid = true;
                }

                if (valid) {
                    let pending = 0;

                    if (this.order.splitted && this.order.pending_splitted) {
                        pending = this.order.pending_splitted;
                    } else if (this.order.splitted && !this.order.pending_splitted) {
                        pending = this.splitted_amount;
                    } else {
                        pending = this.pending_total;
                    }

                    let client;

                    client = this.order.client;

                    let data = {
                        pending: pending,
                        branch: this.params.branch,
                        order: this.order,
                        gran_total: this.gran_total,
                        gran_hidden_taxes: this.components.fixed(this.navParams.data.gran_hidden_taxes),
                        gran_exempt: this.components.fixed(this.gran_exempt),
                        gran_subtotal:
                            this.navParams.data.gran_subtotal -
                            this.navParams.data.gran_hidden_taxes,
                        gran_tip: this.tip,
                        gran_discount: this.navParams.data.gran_discount,
                        items: this.params.order.items,
                        payments: this.payments,
                        client: client,
                        discounts: this.navParams.data.discounts
                    };


                    if (this.auth.license.membership.type == 'retail') {
                        if (this.items) {
                            data['items'] = this.items;
                        }
                    }

                    const modal = await this.modalController.create({
                        component: CreditComponent,
                        cssClass: 'pay-method-modal',
                        componentProps: data,
                    });

                    await modal.present();
                    await modal.onDidDismiss().then((data) => {
                        if (data.data) {
                            if (data.data.completed) {
                                this.components.showToast('Pago realizado con exito');
                                this.modalController.dismiss({
                                    completed: true,
                                    ticket_key: data.data.ticket_key,
                                });
                            }

                            if (data.data.items) {
                                this.items = data.data.items;
                            }
                        }
                    });
                } else {
                    this.addClient(true);
                }
            }
        }
    }

    async addClient(credit = false) {
        const modal = await this.modalController.create({
            component: ClientsComponent,
            showBackdrop: true,
            componentProps: {
                order: this.order,
            },
        });

        await modal.present();

        await modal.onDidDismiss().then(data => {
            if (data.data) {
                let client = data.data;

                if (this.order) {
                    this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
                        client: client
                    }).then(() => {
                        this.order.client = client;
                        this.pos.addClient(client);
                        this.components.showToast('Cliente agregado a tu orden');
                        this.getTotal();
                    }, err => {
                        console.log(err);
                    })
                } else {
                    this.pos.addClient(client);
                }

                if (credit) {
                    this.order.client = client;
                    this.openCredit(true);
                }
            }
        });
    }

    async openMoreMethods() {
        const modal = await this.modalController.create({
            component: ModalBaseComponent,
            cssClass: 'edit-item-modal',
            showBackdrop: true,
            backdropDismiss: true,
            componentProps: {
                rootPage: MoreMethodsComponent,
                params: {
                }
            }
        });

        modal.present();

        await modal.onDidDismiss().then((data) => {
            if (data.data) {
                this.openBoardPayment(data.data.method, false, data.data.method_data)
            }
        });
    }

    async openBoardPayment(payment_type, giftcard = false, other = false) {
        let pending = 0;

        if (this.order.splitted && this.order.pending_splitted) {
            pending = this.order.pending_splitted;
        } else if (this.order.splitted && !this.order.pending_splitted) {
            pending = this.splitted_amount;
        } else {
            pending = this.pending_total;
        }

        if (this.navParams.data.gran_hidden_taxes == undefined) {
            this.navParams.data.gran_hidden_taxes = 0;
        }

        let data = {
            pending: pending,
            branch: this.params.branch,
            order: this.order,
            gran_total: this.gran_total,
            gran_subtotal:
                this.navParams.data.gran_subtotal -
                this.navParams.data.gran_hidden_taxes,
            gran_hidden_taxes: this.components.fixed(this.navParams.data.gran_hidden_taxes),
            gran_exempt: this.gran_exempt,
            gran_tip: this.tip,
            gran_discount: this.navParams.data.gran_discount,
            discounts: this.navParams.data.discounts,
            items: this.params.order.items,
            payments: this.payments,
            payment_type: payment_type,
            other: false,
        };

        if (payment_type == 'giftcard') {
            data['giftcard'] = giftcard;
        }
        if (this.discount_card) {
            data['discount_card'] = this.discount_card;
        }

        if (other) {
            data.other = true;
            data['other_payment'] = other;
        }

        if (this.auth.license.membership.type == 'retail') {
            if (this.items) {
                data['items'] = this.items;
            }
        }

        const modal = await this.modalController.create({
            component: PaymentBoardComponent,
            cssClass: 'pay-board-modal',
            backdropDismiss: true,
            componentProps: data,
        });

        await modal.present().then(() => {
            this.board_open = true;
        });

        await modal.onDidDismiss().then((data) => {
            if (data?.data?.success === false) {
                this.modalController.dismiss(data.data)
            }
            if (data.data) {
                if (data.data.completed) {
                    this.discount_card = null;
                    this.components.showToast('Pago realizado con exito');
                    this.modalController.dismiss({
                        completed: true,
                        ticket_key: data.data.ticket_key,
                    });
                } else {
                    if (this.order.splitted_payments) {
                        this.order.splitted_payments = data.data.data_order.splitted_payments;
                    }
                }


                if (data.data.items) {
                    this.items = data.data.items;
                }
            }
            this.board_open = false;
        });
    }

    /**
     * The function openRemotePayment() is called when the user clicks on the "Remote Payment" button. The
     * function pushes the RemotePaymentComponent onto the navigation stack, and passes the
     * RemotePaymentComponent the value of the current level plus one
     */
    openRemotePayment() {
        this.nav.push(RemotePaymentComponent, { level: this.level + 1 });
    }

    /**
     * It opens a modal that allows the user to select items from the menu
     */
    async OpenSelectItems() {
        let data = {
            order_key: this.params.order_key,
            total: this.total + this.tip,
        };

        if (this.pending_total > 0) {
            data = {
                order_key: this.params.order_key,
                total: this.pending_total,
            };
        }

        const modal = await this.modalController.create({
            component: SeparateItemsComponent,
            cssClass: 'select-items-modal',
            showBackdrop: false,
            animated: false,
            backdropDismiss: true,
            componentProps: data,
        });

        await modal.present().then(() => {
            this.board_open = true;
        });

        await modal.onDidDismiss().then((data) => {
            this.board_open = false;
            if (data.data) {
                this.modalController.dismiss();
                this.router.navigate(['/pos/' + data.data]);
            }
        });
    }

    /**
     * It creates a modal that displays the ticket selection component, and when the modal is dismissed,
     * it sets the ticket_key to the data returned from the modal
     */
    async selectTicket() {
        const modal = await this.modalController.create({
            component: TicketSelectionComponent,
            cssClass: 'tickets-modal',
            canDismiss: true,
            showBackdrop: true,
            backdropDismiss: true,
            componentProps: {
                table: this.order.table,
            },
        });
        await modal.present();

        modal.onDidDismiss().then((data) => {
            if (data.data) {
                this.router.navigateByUrl(`/pos/${data.data}`).then((response) => {
                    this.pos.updateOrderTicket(data.data);
                    this.modalController.dismiss();
                });
            }
        });
    }

    /**
     * The function paymentSuccess() is called when the user clicks the "Pay Now" button. It pushes the
     * PaymentSuccessComponent onto the navigation stack
     */
    paymentSuccess() {
        this.nav.push(PaymentSuccessComponent, { level: this.level + 1 });
    }

    /**
     * It checks if the ticket has any payments, if it does, it shows an alert, if it doesn't, it shows a
     * modal
     */
    async splitPayment() {
        if (!this.auth.license?.membership?.features?.payments?.split) {
            const alert = await this.alertController.create({
                header: 'Error',
                message:
                    'Tu plan no admite dividir cobros, para poder acceder a esta opción incrementa tu plan.',
                buttons: ['Aceptar'],
            });

            await alert.present();
        } else {
            if (this.total_payments > 0) {
                const alert = await this.alertController.create({
                    header: 'No puedes dividir este pago.',
                    message:
                        'Ya realizaste pagos a este ticket, por lo tanto ya no puedes dividir los pagos',
                    buttons: ['Aceptar'],
                });

                await alert.present();
            } else {
                const modal = await this.modalController.create({
                    component: SplitAmountComponent,
                    cssClass: 'number-modal auto-height',
                    canDismiss: true,
                    showBackdrop: true,
                    backdropDismiss: true,
                    componentProps: {
                        order: this.order,
                        total: this.total,
                        tip: this.tip,
                    },
                });
                await modal.present();

                modal.onDidDismiss().then((data) => {
                    if (data.data) {
                        let splitted = data.data;
                        this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
                            splitted: Number(splitted),
                            splitted_payments: 1,
                        }).then(data => {
                            this.pos.splitted = Number(splitted);
                            this.pos.splitted_payments = 1;
                            this.order.splitted = Number(splitted);
                            this.order.splitted_payments = 1;
                            this.components.showToast('Pago dividido con exito');
                            this.getTotal();
                        }, (err) => {
                            console.log(err);
                        });


                    }
                });
            }
        }
    }

    /**
     * It creates an alert that asks the user if they want to unsplit the payment, if they click the
     * confirm button, it updates the ticket document in the database
     */
    async unsplitPayment() {

        if (this.payments.length <= 0) {
            this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
                splitted: false,
                splitted_payments: deleteField(),
                pending_splitted: deleteField(),
            }).then(() => {
                this.pos.splitted = 0;
                this.pos.splitted_payments = 0;
                this.order.splitted = 0;
                this.order.splitted_payments = 0;
            }, (err) => {
                console.log(err);
            });
        } else {
            const alert = await this.alertController.create({
                header: '¿Estas seguro que deseas unir los pagos?',
                message: 'Si realizas esta acción no podras volver a dividir los pagos',
                buttons: [
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                    },
                    {
                        text: 'Aceptar',
                        role: 'destructive',
                        id: 'confirm-button',
                        handler: () => {
                            this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
                                splitted: false,
                                splitted_payments: deleteField(),
                                pending_splitted: deleteField(),
                            }).then(() => {
                                this.pos.splitted = 0;
                                this.pos.splitted_payments = 0;
                                this.order.splitted = 0;
                                this.order.splitted_payments = 0;
                            }, (err) => {
                                console.log(err);
                            });
                        },
                    },
                ],
            });
            await alert.present();
        }
    }

    /**
     * It shows a confirmation alert to the user, and if the user confirms, it deletes the payment from
     * the database
     * @param payment - The payment object that we want to delete.
     */
    async deletePayment(payment) {
        const alert = await this.alertController.create({
            header: `¿Estas seguro que deseas eliminar el pago de $${payment.total}?`,
            message: 'Si realizas esta accion no podras revertir.',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                },
                {
                    text: 'Eliminar pago',
                    role: 'destructive',
                    id: 'confirm-button',
                    handler: () => {
                        this.components.showLoader('Eliminando pago...').then(() => {
                            this.api
                                .deleteDocument(
                                    `accounts/${this.auth.account}/ticket_payments`,
                                    payment.$key
                                )
                                .then((data) => {
                                    this.order.splitted_payments = this.order.splitted_payments - 1;
                                    this.api.updateSplittedPayments(this.order)
                                    this.components.dismissLoader();
                                });
                        });
                    },
                },
            ],
        });
        await alert.present();
    }

    /**
     * This function displays an alert asking the user to confirm the removal of a tip and updates a
     * document in the API accordingly.
     */
    async removeTip() {
        const alert = await this.alertController.create({
            header: 'Eliminar propina',
            message: `¿Estas seguro que deseas eliminar la propina?, esta accion no se puede revertir.`,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                },
                {
                    text: 'Eliminar',
                    role: 'destructive',
                    handler: () => {
                        this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
                            tip_removed: true,
                        }).then((data) => {
                            this.tip = 0;
                            this.order.tip_removed = true;
                            this.getTotal();
                        });
                    },
                },
            ],
        });

        await alert.present();
    }

    /**
     * This function updates a document in a specific location with a new value and then calls another
     * function to get the total.
     */
    addTip() {
        this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
            tip_removed: false,
        }).then((data) => {
            console.log(this.params.gran_tip);
            this.order.tip_removed = false;
            this.tip = JSON.parse(JSON.stringify(this.params.gran_tip));
            this.getTotal();
        });
    }

    /**
     * This function opens a modal with a voucher component and updates the tax receipt value of an order
     * or a point of sale based on user input.
     */
    async voucherType() {
        const modal = await this.modalController.create({
            component: VoucherComponent,
            cssClass: 'auto-height',
            showBackdrop: true,
            componentProps: {
                order: this.order,
                type: this.order.tax_receipt.type
            },
        });

        await modal.present();
        await modal.onDidDismiss().then((data) => {
            if (data.data) {
                let fiscal_type = data.data;

                this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
                    tax_receipt: data.data,
                }).then(data => {
                    this.pos.changeFiscal(fiscal_type);
                })

            }
        });
    }

    /**
     * The function toggles the exempt status of an order or point of sale transaction and updates it in
     * the database.
     */
    changeExempt() {
        if (this.auth.license.membership.type == 'retail') {
            if (this.order) {
                if (!this.order.exempt) {
                    this.order.exempt = true;
                } else {
                    this.order.exempt = false;
                }
                this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key,
                    {
                        exempt: this.order.exempt,
                    }
                );
            } else {
                if (!this.pos.exempt) {
                    this.pos.exempt = true;
                } else {
                    this.pos.exempt = false;
                }
                this.getTotal();
            }
        } else {
            if (!this.order.exempt) {
                this.order.exempt = true;
            } else {
                this.order.exempt = false;
            }
            this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key,
                {
                    exempt: this.order.exempt,
                }
            )
                .then((data) => { });
        }
    }

    /**
     * The function calculates the subtotal and exempt amounts, and then prints an order with various
     * parameters.
     */
    printOrder() {
        let exempt = this.navParams.data.gran_hidden_taxes
        let subtotal = (this.params.gran_subtotal + this.params.gran_discount);
        this.print.printOrder(this.params.order, subtotal, this.gran_total, this.tip, this.params.gran_discount, false, exempt)
    }
}
