// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-thumbnail {
  background: var(--ion-color-light);
}`, "",{"version":3,"sources":["webpack://./src/app/components/pos/refund-ticket/refund-ticket.component.scss"],"names":[],"mappings":"AAAA;EACG,kCAAA;AACH","sourcesContent":["ion-thumbnail {\n   background: var(--ion-color-light);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
