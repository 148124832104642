import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NavigationExtras, Router } from '@angular/router';
import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';
import { ModalController } from '@ionic/angular';
import { deleteField, serverTimestamp } from 'firebase/firestore';
import { BehaviorSubject, from } from 'rxjs';
import { CountdownTimerComponent } from 'src/app/components/shared/countdown-timer/countdown-timer.component';
import { ComponentsService } from "../components/components.service";
import { PosService } from '../pos/pos.service';
@Injectable({
    providedIn: 'root'
})

export class AuthService {
    public isLicenseAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isAccountAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    token: any = '';
    peripheral: any = false;
    peripheral_device: any = false;
    account: any;
    branch: any;
    device: any;
    device_name: any;
    device_code: any;
    timer: any;
    license: any = false;
    countdownPresent: any = false;
    account_data: any;
    device_number: any;
    branch_data: any;
    uid: any;
    kds_devices: any = [];
    device_data: any = [];
    report: any;
    taxes: any = [];
    report_listener: any = false;
    dte_full_contingency: any = false;
    dte_contingency: any = false;
    contingency_error: any;
    pos_roles: any = [
        {
            type: 'manager',
            roles: ['all']
        },
        {
            type: 'employee',
            roles: ['pos']
        },
        {
            type: 'cashier',
            roles: ['pos', 'clients', 'printers', 'transactions', 'my-orders', 'closings']
        }
    ]

    constructor(
        public database: AngularFireDatabase,
        private router: Router,
        public db: AngularFirestore,
        private angularAuth: AngularFireAuth,
        public modalController: ModalController,
        public components: ComponentsService,
        private ble: BLE,
        public pos: PosService,
    ) {

        this.init();
    }

    init() {
        Network.getStatus().then(data => {
            if (data.connected) {

                this.angularAuth.authState.subscribe((firebaseUser) => {
                    if (firebaseUser !== null) {
                        this.uid = firebaseUser.uid;
                        this.loadToken();
                    } else {
                        this.isAuthenticated.next(false);
                    }
                });

            } else {
                this.init();
            }
        })
    }

    async loadToken() {
        const account = await Preferences.get({ key: 'account' });
        const branch = await Preferences.get({ key: 'branch' });
        const device = await Preferences.get({ key: 'device' });
        const device_name = await Preferences.get({ key: 'device_name' });
        const device_code = await Preferences.get({ key: 'device_code' });
        const device_number = await Preferences.get({ key: 'device_number' });

        if (account && account.value && branch && branch.value && device && device.value) {
            this.account = account.value;
            this.branch = branch.value;
            this.device = device.value;
            this.device_name = device_name.value;
            this.device_number = device_number.value;
            this.device_code = device_code.value;
            this.isAuthenticated.next(true);
            this.checkBranch();
            this.checkDevice();
            this.checkMembership();
            this.checkAccount();

            this.db.collection(`quanto`).ref
                .doc('support')
                .onSnapshot((snapshot: any) => {
                    this.dte_contingency = snapshot.data().dte_contingency;
                    this.dte_full_contingency = snapshot.data().full_contingency;
                    this.contingency_error = snapshot.data().contingency_error;
                })

        } else {
            this.isAuthenticated.next(false);
        }
    }

    registerDevice(code) {
        return new Promise((resolve, reject) => {

            this.angularAuth.signInAnonymously()
                .then((data) => {

                    let uid = data.user.uid;

                    this.db.collection('devices').ref
                        .where('code', '==', code)
                        .where('type', '==', 'POS')
                        .get()
                        .then((snapshots: any) => {

                            if (snapshots.empty) {
                                this.angularAuth.currentUser.then(user => {
                                    if (user && user.isAnonymous) {
                                        user.delete()
                                            .then(() => {
                                                this.angularAuth.signOut().then(() => {
                                                    reject(false);
                                                }, error => {
                                                    console.log('Error al hacer logout:', error);
                                                })
                                            })
                                            .catch((error) => {
                                                reject(false);
                                            });
                                    }
                                })
                            } else {
                                snapshots.forEach(async element => {
                                    let device = element.data();
                                    device.$key = element.id;

                                    if (device.available) {
                                        from(Preferences.set({ key: 'account', value: device.account_key }));
                                        from(Preferences.set({ key: 'branch', value: device.branch_key }));
                                        from(Preferences.set({ key: 'device', value: device.$key }));

                                        const device_id = await Device.getId();

                                        let batch = this.db.firestore.batch();

                                        batch.update(this.db.firestore.collection(`accounts`).doc(device.account_key), {
                                            logged_device: true
                                        });

                                        batch.update(this.db.firestore.collection(`devices`).doc(device.$key), {
                                            available: false,
                                            device_id: device_id.identifier,
                                            updated_at: serverTimestamp(),
                                            activeUID: uid
                                        });

                                        this.db.collection(`accounts/${device.account_key}/devices/`).ref
                                            .doc(device.$key)
                                            .get()
                                            .then((snapshot: any) => {
                                                let _device = snapshot.data();
                                                _device.$key = snapshot.id;

                                                from(Preferences.set({ key: 'device_name', value: _device.name }));
                                                from(Preferences.set({ key: 'device_code', value: _device.code }));
                                                from(Preferences.set({ key: 'device_number', value: String(_device.number) }));

                                                batch.update(this.db.firestore.collection(`accounts/${device.account_key}/devices/`).doc(device.$key), {
                                                    available: false,
                                                    device_id: device_id.identifier,
                                                    updated_at: serverTimestamp()
                                                });

                                                batch.commit().then(data => {
                                                    this.isAuthenticated.next(true);
                                                    this.loadToken();
                                                    resolve(true);
                                                }, err => {
                                                    console.log(err);
                                                    reject();
                                                });

                                            }).catch((error: any) => {
                                                reject(error);
                                            });
                                    } else {
                                        const user = this.angularAuth.currentUser.then(user => {
                                            if (user && user.isAnonymous) {
                                                user.delete()
                                                    .then(() => {
                                                        this.angularAuth.signOut().then(() => {
                                                            reject(false);
                                                        }, error => {
                                                            console.log('Error al hacer logout:', error);
                                                        })
                                                    })
                                                    .catch((error) => {
                                                        reject(false);
                                                    });
                                            }
                                        })

                                    }
                                });
                            }
                        }, err => {

                            this.angularAuth.currentUser.then(user => {
                                if (user && user.isAnonymous) {
                                    user.delete()
                                        .then(() => {
                                            this.angularAuth.signOut().then(() => {
                                                reject(false);
                                            }, error => {
                                                console.log('Error al hacer logout:', error);
                                            })
                                        })
                                        .catch((error) => {
                                            reject(false);
                                        });
                                }
                            })
                        })

                }, err => {
                    console.log(err);
                })

        })
    }

    checkDevice() {
        this.db.collection(`accounts/${this.account}/devices`).ref
            .doc(this.device)
            .get()
            .then((snapshot: any) => {
                let device_details = snapshot.data();
                this.device_data = device_details;
                this.device_data.$key = snapshot.id;
            }, err => {
                console.log(err);
            });
    }

    async checkMembership() {
        this.db.collection(`licenses`).ref
            .where('account_key', '==', this.account)
            .onSnapshot((snapshots: any) => {
                snapshots.forEach(element => {
                    let license = element.data();
                    license.$key = element.id;
                    this.license = license;
                    this.license.creation_date = this.license.creation_date.toDate();

                    if (this.license.free_trial) {
                        this.license.expiration_trial = this.license.creation_date.setDate(this.license.creation_date.getDate() + 7);
                        this.license.expiration_trial = new Date(this.license.expiration_trial);
                    }
                });
                this.isLicenseAvailable.next(true);
            }, err => {

                console.log(err);
            });
    }

    checkBranch() {
        this.db.collection(`accounts/${this.account}/branches`).ref
            .doc(this.branch)
            .get()
            .then((snapshot: any) => {
                let branch_details = snapshot.data();
                this.branch_data = branch_details;
                this.branch_data.$key = snapshot.id;
            }, err => {
                console.log(err);
            });
    }

    async checkAccount() {
        this.db.collection(`accounts`).ref
            .doc(this.account)
            .onSnapshot((snapshots: any) => {
                if (this.account_data == undefined) {
                    this.account_data = snapshots.data();
                }

                if (snapshots.data().dte_counter) {
                    this.account_data.dte_counter = snapshots.data().dte_counter;
                }

                if (this.account_data.dte_counter == undefined) {
                    this.account_data.dte_counter = 0;
                }

                this.isAccountAvailable.subscribe((value) => {
                    if (!value) {
                        this.report_listener = this.db.collection(`accounts/${this.account}/reports`).ref
                            .where('branch_key', '==', this.branch)
                            .where('name', '==', this.components.dateToString(new Date()))
                            .onSnapshot((snapshots: any) => {

                                if (snapshots.empty) {
                                    this.setNewReport();
                                } else {
                                    snapshots.forEach(element => {
                                        this.report = element.data();
                                        this.report.$key = element.id;
                                    });
                                }
                                this.getReports();

                            })
                        this.db.collection(`accounts/${this.account}/users`).ref
                            .where('user_key', '==', this.token)
                            .get()
                            .then((snapshots: any) => {
                                snapshots.forEach(element => {
                                    this.account_data.user = element.data();
                                    this.account_data.user.$key = element.id;
                                    this.getUserType();
                                })
                            })
                        this.db.collection(`accounts/${this.account}/taxes`).ref
                            .get()
                            .then((snapshots: any) => {
                                snapshots.forEach(element => {
                                    this.taxes.push(element.data());
                                })
                            })
                        Preferences.get({ key: 'peripheral' }).then(peripheral => {
                            this.ble.isConnected(peripheral.value).then(data => {
                                this.peripheral = peripheral.value;
                            }, err => {
                                this.peripheral = false;
                                Preferences.remove({ key: 'peripheral' });
                            })
                        })
                        Preferences.get({ key: 'peripheral_device' }).then(peripheral_device => {
                            this.ble.isConnected(peripheral_device.value).then(data => {
                                this.peripheral_device = peripheral_device.value;
                            }, err => {
                                this.peripheral_device = false;
                                Preferences.remove({ key: 'peripheral_device' });
                            })
                        })
                        this.isAccountAvailable.next(true);
                    }
                });
            }, err => {
                console.log('Este es el error', err);
            });

    }

    getReports() {
        setInterval(() => {
            if (this.report_listener) {
                let today = this.components.dateToString(new Date());
                if (today != this.report?.name) {
                    this.report_listener();
                    this.report_listener = false;
                }
            }
            if (!this.report_listener) {
                this.report_listener = this.db.collection(`accounts/${this.account}/reports`).ref
                    .where('branch_key', '==', this.branch)
                    .where('name', '==', this.components.dateToString(new Date()))
                    .onSnapshot((snapshots: any) => {
                        if (snapshots.empty) {
                            this.setNewReport();
                        } else {
                            snapshots.forEach(element => {
                                this.report = element.data();
                                this.report.$key = element.id;
                            });
                        }
                    })
            }

        }, 10000);
    }

    setNewReport() {
        this.report = {
            name: this.components.dateToString(new Date()),
            branch_key: this.branch,
            date: new Date(),
            total_taxes: 0,
            total_orders: 0,
            total_discounts: 0,
            total_sales: 0,
            total_tip: 0,
            total_credit: 0,
            sales_data: [],
            sales_heatmap: [],
            clients_data: [{
                name: 'Hombres',
                quantity: 0,
                key: 'mens',
                sales_heatmap: [],
            }, {
                name: 'Mujeres',
                quantity: 0,
                key: 'womens',
                sales_heatmap: [],
            }, {
                name: 'Niños',
                quantity: 0,
                key: 'childrens',
                sales_heatmap: [],
            }],
            types_data: [],
            employees_data: [],
            total_tables: 0,
            total_clients: 0,
            payment_methods: [],
            products: [],
            total_refunds: 0,
            total_refund_taxes: 0,
            deliveries_data: [],
            total_deliveries: 0,
            total_deliveries_orders: 0,
            deliveries_sales: [],
            deliveries_heatmap: [],
            tables_data: [],
            mens: 0,
            womens: 0,
            childrens: 0,
            giftcards: 0,
            total_giftcards: 0,
        };
    }

    async getMembership() {
        return new Promise((resolve, reject) => {
            this.db.collection(`licenses`).ref
                .where('account_key', '==', this.account)
                .onSnapshot((snapshots: any) => {
                    snapshots.forEach(element => {
                        let membership = element.data().membership;
                        resolve(membership);
                    });
                }, err => {
                    console.log(err);
                });
        })
    }

    async getBranch() {
        return new Promise((resolve, reject) => {
            this.db.collection(`accounts/${this.account}/branches`).ref
                .doc(this.branch)
                .onSnapshot((snapshots: any) => {
                    let branch = snapshots.data();
                    return (branch);
                }, err => {
                    console.log(err);
                    reject(err)
                });
        })
    }

    checkCode(code) {
        return new Promise((resolve, reject) => {
            this.components.showLoader('Ingresando a Quanto POS...', 'dark').then(() => {
                let real_code = code.slice(0, 4);
                this.db.collection(`accounts/${this.account}/users`).ref
                    .where('code', '==', real_code)
                    .where('pos', '==', true)
                    .where('branches', 'array-contains', this.branch)
                    .get()
                    .then((snapshots: any) => {

                        if (snapshots.empty) {
                            reject(false);
                        } else {
                            snapshots.forEach(element => {
                                this.account_data.user = element.data();
                                this.account_data.user.$key = element.id;
                                this.token = element.id;

                                this.db.collection(`accounts/${this.account}/branches`).ref
                                    .doc(this.branch)
                                    .get()
                                    .then((snapshot: any) => {
                                        let branch = snapshot.data();
                                        this.startCoutdown();
                                        this.getUserType();
                                        resolve(branch);
                                    }, err => {
                                        console.log(err);
                                    });
                            });
                        }
                    }, err => {
                        console.log(err);
                        reject();
                    });
            })
        });
    }

    getUserType() {
        this.pos_roles.forEach(element => {
            let rol = element;

            if (JSON.stringify(rol.roles) == JSON.stringify(this.account_data.user.pos_roles)) {
                this.account_data.user.type = rol.type;
            }
        });
    }

    startCoutdown() {
        let countdown = this.account_data?.timer;

        if (countdown) {
            this.timer = setInterval(() => {
                if (this.token) {
                    if (countdown > 0) {
                        countdown -= 1;
                        if (countdown == 30) {
                            this.showTimer();
                        } else if (countdown == 0) {
                            clearInterval(this.timer);
                            this.lockUser();
                        }
                    }
                }
            }, 1000);
        }

        addEventListener('mousemove', e => {
            countdown = this.account_data?.timer;
            if (this.countdownPresent) {
                this.modalController.dismiss();
            }
        });

        addEventListener('keydown', e => {
            countdown = this.account_data?.timer;
            if (this.countdownPresent) {
                this.modalController.dismiss();
            }
        })
    }

    async showTimer() {
        if (!this.countdownPresent) {
            this.countdownPresent = true;
            const modal = await this.modalController.create({
                component: CountdownTimerComponent,
                cssClass: 'coutdown-modal',
                animated: false,
                backdropDismiss: false
            });
            await modal.present();
            await modal.onDidDismiss().then(() => (this.countdownPresent = false));
        }
    }

    async lockUser() {
        this.pos.clearOrder(undefined);

        /* Esto cierra cualquier ventana que esta abierta */
        const modals = Array.from(document.getElementsByTagName("ion-modal"));
        const popovers = Array.from(document.getElementsByTagName("ion-popover"));
        const alerts = Array.from(document.getElementsByTagName("ion-alert"));

        for (const modal of modals) await modal.dismiss();
        for (const popover of popovers) await popover.dismiss();
        for (const alert of alerts) await alert.dismiss();

        Preferences.remove({ key: 'token' }).then(data => {
            this.token = '';
            this.router.navigate(['/code'], { replaceUrl: true, clearHistory: true, } as NavigationExtras);
        })
    }

    async logOut() {
        try {
            await Preferences.clear();
            clearInterval(this.timer);
            const batch = this.db.firestore.batch();

            batch.update(this.db.firestore.collection('devices').doc(this.device), {
                available: true,
                device_id: '',
                activeUID: deleteField()
            });
            batch.update(this.db.firestore.collection(`accounts/${this.account}/devices`).doc(this.device), {
                available: true
            });

            await batch.commit();

            const user = await this.angularAuth.currentUser;

            if (user && user.isAnonymous) {
                try {
                    await user.delete();
                    await this.angularAuth.signOut();
                } catch (error) {
                    console.error('Error al eliminar usuario anónimo o cerrar sesión:', error);
                }
            } else {
                await this.angularAuth.signOut();
            }
            this.clearSession();
            this.router.navigate(['/register'], { replaceUrl: true, clearHistory: true } as NavigationExtras);
            window.location.reload();
        } catch (error) {
            console.error('Error durante el proceso de logout:', error);
            throw error;
        }
    }

    cleanToken() {
        Preferences.remove({ key: 'token' });
    }

    clearSession() {
        this.isLicenseAvailable.next(false);
        this.isAccountAvailable.next(false);
        this.isAuthenticated.next(false);
        this.token = '';
        this.account = null;
        this.branch = null;
        this.device = null;
        this.device_name = null;
        this.device_code = null;
        this.timer = null;
        this.license = false;
        this.countdownPresent = false;
        this.account_data = null;
        this.device_number = null;
        this.branch_data = null;
        this.uid = null;
        this.report = null;
    }
}
