// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-data {
  height: calc(100% + 32px);
}

img {
  width: 120px;
  margin-bottom: 75px;
}

h1 {
  font-size: 28px !important;
  margin-bottom: 16px;
}

p {
  font-size: 18px;
}

ion-button {
  font-size: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/update-app/update-app.component.scss"],"names":[],"mappings":"AAAA;EACG,yBAAA;AACH;;AAEA;EACG,YAAA;EACA,mBAAA;AACH;;AAEA;EACG,0BAAA;EACA,mBAAA;AACH;;AAEA;EACG,eAAA;AACH;;AAEA;EACG,0BAAA;AACH","sourcesContent":[".empty-data {\n   height: calc(100% + 32px);\n}\n\nimg {\n   width: 120px;\n   margin-bottom: 75px;\n}\n\nh1 {\n   font-size: 28px !important;\n   margin-bottom: 16px;\n}\n\np {\n   font-size: 18px;\n}\n\nion-button {\n   font-size: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
