import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as currency from 'currency.js';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
	selector: 'app-select-amount-board',
	templateUrl: './select-amount-board.component.html',
	styleUrls: ['./select-amount-board.component.scss'],
})
export class SelectAmountBoardComponent  {
	slideOptions = {
		slidesPerView: 'auto',
		zoom: false,
		autoplay: false,
		loop: false,
	};

	currency_amount: any = 0;
	amount: any = '';
	availableAmount: any;
	total: number = 0;
	

	constructor(
		public modalController: ModalController,
		public api: ApiService,
		public navParams: NavParams,
		public components: ComponentsService,
		public auth: AuthService,
		public pos: PosService
	) {
		this.total = this.navParams.get('total');
		this.availableAmount = this.navParams.get('availableAmount');
	}



	setNumber(number) {
		this.amount += number;
		this.currency_amount = currency(this.amount, {
			fromCents: true,
			precision: 2,
		});
	}

	clearAmount() {
		this.amount = '';
		this.currency_amount = currency(this.amount, {
			fromCents: true,
			precision: 2,
		});
	}

	setOption(value) {
		let amount = Number(this.amount);
		amount += Number(value);
		this.amount = amount.toString();

		this.currency_amount = currency(this.amount, {
			fromCents: true,
			precision: 2,
		});
	}

	setTotal() {
		const selectedAmount = Math.min(this.availableAmount, this.total);
		this.amount = selectedAmount.toString();
		this.currency_amount = currency(this.amount);
	}

	removeNumber() {
		this.amount = this.amount.substring(0, this.amount.length - 1);
		this.currency_amount = currency(this.amount, {
			fromCents: true,
			precision: 2,
		});
	}

	useAmountSelected(){
		this.modalController.dismiss({ value: this.currency_amount.value})
	}

	isAmountInvalid(value: number): boolean {
		const newAmount = Number(this.amount) + value;
		return newAmount > this.availableAmount || newAmount > this.total;
	}


}

