// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quantity-input {
  background: var(--ion-color-light);
}
.quantity-input ion-button {
  --background: white !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pos/edit-item/edit-item.component.scss"],"names":[],"mappings":"AAAA;EACG,kCAAA;AACH;AACG;EACG,8BAAA;AACN","sourcesContent":[".quantity-input {\n   background: var(--ion-color-light);\n\n   ion-button {\n      --background: white !important;\n   }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
