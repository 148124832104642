import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { AuthService } from './../../../services/auth/auth.service';

@Component({
	selector: 'app-refund-item',
	templateUrl: './refund-item.component.html',
	styleUrls: ['./refund-item.component.scss'],
})
export class RefundItemComponent implements OnInit {

	item: any;
	quantity: any = 1;
	note: any = '';
	return_stock: any = false;
	other: any = false;
	message: any = ''
	ticket: any;

	constructor(
		public navParams: NavParams,
		public components: ComponentsService,
		private api: ApiService,
		public modalController: ModalController,
		public auth: AuthService

	) {
		this.item = JSON.parse(JSON.stringify(navParams.data.item));
		this.note = JSON.parse(JSON.stringify(navParams.data.reason));
		this.ticket = JSON.parse(JSON.stringify(navParams.data.ticket));
	}

	ngOnInit() {

	}

	changeQuantity(type) {
		if (type == 'add') {
			if (this.quantity + 1 > this.item.quantity) {
				this.components.showToast('Limite de items para anular');
			} else {
				this.quantity++;
			}

		}
		else if (type == 'minus') {
			if (this.quantity - 1 < 1) {
				this.components.showToast('Para anular debes seleccionar al menos uno');
			} else {
				this.quantity--;
			}

		}
	};

	refundItem() {
		if (this.ticket.total_refunds == undefined) {
			this.ticket.total_refunds = 0;
		}
		this.components.showLoader('Realizando devoluci�n...').then(() => {
			this.api.refundItem(this.ticket, this.item, this.note, this.message, this.quantity, this.return_stock).then(response => {
				this.components.dismissLoader();
				this.modalController.dismiss({ item_refunded: true, refund: response });
			}, err => {

				this.components.dismissLoader();
			});
		});
	}

}
