import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IonNav, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
	selector: 'app-payment-success',
	templateUrl: './payment-success.component.html',
	styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {

	ticket_key: any;
	ticket: any;
	payments: any = [];
	payment_key: any;
	payment: any;
	total: any = 0;
	pending_payment: any = 0;
	discounts: any = [];

	constructor(
		public modalController: ModalController,
		public navParams: NavParams,
		private api: ApiService,
		private nav: IonNav,
		public router: Router,
		public auth: AuthService
	) {
		this.ticket_key = this.navParams.data.ticket_key;
		this.payment_key = this.navParams.data.payment_key;

		if (this.payment_key) {
			this.api.getDocument(`accounts/${this.auth.account}/payments`, this.payment_key).then(payment => {
				this.payment = payment;
			}, err => {
				console.log(err);
			})
		}

		this.api.getDocument(`accounts/${this.auth.account}/tickets`, this.ticket_key).then(data => {
			this.ticket = data;
			this.getTotal();

			if (this.ticket.status == 'completed') {
				this.router.navigate(['pos']);
			}

			this.api.getRef(`accounts/${this.auth.account}/payments`).ref
				.where('ticket_key', '==', this.ticket_key)
				.orderBy('creation_date')
				.onSnapshot((snapshots:any) => {
					snapshots.docChanges().forEach(element => {
						if (element.type == 'added') {
							let payment = element.doc.data();
							payment.$key = element.doc.id;
							payment.creation_date = payment.creation_date.toDate();
							this.payments.push(payment);
							this.getTotal();
						}
					});
				}, err => {
					console.log(err);
				})

			this.api.getRef(`accounts/${this.auth.account}/ticket_discounts`).ref
				.where('ticket_key', '==', this.ticket_key)
				.onSnapshot((snapshots:any) => {
					snapshots.docChanges().forEach(element => {
						if (element.type == 'added') {
							let discount = element.doc.data();
							discount.$key = element.doc.id;
							this.discounts.push(discount);
							this.getTotal();
						}
					}, err => {
						console.log(err);
					});
				}, err => {
					console.log(err);
				});

		}, err => {
			console.log(err);
		});
	}

	ngOnInit() {
	}

	getTotal() {
		let total_payments = 0;
		this.total = this.ticket.total;

		this.payments.forEach(element => {
			total_payments += element.amount;
		});

		this.discounts.forEach(element => {
			if (element.type == 'percentage') {
				let discount = Number(Number(this.total * (Number(element.amount) / 100)).toFixed(2));
				element.total_discount = discount;
				this.total = this.total - discount;
			} else if (element.type == 'fixed') {
				element.total_discount = element.amount;
				this.total = this.total - element.amount;
			}
		});

		this.pending_payment = this.total - total_payments;
	}

	dismiss() {
		this.modalController.dismiss();
	}

	goBack() {
		this.nav.pop();
	}
}
