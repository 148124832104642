// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {
  margin: 16px 0;
}

.user-data {
  padding-left: 6px;
  padding-right: 32px;
  position: sticky;
  top: 0;
  height: 56vh;
  border-right: 1px solid var(--ion-color-light);
}

.right-container {
  padding-left: 20px;
}

ion-radio-group ion-grid {
  margin: -10px;
}

@media (max-width: 767px) {
  .user-data,
  .right-container {
    border-right: none !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    position: relative !important;
    width: 100% !important;
    height: fit-content !important;
  }
  .user-data {
    border-bottom: 1px solid var(--ion-color-light) !important;
  }
  .right-container {
    padding-top: 32px;
  }
  form {
    margin-right: 0;
  }
  form .content {
    display: block !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/clients/edit-client/edit-client.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,MAAA;EACA,YAAA;EACA,8CAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAGE;EACE,aAAA;AAAJ;;AAIA;EACE;;IAEE,6BAAA;IACA,2BAAA;IACA,0BAAA;IACA,6BAAA;IACA,sBAAA;IACA,8BAAA;EADF;EAIA;IACE,0DAAA;EAFF;EAKA;IACE,iBAAA;EAHF;EAKA;IACE,eAAA;EAHF;EAIE;IACE,yBAAA;EAFJ;AACF","sourcesContent":["hr {\n  margin: 16px 0;\n}\n\n.user-data {\n  padding-left: 6px;\n  padding-right: 32px;\n  position: sticky;\n  top: 0;\n  height: 56vh;\n  border-right: 1px solid var(--ion-color-light);\n}\n\n.right-container {\n  padding-left: 20px;\n}\n\nion-radio-group {\n  ion-grid {\n    margin: -10px;\n  }\n}\n\n@media (max-width: 767px) {\n  .user-data,\n  .right-container {\n    border-right: none !important;\n    padding-right: 0 !important;\n    padding-left: 0 !important;\n    position: relative !important;\n    width: 100% !important;\n    height: fit-content !important;\n  }\n\n  .user-data {\n    border-bottom: 1px solid var(--ion-color-light) !important;\n  }\n\n  .right-container {\n    padding-top: 32px;\n  }\n  form {\n    margin-right: 0;\n    .content {\n      display: block !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
