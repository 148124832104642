import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateConfigService } from 'src/app/services/language/languages.service';


@Component({
    selector: 'app-select-popover',
    templateUrl: './select-popover.component.html',
    styleUrls: ['./select-popover.component.scss'],
})
export class SelectPopoverComponent implements OnInit {

    @ViewChild('yourElement') yourElement: ElementRef;
    elements: any = [];
    all_elements: any = [];

    constructor(
        public navParams: NavParams,
        public popoverController: PopoverController,
        public auth: AuthService,
        public langService: TranslateConfigService
    ) {
        this.all_elements = navParams.get('list');
        this.elements = this.all_elements.slice(0, 15);

        if (this.auth.branch_data.country !== 'El Salvador') {
            const found = this.elements.findIndex(_element => _element.value == 'DUI');
            if (found > -1)
                this.elements.splice(found, 1);
        }
        this.setObserverLoader();
    }

    ngOnInit() { }

    async getMore() {
        this.elements = this.elements.concat(this.all_elements.slice(this.elements.length, this.elements.length + 15));
    }

    searching(ev) {
        this.elements = [];
        const query = ev.detail.value.toLowerCase();
        if (query != '') {
            this.navParams.get('list').forEach(element => {
                const should_show = this.remove_accents(element.value).indexOf(query) > -1;
                if (should_show) {
                    this.elements.push(element);
                }
            });
        } else {
            this.elements = this.navParams.get('list');
        }
    }

    setObserverLoader() {
        setTimeout(() => {
            const threshold = 0.2; // how much % of the element is in view
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            this.getMore();
                        }
                    });
                },
                { threshold }
            );
            if (this.yourElement) observer.observe(this.yourElement.nativeElement);
        }, 1000);
    }

    remove_accents(value) {
        const accents = { '�': 'a', '�': 'e', '�': 'i', '�': 'o', '�': 'u', '�': 'A', '�': 'E', '�': 'I', '�': 'O', '�': 'U' };
        return value.split('').map(letter => accents[letter] || letter).join('').toString().toLowerCase();
    }

}
