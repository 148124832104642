// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover-content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  max-height: 90%;
  flex: 1;
}

.popover-list {
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #e0e0e0;
}

.popover-list::-webkit-scrollbar {
  width: 6px;
}

.popover-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.popover-list::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/select-popover/select-popover.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,YAAA;EACA,eAAA;EACA,OAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;EACA,qBAAA;EACA,6BAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,sBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".popover-content {\n    display: flex;\n    flex-direction: column;\n    box-sizing: border-box;\n    height: 100%;\n    max-height: 90%;\n    flex: 1;\n}\n  \n.popover-list {\n    max-height: 100%;\n    overflow-y: auto;\n    scrollbar-width: thin;\n    scrollbar-color: #888 #e0e0e0;\n}\n\n.popover-list::-webkit-scrollbar {\n    width: 6px;\n}\n\n.popover-list::-webkit-scrollbar-thumb {\n    background-color: #888;\n    border-radius: 3px;\n}\n\n.popover-list::-webkit-scrollbar-track {\n    background-color: #e0e0e0;\n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
