// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  font-size: 20px;
  padding-bottom: 16px;
  color: white;
}

h1 {
  font-size: 100px;
  font-weight: 900;
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.55);
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/countdown-timer/countdown-timer.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,oBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,kCAAA;UAAA,0BAAA;EACA,+BAAA;AACF","sourcesContent":["p {\n  font-size: 20px;\n  padding-bottom: 16px;\n  color: white;\n}\n\nh1 {\n  font-size: 100px;\n  font-weight: 900;\n  color: white;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  backdrop-filter: blur(5px);\n  background: rgb(0 0 0 / 55%);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
