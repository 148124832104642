import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSegment, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { AddClientComponent } from '../../clients/add-client/add-client.component';
import algoliasearch from 'algoliasearch';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
const client = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);

@Component({
	selector: 'app-clients',
	templateUrl: './clients.component.html',
	styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {

	@ViewChild('main_segment') main_segment: IonSegment;
	clients: any = [];
	index: any = client.initIndex(`clients`);
	loading: any = false;
	last_data: any;
	ticket: any;
	hits: any = [];
	is_ccf: any;

	constructor(
		private api: ApiService,
		public auth: AuthService,
		public modalController: ModalController,
		public navParams: NavParams,
		public components: ComponentsService
	) {
		this.ticket = this.navParams.get('ticket');
		this.is_ccf = this.navParams.get('is_ccf');
	}

	ngOnInit() {
		this.loadData();
	}

	async addCustomer(type) {
		if (!this.components.isModalPresent) {
			let new_class = '';
			if (type != 'regular') {
				new_class = 'two-columns';
			}
			this.components.isModalPresent = true;
			const modal = await this.modalController.create({
				component: AddClientComponent,
				cssClass: 'auto-height ' + new_class,
				animated: true,
				backdropDismiss: true,
				componentProps: {
					type: type
				}
			});

			await modal.present().then(data => {
				this.components.isModalPresent = false;
			})

			modal.onDidDismiss().then(data => {
				if (data.data) {
					this.selectClient(data.data);
				}
			})
		}
	}

	async selectClient(client) {
		this.modalController.dismiss(client);
	}

	segmentChanged() {
		this.clients = [];
		this.last_data = undefined;
		this.loadData();
	}

	loadData(event = null) {
		this.api.getRef(`accounts/${this.auth.account}/clients`).ref
			.where('type', '==', this.main_segment ? this.main_segment.value : 'regular')
			.orderBy('name')
			.startAfter(this.last_data || null) // Utiliza last_data como referencia
			.limit(20)
			.get()
			.then((snapshots:any) => {
				if (event) event.target.complete();

				if (snapshots.empty) {
					if (event) event.target.disabled = true;
					this.last_data = undefined;
					this.loading = false;
				} else {
					this.loadSnapshots(snapshots);
				}
			});
	}

	loadSnapshots(snapshots) {
		if (snapshots.size === 20) {
			this.last_data = snapshots.docs[snapshots.docs.length - 1];
		} else {
			this.last_data = undefined;
		}

		snapshots.forEach(element => {
			let client = element.data();
			client.date = moment(client.creation_date.toDate(), "YYYYMMDD").fromNow();
			client.$key = element.id;
			this.clients.push(client);
		});
		this.loading = false;
	}

	search(e) {
		let query = e.detail.value;

		if (query !== '') {
			this.index.search(query, {
				filters: `(account_key:${this.auth.account})`
			}).then(({ hits }) => {
				this.hits = hits;

				hits.forEach(element => {
					let item_search = element;
					item_search.$key = element.objectID;
				})

				this.loading = false;
			});
		} else {
			this.hits = [];
			this.clients = [];
			this.ngOnInit();
		}
	}

	searchElements(element, ev) {
		let hits = element;

		if (!Array.isArray(hits)) {
			hits = [];
			hits.push(element);
		}

		if (hits.length > 0) {
			this.clients = [];
			this.last_data = false;
			this.loading = true;

			if (ev) ev.target.blur();

			hits.forEach(hit => {
				this.api.getRef(`accounts/${this.auth.account}/clients`).ref
					.doc(hit.$key)
					.onSnapshot((snapshot:any) => {
						let client = snapshot.data();
						client.$key = snapshot.id;
						this.clients.push(client);
					})
			});

			this.loading = false;
		}
	}
}
