import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonNav, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { SeparateItemsComponent } from '../../payments/separate-items/separate-items.component';
import { TicketSelectionComponent } from '../../payments/ticket-selection/ticket-selection.component';
import { TransferTicketsComponent } from '../../tables/transfer-tickets/transfer-tickets.component';
import { AddDiscountComponent } from '../add-discount/add-discount.component';
import { AddNoteOrderComponent } from '../add-note-order/add-note-order.component';
import { CancelNotesComponent } from '../cancel-notes/cancel-notes.component';
import { ComponentsService } from 'src/app/services/components/components.service';
import { AddTipComponent } from '../add-tip/add-tip.component';
import { MaskitoOptions } from '@maskito/core';
import { DiscountCardDetailsComponent } from '../../discount-card/discount-card-details/discount-card-details.component';
import { SelectAmountBoardComponent } from '../../discount-card/select-amount-board/select-amount-board.component';


@Component({
    selector: 'app-ticket-options',
    templateUrl: './ticket-options.component.html',
    styleUrls: ['./ticket-options.component.scss'],
})
export class TicketOptionsComponent implements OnInit {

    order: any;
    payments: any;
    params: any;
    code: string = '';
    discount_card: any;
    currentTotal = 0;

    constructor(
        public modalController: ModalController,
        public navParams: NavParams,
        private nav: IonNav,
        public api: ApiService,
        public alertController: AlertController,
        public auth: AuthService,
        public pos: PosService,
        public router: Router,
        public components: ComponentsService
    ) {
        this.params = this.navParams.data;
        this.discount_card = this.params.order.discount_card;
        this.currentTotal = this.params.currentTotal;
        if (Object.keys(this.params).length > 0) {
            this.order = this.params.order;
            this.payments = this.params.payments;
        }
    }

    readonly discount_card_mask: MaskitoOptions = {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    };

    ngOnInit() {
    }

    /**
     * The function is called when the user clicks the "Add Discount" button. It pushes the
     * AddDiscountComponent onto the navigation stack, and passes the order object to the
     * AddDiscountComponent
     */
    async goToDiscountPage() {
        this.nav.push(AddDiscountComponent, { order: this.order });
    }

    async goToTipPage() {
        this.nav.push(AddTipComponent, { order: this.order });
    }

    /* Pushing the AddNoteOrderComponent onto the navigation stack, and passing the order object to the
    AddNoteOrderComponent */
    openAddNote() {
        this.nav.push(AddNoteOrderComponent, { order: this.order });
    };

    /**
     * It creates a modal that contains the TransferTicketsComponent, and passes the order to the
     * component
     */
    async goToChangeTablePage() {
        this.modalController.dismiss();
        const modal = await this.modalController.create({
            component: TransferTicketsComponent,
            cssClass: 'full-modal',
            canDismiss: true,
            showBackdrop: true,
            backdropDismiss: true,
            componentProps: {
                orders: [this.order]
            }
        });

        await modal.present();
    }

    /**
     * If the order has payments, show an alert, otherwise, navigate to the cancel notes page
     */
    async openCancelOrder() {
        if (this.payments.length > 0) {
            const alert = await this.alertController.create({
                header: 'Tienes pagos realizados',
                message: 'No puedes cancelar esta orden ya que tienes pagos realizados',
                buttons: [
                    {
                        text: 'Aceptar',
                        role: 'role'
                    }
                ]
            });

            await alert.present();
        } else {
            this.nav.push(CancelNotesComponent, {
                level: 1,
                order: this.order,
                type: 'order',
            });

        }
    }

    /**
     * It creates a modal that displays the ticket selection component, and when the modal is dismissed,
     * it sets the ticket_key to the data returned from the modal
     */
    async selectTicket() {
        const modal = await this.modalController.create({
            component: TicketSelectionComponent,
            cssClass: 'tickets-modal',
            canDismiss: true,
            showBackdrop: true,
            backdropDismiss: true,
            componentProps: {
                table: this.order.table
            }
        });
        await modal.present();

        modal.onDidDismiss().then(data => {
            if (data.data) {
                this.router.navigateByUrl(`/pos/${data.data}`).then(response => {
                    console.log('aqui ?');

                    this.pos.updateOrderTicket(data.data);
                    this.modalController.dismiss();
                })
            }
        });


    }

    async getPeopleFromOrder() {
        try {
            const { mens, womens, childrens, number_persons }: any = await this.api.getDocument(`accounts/${this.auth.account}/orders`, this.params.order.$key);
            return {
                mens,
                womens,
                childrens,
                number_persons
            };
        } catch (error) {
            console.error("Error fetching people from order:", error);
            throw error;
        }
    }

    async OpenSelectItems() {
        const peopleAvailable = await this.getPeopleFromOrder();
        let data = {
            order_key: this.params.order.$key,
            order: this.params.order,
            total: this.params.total,
            peopleAvailable
        }

        const modal = await this.modalController.create({
            component: SeparateItemsComponent,
            cssClass: 'full-modal',
            showBackdrop: false,
            // animated: false,
            backdropDismiss: true,
            componentProps: data
        });

        await modal.present().then(() => {
        });

        await modal.onDidDismiss().then(data => {
            if (data.data) {
                this.pos.updateOrderTicket(data.data);
                this.modalController.dismiss();
                // this.router.navigateByUrl(`/pos/${data.data}`).then(response => {
                // 	// this.pos.updateOrderTicket(data.data)
                // 	// this.modalController.dismiss();
                // })
            }
        })
    }


    async findDiscountCard() {
        try {
            const snapshots = await this.api
                .getRef(`accounts/${this.auth.account}/discount_card`)
                .ref.where('code', '==', this.code)
                .get();

            if (snapshots.empty) {
                this.components.showToast('El código ingresado no es válido.', 'error');
                return;
            }

            const element = snapshots.docs[0];
            const cardData = { ...element.data() as any, $key: element.id };
            const today = new Date();

            if (today > cardData.expiration_date.toDate()) {
                this.components.showToast('Tarjeta de descuento expirada.', 'error');
                return;
            }

            if (!cardData.is_active) {
                this.components.showToast('Tarjeta de descuento ya ha sido consumida.', 'error');
                return;
            }
            const discount_card = { $key: element.id, ...cardData };
            const discountKey = this.api.db.createId();
            discount_card.discountKey = discountKey;

            const discount: any = {
                $key: discountKey,
                iva_discount: 0,
                total_discount: 0,
                card_code: discount_card.code,
                type: discount_card.type,
                isDiscountCard: true,
                owner: discount_card.client.name
            };
          
            if (cardData.type === 'fixed') {
                const response = await this.checkDiscountCard(cardData);
                if (!response) return this.modalController.dismiss(false);
                const amount_selected = await this.selectAmount(cardData);
                if (!amount_selected) return this.modalController.dismiss(false);
                discount.amount = amount_selected;
            } 
            if (cardData.type === 'percentage') {
                discount.amount = discount_card.amount;
            }



            if (!this.order) {
                this.pos.discounts.push(discount);
                this.pos.AddItemEvent.emit();
                this.discount_card = discount_card;
            } else {
                const discounts = this.order.discounts;
                const { discountAdded, discountCard} = await this.api.addDiscountCarToOrder(
                    this.order.$key,
                    discount_card,
                    discount
                );
                this.pos.discounts = [];
                this.discount_card = discountCard;
                this.order.discount_card = this.discount_card;
                this.order.discounts = [...discounts, discountAdded];
            }

            this.modalController.dismiss(this.discount_card);
            this.components.showToast('La tarjeta de descuento fue añadida.');
        } catch (error) {
            console.error('Algo salió mal:', error);
        }
    }
    async removeDiscountCard() {
        const discountKey: any = this.discount_card.discountKey ?? null;

        const alert = await this.alertController.create({
            header: 'Remover tarjeta de descuento',
            message: 'Estas seguro que deseas remover tarjeta de descuento',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel'
                },
                {
                    text: 'Remover tarjeta',
                    role: 'destructive',
                    handler: () => {
                        if (!this.order) {
                            const index = this.pos.discounts.filter(discount => discount.$key === discountKey);
                            if (index !== -1) {
                                this.pos.discounts.splice(index, 1);
                                this.discount_card = null;
                                this.modalController.dismiss({ code: 'deleted' })
                            }
                        }
                        if (this.order) {
                            const discounts = this.order.discounts;
                            this.api.deleteDocument(`accounts/${this.auth.account}/ticket_discounts`, discountKey).then(async () => {
                                await this.api.removeDiscountCardFromOrder(this.order)
                                this.order.discounts = discounts.filter(({$key}) => discountKey !== $key);
                                this.discount_card = null;
                                this.modalController.dismiss({ code: 'deleted' })
                            }, err => {
                                console.log(err);
                            })
                        }
                    }
                }
            ]
        });

        await alert.present();
    }


    async checkDiscountCard(card){
        const modal = await this.modalController.create({
            component: DiscountCardDetailsComponent,
            cssClass: 'discount-card-details-modal',
            showBackdrop: true,
            componentProps: {
              card,
              total: this.currentTotal,
              cardHistoryAvailable: false
            },
        });

        
        await modal.present();
		const { data } = await modal.onDidDismiss();
        if(!data) return false;
		return data

    }

    async selectAmount(card){
        const modal = await this.modalController.create({
            component: SelectAmountBoardComponent,
            cssClass: 'pay-board-modal',
            showBackdrop: true,
            componentProps: {
              total: this.currentTotal,
              availableAmount: card.available_amount
            }
        });
    
    
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if(!data) return false;
	  return data.value
    
    }


}
