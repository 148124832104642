// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  height: 100%;
}
.options ion-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ebeff8;
  border-radius: 12px;
  text-align: center;
  margin: 0;
  padding: 16px;
  height: 100%;
  cursor: pointer;
}
.options ion-card ion-icon {
  font-size: 80px;
  margin-bottom: 24px;
}
.options ion-card p {
  font-size: 25px;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .options {
    grid-template-columns: repeat(2, 1fr) !important;
    height: calc(100% - 63.8px);
  }
  .options ion-icon {
    font-size: 50px !important;
  }
  .options p {
    font-size: 15px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/select-type/select-type.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,qCAAA;EACA,SAAA;EACA,YAAA;AACD;AACC;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AACF;AACE;EACC,eAAA;EACA,mBAAA;AACH;AAEE;EACC,eAAA;EACA,YAAA;EACA,gBAAA;EACA,yBAAA;AAAH;;AAMA;EACC;IACC,gDAAA;IACA,2BAAA;EAHA;EAKA;IACC,0BAAA;EAHD;EAMA;IACC,0BAAA;EAJD;AACF","sourcesContent":[".options {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(3, 1fr);\n\tgap: 16px;\n\theight: 100%;\n\n\tion-card {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tflex-direction: column;\n\t\tborder: 1px solid #ebeff8;\n\t\tborder-radius: 12px;\n\t\ttext-align: center;\n\t\tmargin: 0;\n\t\tpadding: 16px;\n\t\theight: 100%;\n\t\tcursor: pointer;\n\n\t\tion-icon {\n\t\t\tfont-size: 80px;\n\t\t\tmargin-bottom: 24px;\n\t\t}\n\n\t\tp {\n\t\t\tfont-size: 25px;\n\t\t\tcolor: black;\n\t\t\tfont-weight: 600;\n\t\t\ttext-transform: uppercase;\n\t\t}\n\t}\n}\n\n\n@media (max-width: 767px) {\n\t.options {\n\t\tgrid-template-columns: repeat(2, 1fr) !important;\n\t\theight: calc(100% - 63.8px);\n\n\t\tion-icon {\n\t\t\tfont-size: 50px !important;\n\t\t}\n\n\t\tp {\n\t\t\tfont-size: 15px !important;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
