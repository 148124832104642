// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-searchbar {
  height: 40px !important;
}

.search-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.05);
}`, "",{"version":3,"sources":["webpack://./src/app/components/pos/deliveries/deliveries.component.scss"],"names":[],"mappings":"AAAA;EACG,uBAAA;AACH;;AAEA;EACG,+CAAA;AACH","sourcesContent":["ion-searchbar {\n   height: 40px !important;\n}\n\n.search-list {\n   box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.05);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
