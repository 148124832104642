// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-segment-button {
  text-transform: none;
}
ion-segment-button ion-text {
  padding: 0;
  font-weight: 600;
}

.radio-group-container {
  display: flex;
}
.radio-group-container ion-item {
  width: calc(100% - 16px);
}`, "",{"version":3,"sources":["webpack://./src/app/components/pos/add-discount/add-discount.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AACE;EACE,UAAA;EACA,gBAAA;AACJ;;AAGA;EACE,aAAA;AAAF;AAEE;EACE,wBAAA;AAAJ","sourcesContent":["ion-segment-button {\n  text-transform: none;\n\n  ion-text {\n    padding: 0;\n    font-weight: 600;\n  }\n}\n\n.radio-group-container {\n  display: flex;\n\n  ion-item {\n    width: calc(100% - 16px);\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
