import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
	selector: 'app-ticket-selection',
	templateUrl: './ticket-selection.component.html',
	styleUrls: ['./ticket-selection.component.scss'],
})
export class TicketSelectionComponent implements OnInit {

	loading: any = true;
	orders: any = [];
	table: any;
	transfer: boolean = false;
	branch: any;

	constructor(
		public api: ApiService,
		public auth: AuthService,
		public modalController: ModalController,
		public navParams: NavParams,
		public components: ComponentsService,
		public router: Router,

	) { }

	ngOnInit() {
		this.api.getDocument(`accounts/${this.auth.account}/branches`, this.auth.branch).then(data => {
			this.branch = data;
			this.table = this.navParams.get('table');
			console.log(this.table);



			this.api.getRef(`accounts/${this.auth.account}/orders`).ref
				.where('branch_key', '==', this.auth.branch)
				.where('table', '==', this.table)
				.orderBy('order_number')
				.onSnapshot((snapshots:any) => {
					snapshots.docChanges().forEach(element => {
						if (element.type == 'added') {
							let order = element.doc.data();
							order.$key = element.doc.id;
							order.creation_date = order.creation_date.toDate();
							order.items = [];

							this.api.getRef(`accounts/${this.auth.account}/ticket_items`).ref
								.where('order_key', '==', order.$key)
								.limit(3)
								.get()
								.then((snapshots:any) => {
									snapshots.forEach(element => {
										let item = element.data();
										item.$key = element.id;
										order.items.push(item);
									});
								}, err => {
									console.log(err);
								});

							setInterval(() => {
								const today = Math.abs(new Date().getTime());
								const endDate = Math.abs(order.creation_date.getTime());

								const days = (endDate - today) / (1000 * 60 * 60 * 24);
								const hours = Math.abs(endDate - today) / (1000 * 60 * 60) % 24;
								const minutes = Math.abs(order.creation_date.getTime() - new Date().getTime()) / (1000 * 60) % 60;
								const seconds = Math.abs(order.creation_date.getTime() - new Date().getTime()) / (1000) % 60;

								order.time = `${Math.round(minutes)}:${Math.round(seconds)}`
								if (Math.round(hours) > 0) {
									order.time = `${Math.round(hours)}h ${order.time}`
								}
							}, 1000)

							this.orders.push(order);
						}
					})

					this.loading = false;
				}, err => {
					console.log(err);
				})


		})
	}

	dismiss() {
		this.modalController.dismiss();
	}

	setTable(order) {
		this.modalController.dismiss(order.$key);
	}

}
