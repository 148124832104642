import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
  selector: 'app-add-delivery',
  templateUrl: './add-delivery.component.html',
  styleUrls: ['./add-delivery.component.scss'],
})
export class AddDeliveryComponent implements OnInit {

  deliveryForm: FormGroup;

  constructor(
    public modalController: ModalController,
    public formBuilder: FormBuilder,
    public api: ApiService,
    public auth: AuthService,
    public components: ComponentsService
  ) {
    this.deliveryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      email: ['', [Validators.email, this.emailValidator()]],
      phone: ['', [Validators.required]],
      commission: ['', [Validators.required, this.commissionValidator()]],
      isInHouseDelivery:  [false],
    });
  }

  ngOnInit() { }

  addDelivery(ev) {
    this.components.buttonLoad(ev);

    let delivery = this.deliveryForm.value;
    this.api.getRef(`accounts/${this.auth.account}/deliveries`).ref
      .where('email', '==', delivery.email)
      .get()
      .then((snapshots) => {
        if (snapshots.empty) {
          delivery.creation_date = new Date();
          delivery.commission = Number(delivery.commission);
          this.api.addDocument(`accounts/${this.auth.account}/deliveries`, delivery).then((data) => {
            this.modalController.dismiss(delivery);
            this.components.dismissButtonLoad(ev);
            this.components.showToast('Delivery agregado correctamente.');
          });
        } else {
          this.components.dismissButtonLoad(ev);
          this.components.showToast('El delivery ya se encuentra registrado en el sistema.', 'error');
        }
      });
  }

  commissionValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = parseFloat(control.value);
      return !isNaN(value) && value >= 0 ? null : { 'commission': { value: control.value } };
    };
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov|mil|int|io|us|co|uk|ca|au|jp|de|fr|es|sv)$/;
      const valid = emailPattern.test(control.value);
      return valid ? null : { 'email': { value: control.value } };
    };
  }
}
