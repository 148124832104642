import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { TicketDetailsComponent } from '../../shared/ticket-details/ticket-details.component';
import { PaymentBoardComponent } from '../../payments/payment-board/payment-board.component';
import { SelectAmountBoardComponent } from '../select-amount-board/select-amount-board.component';

@Component({
  selector: 'app-discount-card-details',
  templateUrl: './discount-card-details.component.html',
  styleUrls: ['./discount-card-details.component.scss'],
})
export class DiscountCardDetailsComponent  implements OnInit {
  discount_card: any;
	payments: any = [];
  symbol: string;
  isApp: boolean;
  cardHistoryAvailable: boolean
  total: number = 0;
  constructor(
    private readonly navParams: NavParams,
    private readonly modalController: ModalController,
    private readonly api: ApiService,
    private readonly auth: AuthService,
    private readonly components: ComponentsService,

    
  ) {
    this.discount_card = this.navParams.get('card'); 
    this.symbol = this.auth.account_data.currency.symbol;
    this.isApp = this.components.isApp();
    this.cardHistoryAvailable = this.navParams.get('cardHistoryAvailable'); 
    this.total = this.navParams.get('total')
  }

  ngOnInit() {
    if(this.cardHistoryAvailable){
      this.api.getRef(`accounts/${this.auth.account}/ticket_payments`).ref
      .where('discount_card', '==', this.discount_card.$key)
      .get()
      .then((snapshots:any) => {
        snapshots.forEach((element:any) => {
          let payment = element.data();
          payment.$key = element.id;
      
          if (payment.ticket_key) {
            this.api.getRef(`accounts/${this.auth.account}/tickets`).ref
              .doc(payment.ticket_key)
              .get()
              .then((snapshot:any) => {
                let ticket = snapshot.data();
                ticket.creation_date = ticket.creation_date.toDate();
                ticket.completed_date = ticket.completed_date.toDate();
                ticket.$key = snapshot.id;
                payment.ticket = ticket;
              })
          } else {
            this.api.getRef(`accounts/${this.auth.account}/orders`).ref
              .doc(payment.order_key)
              .get()
              .then((snapshot:any) => {
                let ticket = snapshot.data();
                ticket.creation_date = ticket.creation_date.toDate();
                ticket.completed_date = ticket.completed_date.toDate();
                ticket.$key = snapshot.id;
                payment.ticket = ticket;
              })
          }
          this.payments.push(payment);
        })
      }, err => {
        console.log(err);
      })
    }
  }

  close(){
    this.modalController.dismiss();
  }

  async deleteDiscountCard() {
    try {
      this.components.showAlert('Eliminar tarjeta de descuento', `Esta acción no se puede revertir.`, [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Eliminar',
          role: 'destructive',
          handler: async () => {
            await this.components.showLoader('Borrando tarjeta...');
            await this.api.deleteDocument(`accounts/${this.auth.account}/discount_card`, this.discount_card.$key);
            await this.components.showToast('Tarjeta eliminada correctamente.');
            this.modalController.dismiss({ deleted: true });
          }
        }
      ]);
    } catch (error) {
      console.error('Error al eliminar tarjeta:', error);
    }
  }

  async openTicket(ticket) {
		if (!this.components.isModalPresent) {
			this.components.isModalPresent = true;
			const modal = await this.modalController.create({
				component: TicketDetailsComponent,
				cssClass: 'full-modal',
				componentProps: {
					ticket: ticket
				}
			});
			modal.onDidDismiss().then((data) => {
				if (data.data) { }
			});

			return await modal.present().then(() => { this.components.isModalPresent = false; })
		}
	}


  useDiscountCard(){
    this.modalController.dismiss(true)

  }
  

}
