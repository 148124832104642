import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { ClientsComponent } from '../../pos/clients/clients.component';
import { ApiService } from 'src/app/services/api/api.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { EditClientComponent } from '../../clients/edit-client/edit-client.component';
import { TranslateConfigService } from 'src/app/services/language/languages.service';

@Component({
    selector: 'app-voucher',
    templateUrl: './voucher.component.html',
    styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent implements OnInit {

    ccfForm: UntypedFormGroup;
    invoiceForm: UntypedFormGroup;
    voucher_type: any;
    type: any;
    order: any;

    constructor(
        public modalController: ModalController,
        public formBuilder: UntypedFormBuilder,
        public components: ComponentsService,
        public navParams: NavParams,
        public auth: AuthService,
        public api: ApiService,
        public pos: PosService,
        public langService: TranslateConfigService
    ) {
        this.type = navParams.get('type');
        console.log(this.type);


        this.ccfForm = this.formBuilder.group({
            number: ['', [Validators.required]],
            nit: ['', [Validators.required]],
            nrc: ['', [Validators.required]],
            exempt: [false],

        });

        this.invoiceForm = this.formBuilder.group({
            number: ['', [Validators.required]],
            name: ['', [Validators.required]],
            dui: ['', [Validators.required]],
            exempt: [false],
        });
    }

    ngOnInit() { }

    selectType(type) {
        if (type == 'ticket') {
            this.modalController.dismiss({
                type: 'ticket'
            });
        } else if (type == 'invoice') {
            if (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte') {
                this.modalController.dismiss({
                    type: 'invoice'
                });
            } else {
                this.voucher_type = type;
            }
        } else if (type == 'ccf') {

            if (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte') {
                if (!this.order.client || this.order.client.type == 'regular') {
                    this.components.showAlert('Orden sin cliente', 'Debes de agregar un cliente para poder seleccionar CCF', ['Aceptar'])
                } else {
                    this.modalController.dismiss({
                        type: 'ccf'
                    });
                }
            } else {
                this.voucher_type = type;
            }
        }
    }

    async addClient() {
        const modal = await this.modalController.create({
            component: ClientsComponent,
            showBackdrop: true,
            componentProps: {
                order: this.order,
                is_ccf: true
            },
        });

        await modal.present();
        await modal.onDidDismiss().then((data) => {
            if (data.data) {
                let client = data.data;
                let valid = true;

                console.log(client);

                if (client.type == 'regular' && (!client.name || !client.email || !this.pos.client.document_type)) {
                    valid = false;
                } else if ((client.type == 'legal' || client.type == 'natural') && (!client.name || !client.phone || !client.email || !client.nit || !client.nrc || !client.activity || !client.address || !client.depto || !client.munic)) {
                    valid = false;
                }

                if (valid) {
                    this.setClient(client);
                } else {
                    this.modalController.create({
                        component: EditClientComponent,
                        cssClass: 'auto-height',
                        animated: true,
                        backdropDismiss: true,
                        componentProps: {
                            client: client,
                            dte_valid: true
                        }
                    }).then(modal => {
                        modal.present();

                        modal.onDidDismiss().then(async data => {
                            this.setClient(data.data);
                        })
                    });
                }
            }
        });
    }

    setClient(client) {
        this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
            client: client
        }).then(() => {
            this.pos.client = client;
            this.order.client = client;
        }, err => {
            console.log(err);
        })

        this.modalController.dismiss({
            type: 'ccf'
        });

        this.components.showToast('Tipo de comprobante actualizado.');
    }

    setType(type) {
        if (type == 'ccf') {
            let ccf = this.ccfForm.value;

            this.modalController.dismiss({
                type: 'ccf',
                ccf: ccf
            });

            this.components.showToast('Tipo de comprobante actualizado.');
        } else if (type == 'invoice') {
            let invoice = this.invoiceForm.value;

            this.modalController.dismiss({
                type: 'invoice',
                invoice: invoice
            });

            this.components.showToast('Tipo de comprobante actualizado.');
        }
    }
}
