import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
    selector: 'app-credit',
    templateUrl: './credit.component.html',
    styleUrls: ['./credit.component.scss'],
})
export class CreditComponent implements OnInit {

    slideOptions = { slidesPerView: 'auto', zoom: false, autoplay: false, loop: false };
    loading: any = false;
    order: any;
    amount: any = '';
    total: any;
    cash_options: any = [];
    wrong: any = false;
    deposit: any = 0;
    deposit_tip: any = 0;
    splitted_amount: any;
    branch: any;
    step: any = 0;
    authorization: any = '';
    params: any;
    items: any;
    client: any;
    limit: any;

    constructor(
        public modalController: ModalController,
        public api: ApiService,
        public navParams: NavParams,
        public components: ComponentsService,
        public auth: AuthService,
        public pos: PosService,
        public alertController: AlertController
    ) {
        this.params = this.navParams.data;
        this.order = this.navParams.data.order;
        this.total = this.navParams.data.pending;
        this.branch = this.navParams.data.branch;
        this.client = this.navParams.data.client;

        if (this.order.type !== 'delivery') {
            this.limit = JSON.parse(JSON.stringify(this.client.limit_credit))
        }
        this.items = this.params.items
    }

    ngOnInit() {
        if (this.order.type !== 'delivery') {
            this.api.getRef(`accounts/${this.auth.account}/tickets`).ref
                .where('status', '==', 'credit')
                .where('client.$key', '==', this.client.$key)
                .get()
                .then((snapshots: any) => {
                    snapshots.forEach(element => {
                        this.client.limit_credit = Number(this.client.limit_credit) - Number(element.data().total);
                    });
                })
        }
    }

    completePayment() {
        this.components.showLoader().then(async () => {

            let valid = true;
            if (this.order.type !== 'delivery') {
                if (this.total > this.client.limit_credit) {
                    valid = false;
                }
            }

            if (valid) {

                let payment_data = {
                    order: this.order,
                    items: this.items,
                    subtotal: this.params.gran_subtotal,
                    gran_total: this.params.gran_total,
                    gran_tip: this.params.gran_tip,
                    gran_discount: this.params.gran_discount,
                    discounts: this.navParams.data.discounts,
                    hidden_taxes: this.components.fixed(this.params.gran_hidden_taxes),
                    exempt: this.params.gran_exempt
                }

                this.api.covertOrderToCreditTicket(payment_data).then(data => {
                    this.components.dismissLoader();
                    this.modalController.dismiss({ completed: true, ticket_key: data['ticket_key'] });
                }, err => {
                    console.log(err);
                });

            } else {
                const alert = await this.alertController.create({
                    header: 'Crédito no disponible',
                    message: 'El total es mayor al crédito disponible de este usuario.',
                    buttons: ['OK']
                });

                await alert.present();
                this.components.dismissLoader();
            }
        })

    }




}
