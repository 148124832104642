// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options {
  height: 100%;
  margin: 0;
}
.options ion-row {
  height: 100%;
}
.options ion-row ion-col {
  height: 50%;
  padding: 16px;
}
.options .option {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 1px solid #ebeff8;
  cursor: pointer;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.options .option ion-icon {
  font-size: 65px;
  margin-bottom: 24px;
  color: #404248;
}
.options .option ion-label {
  font-size: 18px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #404248;
  width: 100%;
}
.options .option--disabled {
  cursor: not-allowed;
}
.options .option--disabled ion-icon,
.options .option--disabled ion-label {
  color: #d5dae5;
}

@media (max-width: 767px) {
  ion-header {
    margin-top: 16px !important;
  }
  .options {
    padding-bottom: env(safe-area-inset-bottom) !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/giftcards/select-payment-method/select-payment-method.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,SAAA;AACF;AACE;EACE,YAAA;AACJ;AACI;EACE,WAAA;EACA,aAAA;AACN;AAGE;EACE,WAAA;EACA,YAAA;EACA,SAAA;EACA,yBAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADJ;AAGI;EACE,eAAA;EACA,mBAAA;EACA,cAAA;AADN;AAII;EACE,0BAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;AAFN;AAKI;EACE,mBAAA;AAHN;AAKM;;EAEE,cAAA;AAHR;;AASA;EACE;IACE,2BAAA;EANF;EASA;IACE,sDAAA;EAPF;AACF","sourcesContent":[".options {\n  height: 100%;\n  margin: 0;\n\n  ion-row {\n    height: 100%;\n\n    ion-col {\n      height: 50%;\n      padding: 16px;\n    }\n  }\n\n  .option {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    border: 1px solid #ebeff8;\n    cursor: pointer;\n    border-radius: 13px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    ion-icon {\n      font-size: 65px;\n      margin-bottom: 24px;\n      color: #404248;\n    }\n\n    ion-label {\n      font-size: 18px !important;\n      font-weight: 600;\n      text-transform: uppercase;\n      text-align: center;\n      color: #404248;\n      width: 100%;\n    }\n\n    &--disabled {\n      cursor: not-allowed;\n\n      ion-icon,\n      ion-label {\n        color: #d5dae5;\n      }\n    }\n  }\n}\n\n@media (max-width: 767px) {\n  ion-header {\n    margin-top: 16px !important;\n  }\n\n  .options {\n    padding-bottom: env(safe-area-inset-bottom) !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
