import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PrintService } from 'src/app/services/print/print.service';
import { AuthService } from './../../../services/auth/auth.service';

@Component({
	selector: 'app-return-item',
	templateUrl: './return-item.component.html',
	styleUrls: ['./return-item.component.scss'],
})
export class ReturnItemComponent implements OnInit {

	item: any;
	quantity: any = 1;
	note: any = '';
	message: any = '';
	return_stock: any = false;
	other: any = false;
	ticket: any;
	order: any;

	constructor(
		public navParams: NavParams,
		public components: ComponentsService,
		private api: ApiService,
		public modalController: ModalController,
		public alertController: AlertController,
		public auth: AuthService,
		public platform: Platform,
		public print: PrintService,

	) {
		this.item = JSON.parse(JSON.stringify(navParams.data.item));
		this.note = JSON.parse(JSON.stringify(navParams.data.reason));
		this.order = JSON.parse(JSON.stringify(navParams.data.order));
	}

	ngOnInit() { }

	changeQuantity(type) {
		if (type == 'add') {
			if (this.quantity + 1 > this.item.quantity) {
				this.components.showToast('Limite de items para anular');
			} else {
				this.quantity++;
			}

		}
		else if (type == 'minus') {
			if (this.quantity - 1 < 1) {
				this.components.showToast('Para anular debes seleccionar al menos uno');
			} else {
				this.quantity--;
			}

		}
	};

	returnItem() {
		this.components.showLoader('Realizando devolución...').then(() => {
			this.api.voidItem(this.item, this.quantity, this.return_stock, this.note, this.message, this.order).then(async (item) => {
				this.item = item;
				if (item) this.showModal()
				this.components.dismissLoader();
				this.modalController.dismiss({ item_refunded: true, refund: !!item });
			});
		});
	}


	async showModal() {
		const alert = await this.alertController.create({
			header: 'Anulación Exitosa.',
			message: '¿Deseas imprimir anulacion?.',
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel'
				},
				{
					text: 'Si, imprimir',
					id: 'confirm-button',
					handler: () => this.printReturn()
				}
			]
		});

		await alert.present();
	}

	printReturn() {
		this.item.type = this.order.type
		this.item.amount = this.item.price;
		if (this.order.type === 'tables') this.item.table = this.order.table;
		let total = this.item.price;
		let gran_discount = 0;
		let hidden_taxes = 0;
		let taxes = 0;
		let tip = 0;

		this.item.creation_date = this.item.creation_date.toDate();

		if (this.item.type == 'tables') {
			if (this.auth.branch_data.active_tip && !this.item.tip_removed) {
				if (this.auth.branch_data.include_taxes_tip) {
					tip = ((total * Number(this.auth.branch_data.tip)) / 100);
				} else {
					tip = (((total - (taxes + hidden_taxes)) * Number(this.auth.branch_data.tip)) / 100);
				}
				tip = Math.round(tip * 100) / 100
			} else {
				tip = 0;
			}
		}
		if (this.item.discounts) {

			this.item.discounts.forEach(element => {
				if (element.type == 'percentage') {

					let discount = Number(Number(total * (Number(element.amount) / 100)).toFixed(2));
					element.total_discount = discount;
					total = total - discount;
					gran_discount += discount;
				} else if (element.type == 'fixed') {
					element.total_discount = element.amount;
					total = total - element.amount;
					gran_discount += element.amount;
				}

				if (total < 0) {
					total = 0;
				}
			});
		}

		if (this.platform.is('cordova') || this.print.quanto_prints) {
			this.print.printOrder(this.item, this.item.amount, total, tip, 0, gran_discount, true);
		} else {
			this.print.pdfOrder(this.item, this.item.amount, total, tip, 0, gran_discount, true);
		}
	}

}
