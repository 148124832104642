// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 1px solid #ebeff8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  cursor: pointer;
  border-radius: 13px;
}
.option ion-icon {
  font-size: 65px;
  margin-bottom: 40px;
  color: #404248;
}
.option ion-label {
  font-size: 21px !important;
  font-weight: 600;
  text-align: center;
  width: 70%;
  color: black;
}
.option--disabled {
  cursor: not-allowed;
}
.option--disabled ion-icon,
.option--disabled ion-label {
  color: #d5dae5;
}

.active {
  border-color: var(--ion-color-primary);
}
.active ion-icon {
  color: var(--ion-color-primary);
}

@media (max-width: 767px) {
  ion-card {
    margin-bottom: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/payments/voucher/voucher.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,SAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;EACA,mBAAA;AACD;AACC;EACC,eAAA;EACA,mBAAA;EACA,cAAA;AACF;AAEC;EACC,0BAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;AAAF;AAGC;EACC,mBAAA;AADF;AAGE;;EAEC,cAAA;AADH;;AAMA;EACC,sCAAA;AAHD;AAKC;EACC,+BAAA;AAHF;;AAOA;EACC;IACC,mBAAA;EAJA;AACF","sourcesContent":[".option {\n\twidth: 100%;\n\theight: 100%;\n\tmargin: 0;\n\tborder: 1px solid #ebeff8;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\tpadding: 32px 0;\n\tcursor: pointer;\n\tborder-radius: 13px;\n\n\tion-icon {\n\t\tfont-size: 65px;\n\t\tmargin-bottom: 40px;\n\t\tcolor: #404248;\n\t}\n\n\tion-label {\n\t\tfont-size: 21px !important;\n\t\tfont-weight: 600;\n\t\ttext-align: center;\n\t\twidth: 70%;\n\t\tcolor: black;\n\t}\n\n\t&--disabled {\n\t\tcursor: not-allowed;\n\n\t\tion-icon,\n\t\tion-label {\n\t\t\tcolor: #d5dae5;\n\t\t}\n\t}\n}\n\n.active {\n\tborder-color: var(--ion-color-primary);\n\n\tion-icon {\n\t\tcolor: var(--ion-color-primary);\n\t}\n}\n\n@media (max-width: 767px) {\n\tion-card {\n\t\tmargin-bottom: 16px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
