// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-price {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0 !important;
}
.title-price h1 {
  font-size: 50px;
  font-weight: 600;
  position: relative;
  z-index: 1111;
  color: var(--ion-color-primary);
}
.title-price p {
  font-size: 16px;
  position: relative;
  z-index: 1111;
  margin-bottom: 10px;
}

ion-grid,
ion-col {
  padding: 0;
}

ion-row {
  margin-bottom: 32px;
}

hr {
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/payments/credit/credit.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,2BAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,+BAAA;AAEJ;AAAE;EACE,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AAEJ;;AAEA;;EAEE,UAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".title-price {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-bottom: 0 !important;\n  h1 {\n    font-size: 50px;\n    font-weight: 600;\n    position: relative;\n    z-index: 1111;\n    color: var(--ion-color-primary);\n  }\n  p {\n    font-size: 16px;\n    position: relative;\n    z-index: 1111;\n    margin-bottom: 10px;\n  }\n}\n\nion-grid,\nion-col {\n  padding: 0;\n}\n\nion-row {\n  margin-bottom: 32px;\n}\n\nhr {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
