// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  margin-bottom: 0;
}

.btn-next {
  margin: 16px;
  height: 60px !important;
  font-size: 17px !important;
}

.button-disabled {
  --background: var(--ion-color-light) !important;
  --background-hover: var(--ion-color-light) !important;
  --background-activated: var(--ion-color-light) !important;
  color: var(--ion-color-light) !important;
  opacity: 1;
}

.numbers {
  border-bottom: 1px solid var(--ion-color-light-shade);
}`, "",{"version":3,"sources":["webpack://./src/app/components/payments/split-amount/split-amount.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,uBAAA;EACA,0BAAA;AACF;;AAEA;EACE,+CAAA;EACA,qDAAA;EACA,yDAAA;EACA,wCAAA;EACA,UAAA;AACF;;AAEA;EACE,qDAAA;AACF","sourcesContent":[".title {\n  margin-bottom: 0;\n}\n\n.btn-next {\n  margin: 16px;\n  height: 60px !important;\n  font-size: 17px !important;\n}\n\n.button-disabled {\n  --background: var(--ion-color-light) !important;\n  --background-hover: var(--ion-color-light) !important;\n  --background-activated: var(--ion-color-light) !important;\n  color: var(--ion-color-light) !important;\n  opacity: 1;\n}\n\n.numbers {\n  border-bottom: 1px solid var(--ion-color-light-shade);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
