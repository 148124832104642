// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.container .header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  background: var(--ion-color-light);
  padding: 16px;
}
.container .header .item-info {
  display: flex;
  align-items: center;
  gap: 16px;
}
.container .header .item-info ion-thumbnail {
  --size: 70px;
  --border-radius: 8px;
  border: 5px solid #fff;
}
.container .header .item-info h6 {
  font-weight: 700 !important;
  margin-bottom: 5px;
}
.container .header .item-info p {
  color: var(--ion-color-primary);
  font-size: 17px;
  z-index: 11;
}
.container .header .item-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  color: #a6a9ab;
}
.container .header .item-info ul li {
  font-size: 12px !important;
  margin-bottom: 3px;
}
.container .header .item-info ul li p,
.container .header .item-info ul li span,
.container .header .item-info ul li div {
  font-size: 17px;
}
.container .header ion-input {
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pos/refund-item/refund-item.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;EACA,kCAAA;EACA,aAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAGN;AAFM;EACE,YAAA;EACA,oBAAA;EACA,sBAAA;AAIR;AAFM;EACE,2BAAA;EACA,kBAAA;AAIR;AAFM;EACE,+BAAA;EACA,eAAA;EACA,WAAA;AAIR;AAFM;EACE,gBAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;EACA,cAAA;AAIR;AAHQ;EACE,0BAAA;EACA,kBAAA;AAKV;AAJU;;;EAGE,eAAA;AAMZ;AADI;EACE,2BAAA;EACA,8BAAA;AAGN","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  .header {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    gap: 10px;\n    height: 100%;\n    background: var(--ion-color-light);\n    padding: 16px;\n    .item-info {\n      display: flex;\n      align-items: center;\n      gap: 16px;\n      ion-thumbnail {\n        --size: 70px;\n        --border-radius: 8px;\n        border: 5px solid #fff;\n      }\n      h6 {\n        font-weight: 700 !important;\n        margin-bottom: 5px;\n      }\n      p {\n        color: var(--ion-color-primary);\n        font-size: 17px;\n        z-index: 11;\n      }\n      ul {\n        list-style: none;\n        padding: 0;\n        margin: 0;\n        margin-top: 5px;\n        color: #a6a9ab;\n        li {\n          font-size: 12px !important;\n          margin-bottom: 3px;\n          p,\n          span,\n          div {\n            font-size: 17px;\n          }\n        }\n      }\n    }\n    ion-input {\n      --padding-top: 0 !important;\n      --padding-bottom: 0 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
