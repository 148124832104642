import { AmountBoardComponent } from './../amount-board/amount-board.component';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ComponentsService } from 'src/app/services/components/components.service';
import { ModalBaseComponent } from '../../shared/modal-base/modal-base.component';
import { MoreMethodsComponent } from '../../payments/more-methods/more-methods.component';

@Component({
	selector: 'app-select-payment-method',
	templateUrl: './select-payment-method.component.html',
	styleUrls: ['./select-payment-method.component.scss'],
})
export class SelectPaymentMethodComponent implements OnInit {

	constructor(
		public modalController: ModalController,
		public navParams: NavParams,
		public components: ComponentsService
	) {

	}

	ngOnInit() {
	}

	async openAmountBoard(payment_type) {
		const modal = await this.modalController.create({
			component: AmountBoardComponent,
			cssClass: 'pay-board-modal',
			componentProps: {
				type: payment_type,
				client: this.navParams.get('client')
			}
		});


		modal.onDidDismiss().then((data) => {
			if (data.data) {
				this.modalController.dismiss();
				this.showGiftcard();
			}
		});

		await modal.present();
	}

	showGiftcard() {
		console.log('aqui aparece la giftcard');
	}

	async openMoreMethods() {
		const modal = await this.modalController.create({
			component: ModalBaseComponent,
			cssClass: 'edit-item-modal',
			showBackdrop: true,
			backdropDismiss: true,
			componentProps: {
				rootPage: MoreMethodsComponent,
				params: {
				}
			}
		});

		modal.present();

		await modal.onDidDismiss().then((data) => {
			if (data.data) {
				console.log(data.data);
				
				this.openAmountBoard(data.data.method)
			}
		});
	}
}
