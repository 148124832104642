import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
    selector: 'app-add-tip',
    templateUrl: './add-tip.component.html',
    styleUrls: ['./add-tip.component.scss'],
})
export class AddTipComponent implements OnInit {

    tipForm: UntypedFormGroup;
    type: any = 'custom';
    order: any;
    item: any;
    manager: any = false;
    tips: any = [];
    selected_tip: any = false;
    loading: any = true;
    apply_to: any = 'all';

    constructor(
        public popoverController: PopoverController,
        public modalController: ModalController,
        public formBuilder: UntypedFormBuilder,
        public navParams: NavParams,
        public auth: AuthService,
        public components: ComponentsService,
        public api: ApiService,
        public pos: PosService
    ) {
        this.order = this.navParams.get('order');
        this.item = this.navParams.get('item');

        this.tipForm = this.formBuilder.group({
            type: [this.type, [Validators.required]],
            amount: ['', [Validators.required]],
            note: ['', [Validators.required]]
        });

        this.api.getRef(`accounts/${this.auth.account}/tips`).ref
            .where('branches', 'array-contains', this.auth.branch)
            .where('available', '==', true)
            .get()
            .then(sanpshots => {
                sanpshots.forEach((element: any) => {
                    let tip = element.data();
                    tip.$key = element.id;
                    this.tips.push(tip);
                });
                this.tips.sort(function (a, b) {
                    var orderA = a.amount, orderB = b.amount;
                    return orderA - orderB;
                });
                this.loading = false;
            })
    }

    ngOnInit() {
    }

    changeType(ev) {
        this.type = ev.detail.value;
    }

    selectTip(ev) {
        this.selected_tip = ev.detail.value;
    }

    changeTip(type) {
        this.components.showLoader().then(() => {
            let tip;

            if (type == 'custom') {
                tip = this.tipForm.value;
                tip.amount = Number(tip.amount);
            } else {
                tip = this.selected_tip;
            }


            if (tip.amount > 0 && tip) {
                this.pos.changeTip(tip);
                this.components.dismissLoader();
                this.modalController.dismiss();
            }
        })
    }

}
