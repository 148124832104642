import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
    selector: 'app-number',
    templateUrl: './number.component.html',
    styleUrls: ['./number.component.scss'],
})
export class NumberComponent implements OnInit {

    table: any;
    mens: any = 0;
    childrens: any = 0;
    womens: any = 0;
    is_update: any;
    order: any;
    peopleAtTable: any;
    peopleConfirmation: boolean;

    constructor(
        public router: Router,
        public auth: AuthService,
        public components: ComponentsService,
        public navParams: NavParams,
        public modalController: ModalController,
        public api: ApiService,
        public pos: PosService
    ) {
        this.table = navParams.get('table');
        this.is_update = navParams.get('is_update');
        this.order = navParams.get('order');
        this.peopleConfirmation = navParams.get('peopleConfirmation');
        if (this.order) {
            this.peopleAtTable = {
                womens: this.order.womens,
                childrens: this.order.childrens,
                mens: this.order.mens
            }
        }

        if (this.is_update) {
            this.womens = this.pos.womens;
            this.mens = this.pos.mens;
            this.childrens = this.pos.childrens;

            console.log(this.womens);
            console.log(this.pos.womens);


        }
        if (this.peopleConfirmation) {
            this.womens = this.pos.womens;
            this.mens = this.pos.mens;
            this.childrens = this.pos.childrens;
        }
    }

    ngOnInit() { }

    goToTable() {

        let number = this.womens + this.childrens + this.mens;

        if (this.order &&
            this.mens === this.peopleAtTable.mens &&
            this.womens === this.peopleAtTable.womens &&
            this.childrens === this.peopleAtTable.childrens
        ) {
            this.modalController.dismiss({ mens: this.mens, womens: this.womens, childrens: this.childrens });
            return
        }
        if (this.is_update) {
            if (this.order) {

                this.components.showLoader().then(() => {
                    this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
                        mens: this.mens,
                        womens: this.womens,
                        childrens: this.childrens,
                        number_persons: Number(this.mens + this.womens + this.childrens)
                    }).then(data => {
                        this.pos.womens = this.womens;
                        this.pos.mens = this.mens;
                        this.pos.childrens = this.childrens;
                        this.components.dismissLoader();
                        this.modalController.dismiss({ mens: this.mens, womens: this.womens, childrens: this.childrens });
                    })
                })
            } else {
                this.pos.womens = this.womens;
                this.pos.mens = this.mens;
                this.pos.childrens = this.childrens;
                this.modalController.dismiss();
            }
        } else {
            if (number > 0) {
                this.pos.type = 'tables';
                this.pos.womens = this.womens;
                this.pos.mens = this.mens;
                this.pos.childrens = this.childrens;
                this.pos.table = this.table.text;
                this.modalController.dismiss();
                this.router.navigate(['/pos']);
            } else {
                this.components.showToast('Debes de agrega al menos una persona en la mesa', 'error');
            }
        }
    }

    addQuantity(type) {
        if (type == 'mens') {
            this.mens++;
        } else if (type == 'womens') {
            this.womens++;
        } else if (type == 'childrens') {
            this.childrens++;
        }
    }

    minusQuantity(type) {
        if (type == 'mens') {
            this.mens--;
        } else if (type == 'womens') {
            this.womens--;
        } else if (type == 'childrens') {
            this.childrens--;
        }
    }

}
