import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PrintService } from '../../../services/print/print.service';

@Component({
	selector: 'app-return-order',
	templateUrl: './return-order.component.html',
	styleUrls: ['./return-order.component.scss'],
})
export class ReturnOrderComponent implements OnInit {

	order: any;
	note: any = '';
	message: any = '';
	return_stock: any = false;

	constructor(
		public navParams: NavParams,
		public components: ComponentsService,
		private api: ApiService,
		public modalController: ModalController,
		public alertController: AlertController,
		public auth: AuthService,
		public platform: Platform,
		public print: PrintService,

	) {
		this.order = JSON.parse(JSON.stringify(navParams.data.order));
		this.note = JSON.parse(JSON.stringify(navParams.data.reason));
	}

	ngOnInit() {

	}

	async voidOrder() {
		const alert = await this.alertController.create({
			header: 'Anular orden',
			message: '�Seguro que deseas anular esta orden?. Esta acci�n no se puede revertir.',
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel'
				},
				{
					text: 'Anular orden',
					role: 'destructive',
					id: 'confirm-button',
					handler: () => {

						this.components.showLoader('Anulando orden...').then(async () => {
							this.order.note = this.note;
							this.order.message = this.message;
							this.order.return_user = this.auth.account_data.user;
							await this.api.voidOrder(this.order, this.return_stock).then(async (items) => {
								this.order.items = items;
								if (items) this.showModal(items)
								this.components.dismissLoader();
								this.modalController.dismiss('returned');
							})
						});
					}
				}
			]
		});

		await alert.present();
	}

	async showModal(items) {
		const alert = await this.alertController.create({
			header: 'Anulaci�n Exitosa',
			message: '�Deseas imprimir anulacion?',
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel'
				},
				{
					text: 'Si, imprimir',
					id: 'confirm-button',
					handler: () => this.printReturn(items)
				}
			]
		});

		await alert.present();
	}

	printReturn(items) {

		let total = 0;
		let total_payments = 0;
		let gran_discount = 0;
		let hidden_taxes = 0;
		let taxes = 0;
		let tip = 0;
		let amount = 0
		this.order.items.forEach(element => {
			amount += element.price * element.quantity;
		});

		this.order.creation_date = this.order.creation_date.toDate();

		let client: any = false;

		if (this.order.items) {
			this.order.items.forEach(element => {

				element.price = Number(element.price);

				if (element.is_promotion) {
					let promo = element;
					promo.quantity = 1;
					promo.total_taxes = 0;
					promo.hidden_taxes = 0;

					element.x_items.forEach(element_x => {

						if (element_x.taxes_item) {
							element_x.total_taxes = 0;
							element_x.hidden_taxes = 0;
							element_x.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100)
									element_x.total_taxes += (element_x.price) * (Number(tax.rate) / 100);
									promo.total_taxes += (element_x.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element_x.price * element_x.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element_x.price * element_x.quantity) - _tax;
									_tax = Number(_tax);
									hidden_taxes += _tax;
									promo.hidden_taxes += _tax;
								}

							});
						} else {
							promo.total_taxes = 0;
							promo.hidden_taxes = 0;
						}
					});

					element.y_items.forEach(element_y => {
						if (element_y.taxes_item) {
							element_y.total_taxes = 0;
							element_y.taxes_item.forEach(tax => {

								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100)
									element_y.total_taxes += (element_y.price) * (Number(tax.rate) / 100);
									promo.total_taxes += (element_y.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element_y.price * element_y.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element_y.price * element_y.quantity) - _tax;
									_tax = Number(_tax);
									hidden_taxes += _tax;
									promo.hidden_taxes += _tax;
								}

							});
						} else {
							promo.total_taxes = 0;
							promo.hidden_taxes = 0;
						}
					});

				} else {
					if (element.is_variant) {
						if (element.parent.taxes_item) {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
							element.parent.taxes_item.forEach(tax => {

								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element.price * element.quantity) * (Number(tax.rate) / 100)
									element.total_taxes += (element.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element.price * element.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element.price * element.quantity) - _tax;
									_tax = Number(_tax);
									element.hidden_taxes += _tax;
									hidden_taxes += _tax;
								}

							});

						} else {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
						}
					} else {
						if (element.taxes_item) {
							element.total_taxes = 0;
							element.hidden_taxes = 0;

							element.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element.price * element.quantity) * (Number(tax.rate) / 100)
									element.total_taxes += (element.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element.price * element.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element.price * element.quantity) - _tax;
									_tax = Number(_tax);
									element.hidden_taxes += _tax;
									hidden_taxes += _tax;
								}

							});

						} else {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
						}
					}
				}

				total += element.price * element.quantity;
			});
		} else {

		}


		if (this.order.client) {
			client = this.order.client;
		}

		if (this.order.type == 'tables') {
			if (this.auth.branch_data.active_tip && !this.order.tip_removed) {
				if (this.auth.branch_data.include_taxes_tip) {
					tip = ((total * Number(this.auth.branch_data.tip)) / 100);
				} else {
					tip = (((total - (taxes + hidden_taxes)) * Number(this.auth.branch_data.tip)) / 100);
				}
				tip = Math.round(tip * 100) / 100
			} else {
				tip = 0;
			}
		} else {
			tip = 0;
		}

		if (this.order.discounts) {

			this.order.discounts.forEach(element => {
				if (element.type == 'percentage') {

					let discount = Number(Number(total * (Number(element.amount) / 100)).toFixed(2));
					element.total_discount = discount;
					total = total - discount;
					gran_discount += discount;
				} else if (element.type == 'fixed') {
					element.total_discount = element.amount;
					total = total - element.amount;
					gran_discount += element.amount;
				}

				if (total < 0) {
					total = 0;
				}
			});
		}
		if (this.platform.is('cordova') || this.print.quanto_prints) {
			this.print.printOrder(this.order, amount, total, tip, gran_discount, true);
		} else {
			this.print.pdfOrder(this.order, amount, total, tip, gran_discount, true);
		}
	}


}
