import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { DteService } from 'src/app/services/dte/dte.service';
import { ClientsComponent } from '../clients/clients.component';
import { tipo_documento, departamentos, municipios, actividad_economica } from "../../../../assets/dte";
import { SelectPopoverComponent } from '../../shared/select-popover/select-popover.component';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { EditClientComponent } from '../../clients/edit-client/edit-client.component';
@Component({
    selector: 'app-refund-ticket',
    templateUrl: './refund-ticket.component.html',
    styleUrls: ['./refund-ticket.component.scss'],
})
export class RefundTicketComponent implements OnInit {

    ticket: any;
    note: any = '';
    return_stock: any = false;
    message: any = '';
    document_type: any = '';
    documents_types: any = tipo_documento;
    document: any = '';
    document_type_value: any = '';
    client: any;

    readonly nit_legal_mask: MaskitoOptions = {
        mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/],
    };

    readonly nit_natural_mask: MaskitoOptions = {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
    };

    readonly nrc_mask: MaskitoOptions = {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
    };

    readonly document_mask: MaskitoOptions = {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
    };

    readonly maskPredicate: MaskitoElementPredicate = async (el) => (el as HTMLIonInputElement).getInputElement();

    constructor(
        public navParams: NavParams,
        public components: ComponentsService,
        private api: ApiService,
        public modalController: ModalController,
        public dte: DteService,
        public auth: AuthService,
        public popoverController: PopoverController,
        public nav: IonNav

    ) {
        this.ticket = JSON.parse(JSON.stringify(navParams.data.ticket));
        this.note = JSON.parse(JSON.stringify(navParams.data.reason));
        if (this.ticket.client && (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte')) this.client = this.ticket.client;
    }

    ngOnInit() {
        if (this.auth.account_data.fiscal) {
            if (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte') {
                if (!this.ticket.client) {
                    this.addClient();
                } else {
                    if (this.ticket.client.document == '') {
                        this.addClient();
                    }
                }
            }

        }
        if (this.auth.account_data?.fiscal?.type !== 'dte') {
            this.document_type_value = true;
            this.document = true;
        }
    }

    async addClient() {
        if (!this.components.isModalPresent) {
            this.components.isModalPresent = true;

            let modal = await this.modalController.create({
                component: ClientsComponent,
                cssClass: 'clients-modal',
                showBackdrop: true,
            });
            await modal.present().then(data => { this.components.isModalPresent = false });

            await modal.onDidDismiss().then(async (data) => {
                if (data.data) {
                    let client = data.data;
                    if (!client.document) {
                        this.editClient(client);
                    }
                    this.client = client;
                } else {
                    if (!this.client || !this.client.document) {
                        this.components.showToast('Para poder realizar la devoluci�n debes agregar el documento del cliente', 'error');
                        this.nav.popToRoot();
                    }
                }
            });
        }
    }


    editClient(client, ev = null) {
        if (ev) this.components.preventTouch(ev);

        this.modalController.create({
            component: EditClientComponent,
            cssClass: 'auto-height',
            animated: true,
            backdropDismiss: true,
            componentProps: {
                client: client,
                isDocumentRequired: true,
            }
        }).then(modal => {
            modal.present();
            modal.onDidDismiss().then((data) => {
                if (data.data) {
                    this.client = data.data;
                }
                if (!data.data) this.addClient()
            });
        });
    }


    refundTicket() {
        this.components.showLoader('Realizando devoluci�n...').then(async () => {
            if (this.ticket.fiscal_type == 'dte') {

                const doc = {
                    document: this.document.replace(/-/g, ""),
                    document_type: this.document_type
                }

                if (this.client) {
                    this.ticket.client = this.client;

                    if (this.client.legal_person == undefined || !this.client.legal_person) {
                        this.ticket.client.document = this.ticket.client.document.replace(/-/g, "");
                    }
                }

                try {
                    const jsonResponse = await this.dte.invalidationJson(this.ticket, this.note, doc);
                    const invalidationData = await this.dte.signDte(jsonResponse, 'invalidation');
                    invalidationData['json'] = jsonResponse;
                    await this.api.refundTicket(this.ticket, this.note, this.message, this.return_stock, invalidationData);
                    this.components.dismissLoader();
                    this.modalController.dismiss({ ticket_refunded: true });  
                } catch({error, msg}) {
                    this.components.dismissLoader();
                    console.log({ error, msg});
                    this.components.showAlert('Hubo un problema al procesar la devoluci�n', `code: ${error.status} ${msg || 'Desconocido'}`, ['Aceptar'])
                }

            } else {
                this.api.refundTicket(this.ticket, this.note, this.message, this.return_stock).then(data => {
                    this.components.dismissLoader();
                    this.modalController.dismiss({ ticket_refunded: true });
                });
            }
        });
    }

    async selectDocType(ev) {
        let docs_types = JSON.parse(JSON.stringify(this.documents_types));

        const popover = await this.popoverController.create({
            component: SelectPopoverComponent,
            event: ev,
            size: 'cover',
            showBackdrop: false,
            componentProps: {
                list: docs_types
            }
        });

        popover.onDidDismiss().then((data) => {
            let element = data.data;
            if (element && (element.value != this.document_type_value)) {
                this.document_type = element;
                this.document_type_value = element.value;
            }
        });

        await popover.present();
    }

    getMask() {
        let document_type = this.document_type.value;

        if (document_type == 'DUI') {
            return this.document_mask;
        } else if (document_type == 'NIT') {
            return this.nit_legal_mask;
        } else if (document_type == 'NRC') {
            return this.nrc_mask;
        } else {
            return null;
        }
    }

}
