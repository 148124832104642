export const departamentos = [
    {
        "code": "00",
        "value": "Otro (Para extranjeros)"
    },
    {
        "code": "01",
        "value": "Ahuachap�n"
    },
    {
        "code": "02",
        "value": "Santa Ana"
    },
    {
        "code": "03",
        "value": "Sonsonate"
    },
    {
        "code": "04",
        "value": "Chalatenango"
    },
    {
        "code": "05",
        "value": "La Libertad"
    },
    {
        "code": "06",
        "value": "San Salvador"
    },
    {
        "code": "07",
        "value": "Cuscatl�n"
    },
    {
        "code": "08",
        "value": "La Paz"
    },
    {
        "code": "09",
        "value": "Caba�as"
    },
    {
        "code": "10",
        "value": "San Vicente"
    },
    {
        "code": "11",
        "value": "Usulut�n"
    },
    {
        "code": "12",
        "value": "San Miguel"
    },
    {
        "code": "13",
        "value": "Moraz�n"
    },
    {
        "code": "14",
        "value": "La Uni�n"
    }
]

export const municipios = [
    {
        "code": "00",
        "value": "Otro (Para extranjeros)",
        "depto": "00"
    },
    {
        "code": "13",
        "value": "AHUACHAPAN NORTE",
        "depto": "01"
    },
    {
        "code": "14",
        "value": "AHUACHAPAN CENTRO",
        "depto": "01"
    },
    {
        "code": "15",
        "value": "AHUACHAPAN SUR",
        "depto": "01"
    },
    {
        "code": "14",
        "value": "SANTA ANA NORTE",
        "depto": "02"
    },
    {
        "code": "15",
        "value": "SANTA ANA CENTRO",
        "depto": "02"
    },
    {
        "code": "16",
        "value": "SANTA ANA ESTE",
        "depto": "02"
    },
    {
        "code": "17",
        "value": "SANTA ANA OESTE",
        "depto": "02"
    },
    {
        "code": "17",
        "value": "SONSONATE NORTE",
        "depto": "03"
    },
    {
        "code": "18",
        "value": "SONSONATE CENTRO",
        "depto": "03"
    },
    {
        "code": "19",
        "value": "SONSONATE ESTE",
        "depto": "03"
    },
    {
        "code": "20",
        "value": "SONSONATE OESTE",
        "depto": "03"
    },
    {
        "code": "34",
        "value": "CHALATENANGO NORTE",
        "depto": "04"
    },
    {
        "code": "35",
        "value": "CHALATENANGO CENTRO",
        "depto": "04"
    },
    {
        "code": "36",
        "value": "CHALATENANGO SUR",
        "depto": "04"
    },
    {
        "code": "23",
        "value": "LA LIBERTAD NORTE",
        "depto": "05"
    },
    {
        "code": "24",
        "value": "LA LIBERTAD CENTRO",
        "depto": "05"
    },
    {
        "code": "25",
        "value": "LA LIBERTAD OESTE",
        "depto": "05"
    },
    {
        "code": "26",
        "value": "LA LIBERTAD ESTE",
        "depto": "05"
    },
    {
        "code": "27",
        "value": "LA LIBERTAD COSTA",
        "depto": "05"
    },
    {
        "code": "28",
        "value": "LA LIBERTAD SUR",
        "depto": "05"
    },
    {
        "code": "20",
        "value": "SAN SALVADOR NORTE",
        "depto": "06"
    },
    {
        "code": "21",
        "value": "SAN SALVADOR OESTE",
        "depto": "06"
    },
    {
        "code": "22",
        "value": "SAN SALVADOR ESTE",
        "depto": "06"
    },
    {
        "code": "23",
        "value": "SAN SALVADOR CENTRO",
        "depto": "06"
    },
    {
        "code": "24",
        "value": "SAN SALVADOR SUR",
        "depto": "06"
    },
    {
        "code": "17",
        "value": "CUSCATLAN NORTE",
        "depto": "07"
    },
    {
        "code": "18",
        "value": "CUSCATLAN SUR",
        "depto": "07"
    },
    {
        "code": "23",
        "value": "LA PAZ OESTE",
        "depto": "08"
    },
    {
        "code": "24",
        "value": "LA PAZ CENTRO",
        "depto": "08"
    },
    {
        "code": "25",
        "value": "LA PAZ ESTE",
        "depto": "08"
    },
    {
        "code": "10",
        "value": "CABA�AS OESTE",
        "depto": "09"
    },
    {
        "code": "11",
        "value": "CABA�AS ESTE",
        "depto": "09"
    },
    {
        "code": "14",
        "value": "SAN VICENTE NORTE",
        "depto": "10"
    },
    {
        "code": "15",
        "value": "SAN VICENTE SUR",
        "depto": "10"
    },
    {
        "code": "24",
        "value": "USULUTAN NORTE",
        "depto": "11"
    },
    {
        "code": "25",
        "value": "USULUTAN ESTE",
        "depto": "11"
    },
    {
        "code": "26",
        "value": "USULUTAN OESTE",
        "depto": "11"
    },
    {
        "code": "21",
        "value": "SAN MIGUEL NORTE",
        "depto": "12"
    },
    {
        "code": "22",
        "value": "SAN MIGUEL CENTRO",
        "depto": "12"
    },
    {
        "code": "23",
        "value": "SAN MIGUEL OESTE",
        "depto": "12"
    },
    {
        "code": "27",
        "value": "MORAZAN NORTE",
        "depto": "13"
    },
    {
        "code": "28",
        "value": "MORAZAN SUR",
        "depto": "13"
    },
    {
        "code": "19",
        "value": "LA UNION NORTE",
        "depto": "14"
    },
    {
        "code": "20",
        "value": "LA UNION SUR",
        "depto": "14"
    }
]

export const actividad_economica = [
    {
        "code": "01111",
        "value": "Cultivo de cereales excepto arroz y para forrajes"
    },
    {
        "code": "01112",
        "value": "Cultivo de legumbres"
    },
    {
        "code": "01113",
        "value": "Cultivo de semillas oleaginosas"
    },
    {
        "code": "01114",
        "value": "Cultivo de plantas para la preparaci�n de semillas"
    },
    {
        "code": "01119",
        "value": "Cultivo de otros cereales excepto arroz y forrajeros n.c.p."
    },
    {
        "code": "01120",
        "value": "Cultivo de arroz"
    },
    {
        "code": "01131",
        "value": "Cultivo de ra�ces y tub�rculos"
    },
    {
        "code": "01132",
        "value": "Cultivo de brotes, bulbos, vegetales tub�rculos y cultivos similares"
    },
    {
        "code": "01133",
        "value": "Cultivo hort�cola de fruto"
    },
    {
        "code": "01134",
        "value": "Cultivo de hortalizas de hoja y otras hortalizas ncp"
    },
    {
        "code": "01140",
        "value": "Cultivo de ca�a de az�car"
    },
    {
        "code": "01150",
        "value": "Cultivo de tabaco"
    },
    {
        "code": "01161",
        "value": "Cultivo de algod�n"
    },
    {
        "code": "01162",
        "value": "Cultivo de fibras vegetales excepto algod�n"
    },
    {
        "code": "01191",
        "value": "Cultivo de plantas no perennes para la producci�n de semillas y\nflores"
    },
    {
        "code": "01192",
        "value": "Cultivo de cereales y pastos para la alimentaci�n animal"
    },
    {
        "code": "01199",
        "value": "Producci�n de cultivos no estacionales ncp"
    },
    {
        "code": "01220",
        "value": "Cultivo de frutas tropicales"
    },
    {
        "code": "01230",
        "value": "Cultivo de c�tricos"
    },
    {
        "code": "01240",
        "value": "Cultivo de frutas de pepita y hueso"
    },
    {
        "code": "01251",
        "value": "Cultivo de frutas ncp"
    },
    {
        "code": "01252",
        "value": "Cultivo de otros frutos y nueces de �rboles y arbustos"
    },
    {
        "code": "01260",
        "value": "Cultivo de frutos oleaginosos"
    },
    {
        "code": "01271",
        "value": "Cultivo de caf�"
    },
    {
        "code": "01272",
        "value": "Cultivo de plantas para la elaboraci�n de bebidas excepto caf�"
    },
    {
        "code": "01281",
        "value": "Cultivo de especias y arom�ticas"
    },
    {
        "code": "01282",
        "value": "Cultivo de plantas para la obtenci�n de productos medicinales y farmac�uticos"
    },
    {
        "code": "01291",
        "value": "Cultivo de �rboles de hule (caucho) para la obtenci�n de l�tex"
    },
    {
        "code": "01292",
        "value": "Cultivo de plantas para la obtenci�n de productos qu�micos y colorantes"
    },
    {
        "code": "01299",
        "value": "Producci�n de cultivos perennes ncp"
    },
    {
        "code": "01300",
        "value": "Propagaci�n de plantas"
    },
    {
        "code": "01301",
        "value": "Cultivo de plantas y flores ornamentales"
    },
    {
        "code": "01410",
        "value": "Cr�a y engorde de ganado bovino"
    },
    {
        "code": "01420",
        "value": "Cr�a de caballos y otros equinos"
    },
    {
        "code": "01440",
        "value": "Cr�a de ovejas y cabras"
    },
    {
        "code": "01450",
        "value": "Cr�a de cerdos"
    },
    {
        "code": "01460",
        "value": "Cr�a de aves de corral y producci�n de huevos"
    },
    {
        "code": "01491",
        "value": "Cr�a de abejas apicultura para la obtenci�n de miel y otros productos ap�colas"
    },
    {
        "code": "01492",
        "value": "Cr�a de conejos"
    },
    {
        "code": "01493",
        "value": "Cr�a de iguanas y garrobos"
    },
    {
        "code": "01494",
        "value": "Cr�a de mariposas y otros insectos"
    },
    {
        "code": "01499",
        "value": "Cr�a y obtenci�n de productos animales n.c.p."
    },
    {
        "code": "01500",
        "value": "Cultivo de productos agr�colas en combinaci�n con la cr�a de animales"
    },
    {
        "code": "01611",
        "value": "Servicios de maquinaria agr�cola"
    },
    {
        "code": "01612",
        "value": "Control de plagas"
    },
    {
        "code": "01613",
        "value": "Servicios de riego"
    },
    {
        "code": "01614",
        "value": "Servicios de contrataci�n de mano de obra para la agricultura"
    },
    {
        "code": "01619",
        "value": "Servicios agr�colas ncp"
    },
    {
        "code": "01621",
        "value": "Actividades para mejorar la reproducci�n, el crecimiento y el rendimiento de los animales y sus productos"
    },
    {
        "code": "01622",
        "value": "Servicios de mano de obra pecuaria"
    },
    {
        "code": "01629",
        "value": "Servicios pecuarios ncp"
    },
    {
        "code": "01631",
        "value": "Labores post cosecha de preparaci�n de los productos agr�colas para su comercializaci�n o para la industria"
    },
    {
        "code": "01632",
        "value": "Servicio de beneficio de caf�"
    },
    {
        "code": "01633",
        "value": "Servicio de beneficiado de plantas textiles (incluye el beneficiado cuando este es realizado en la misma explotaci�n agropecuaria)"
    },
    {
        "code": "01640",
        "value": "Tratamiento de semillas para la propagaci�n"
    },
    {
        "code": "01700",
        "value": "Caza ordinaria y mediante trampas, repoblaci�n de animales de caza y servicios conexos"
    },
    {
        "code": "02100",
        "value": "Silvicultura y otras actividades forestales"
    },
    {
        "code": "02200",
        "value": "Extracci�n de madera"
    },
    {
        "code": "02300",
        "value": "Recolecci�n de productos diferentes a la madera"
    },
    {
        "code": "02400",
        "value": "Servicios de apoyo a la silvicultura"
    },
    {
        "code": "03110",
        "value": "Pesca mar�tima de altura y costera"
    },
    {
        "code": "03120",
        "value": "Pesca de agua dulce"
    },
    {
        "code": "03210",
        "value": "Acuicultura mar�tima"
    },
    {
        "code": "03220",
        "value": "Acuicultura de agua dulce"
    },
    {
        "code": "03300",
        "value": "Servicios de apoyo a la pesca y acuicultura"
    },
    {
        "code": "05100",
        "value": "Extracci�n de hulla"
    },
    {
        "code": "05200",
        "value": "Extracci�n y aglomeraci�n de lignito"
    },
    {
        "code": "06100",
        "value": "Extracci�n de petr�leo crudo"
    },
    {
        "code": "06200",
        "value": "Extracci�n de gas natural"
    },
    {
        "code": "07100",
        "value": "Extracci�n de minerales de hierro"
    },
    {
        "code": "07210",
        "value": "Extracci�n de minerales de uranio y torio"
    },
    {
        "code": "07290",
        "value": "Extracci�n de minerales metal�feros no ferrosos"
    },
    {
        "code": "08100",
        "value": "Extracci�n de piedra, arena y arcilla"
    },
    {
        "code": "08910",
        "value": "Extracci�n de minerales para la fabricaci�n de abonos y productos qu�micos"
    },
    {
        "code": "08920",
        "value": "Extracci�n y aglomeraci�n de turba"
    },
    {
        "code": "08930",
        "value": "Extracci�n de sal"
    },
    {
        "code": "08990",
        "value": "Explotaci�n de otras minas y canteras ncp"
    },
    {
        "code": "09100",
        "value": "Actividades de apoyo a la extracci�n de petr�leo y gas natural"
    },
    {
        "code": "09900",
        "value": "Actividades de apoyo a la explotaci�n de minas y canteras"
    },
    {
        "code": "10101",
        "value": "Servicio de rastros y mataderos de bovinos y porcinos"
    },
    {
        "code": "10102",
        "value": "Matanza y procesamiento de bovinos y porcinos"
    },
    {
        "code": "10103",
        "value": "Matanza y procesamientos de aves de corral"
    },
    {
        "code": "10104",
        "value": "Elaboraci�n y conservaci�n de embutidos y tripas naturales"
    },
    {
        "code": "10105",
        "value": "Servicios de conservaci�n y empaque de carnes"
    },
    {
        "code": "10106",
        "value": "Elaboraci�n y conservaci�n de grasas y aceites animales"
    },
    {
        "code": "10107",
        "value": "Servicios de molienda de carne"
    },
    {
        "code": "10108",
        "value": "Elaboraci�n de productos de carne ncp"
    },
    {
        "code": "10201",
        "value": "Procesamiento y conservaci�n de pescado, crust�ceos y moluscos"
    },
    {
        "code": "10209",
        "value": "Fabricaci�n de productos de pescado ncp"
    },
    {
        "code": "10301",
        "value": "Elaboraci�n de jugos de frutas y hortalizasv"
    },
    {
        "code": "10302",
        "value": "Elaboraci�n y envase de jaleas, mermeladas y frutas deshidratadas"
    },
    {
        "code": "10309",
        "value": "Elaboraci�n de productos de frutas y hortalizas n.c.p."
    },
    {
        "code": "10401",
        "value": "Fabricaci�n de aceites y grasas vegetales y animales comestibles"
    },
    {
        "code": "10402",
        "value": "Fabricaci�n de aceites y grasas vegetales y animales no comestibles"
    },
    {
        "code": "10409",
        "value": "Servicio de maquilado de aceites"
    },
    {
        "code": "10501",
        "value": "Fabricaci�n de productos l�cteos excepto sorbetes y quesos\nsustitutos"
    },
    {
        "code": "10502",
        "value": "Fabricaci�n de sorbetes y helados"
    },
    {
        "code": "10503",
        "value": "Fabricaci�n de quesos"
    },
    {
        "code": "10611",
        "value": "Molienda de cereales"
    },
    {
        "code": "10612",
        "value": "Elaboraci�n de cereales para el desayuno y similares"
    },
    {
        "code": "10613",
        "value": "Servicios de beneficiado de productos agr�colas ncp (excluye\nBeneficio de az�car rama 1072 y beneficio de caf� rama 0163)"
    },
    {
        "code": "10621",
        "value": "Fabricaci�n de almid�n"
    },
    {
        "code": "10628",
        "value": "Servicio de molienda de ma�z h�medo molino para nixtamal"
    },
    {
        "code": "10711",
        "value": "Elaboraci�n de tortillas"
    },
    {
        "code": "10712",
        "value": "Fabricaci�n de pan, galletas y barquillos"
    },
    {
        "code": "10713",
        "value": "Fabricaci�n de reposter�a"
    },
    {
        "code": "10721",
        "value": "Ingenios azucareros"
    },
    {
        "code": "10722",
        "value": "Molienda de ca�a de az�car para la elaboraci�n de dulces"
    },
    {
        "code": "10723",
        "value": "Elaboraci�n de jarabes de az�car y otros similares"
    },
    {
        "code": "10724",
        "value": "Maquilado de az�car de ca�a"
    },
    {
        "code": "10730",
        "value": "Fabricaci�n de cacao, chocolates y productos de confiter�a"
    },
    {
        "code": "10740",
        "value": "Elaboraci�n de macarrones, fideos, y productos farin�ceos similares"
    },
    {
        "code": "10750",
        "value": "Elaboraci�n de comidas y platos preparados para la reventa en\nlocales y/o para exportaci�n"
    },
    {
        "code": "10791",
        "value": "Elaboraci�n de productos de caf�"
    },
    {
        "code": "10792",
        "value": "Elaboraci�n de especies, sazonadores y condimentos"
    },
    {
        "code": "10793",
        "value": "Elaboraci�n de sopas, cremas y consom�"
    },
    {
        "code": "10794",
        "value": "Fabricaci�n de bocadillos tostados y/o fritos"
    },
    {
        "code": "10799",
        "value": "Elaboraci�n de productos alimenticios ncp"
    },
    {
        "code": "10800",
        "value": "Elaboraci�n de alimentos preparados para animales"
    },
    {
        "code": "11012",
        "value": "Fabricaci�n de aguardiente y licores"
    },
    {
        "code": "11020",
        "value": "Elaboraci�n de vinos"
    },
    {
        "code": "11030",
        "value": "Fabricaci�n de cerveza"
    },
    {
        "code": "11041",
        "value": "Fabricaci�n de aguas gaseosas"
    },
    {
        "code": "11042",
        "value": "Fabricaci�n y envasado de agua"
    },
    {
        "code": "11043",
        "value": "Elaboraci�n de refrescos"
    },
    {
        "code": "11048",
        "value": "Maquilado de aguas gaseosas"
    },
    {
        "code": "11049",
        "value": "Elaboraci�n de bebidas no alcoh�licas"
    },
    {
        "code": "12000",
        "value": "Elaboraci�n de productos de tabaco"
    },
    {
        "code": "13111",
        "value": "Preparaci�n de fibras textiles"
    },
    {
        "code": "13112",
        "value": "Fabricaci�n de hilados"
    },
    {
        "code": "13120",
        "value": "Fabricaci�n de telas"
    },
    {
        "code": "13130",
        "value": "Acabado de productos textiles"
    },
    {
        "code": "13910",
        "value": "Fabricaci�n de tejidos de punto y ganchillo"
    },
    {
        "code": "13921",
        "value": "Fabricaci�n de productos textiles para el hogar"
    },
    {
        "code": "13922",
        "value": "Sacos, bolsas y otros art�culos textiles"
    },
    {
        "code": "13929",
        "value": "Fabricaci�n de art�culos confeccionados con materiales textiles,\nexcepto prendas de vestir ncp"
    },
    {
        "code": "13930",
        "value": "Fabricaci�n de tapices y alfombras"
    },
    {
        "code": "13941",
        "value": "Fabricaci�n de cuerdas de henequ�n y otras fibras naturales (lazos,\npitas)"
    },
    {
        "code": "13942",
        "value": "Fabricaci�n de redes de diversos materiales"
    },
    {
        "code": "13948",
        "value": "Maquilado de productos trenzables de cualquier material (petates,\nsillas, etc.)"
    },
    {
        "code": "13991",
        "value": "Fabricaci�n de adornos, etiquetas y otros art�culos para prendas de\nvestir"
    },
    {
        "code": "13992",
        "value": "Servicio de bordados en art�culos y prendas de tela"
    },
    {
        "code": "13999",
        "value": "Fabricaci�n de productos textiles ncp"
    },
    {
        "code": "14101",
        "value": "Fabricaci�n de ropa interior, para dormir y similares"
    },
    {
        "code": "14102",
        "value": "Fabricaci�n de ropa para ni�os"
    },
    {
        "code": "14103",
        "value": "Fabricaci�n de prendas de vestir para ambos sexos"
    },
    {
        "code": "14104",
        "value": "Confecci�n de prendas a medida"
    },
    {
        "code": "14105",
        "value": "Fabricaci�n de prendas de vestir para deportes"
    },
    {
        "code": "14106",
        "value": "Elaboraci�n de artesan�as de uso personal confeccionadas\nespecialmente de materiales textiles"
    },
    {
        "code": "14108",
        "value": "Maquilado de prendas de vestir, accesorios y otros"
    },
    {
        "code": "14109",
        "value": "Fabricaci�n de prendas y accesorios de vestir n.c.p."
    },
    {
        "code": "14200",
        "value": "Fabricaci�n de art�culos de piel"
    },
    {
        "code": "14301",
        "value": "Fabricaci�n de calcetines, calcetas, medias (panty house) y otros\nsimilares"
    },
    {
        "code": "14302",
        "value": "Fabricaci�n de ropa interior de tejido de punto"
    },
    {
        "code": "14309",
        "value": "Fabricaci�n de prendas de vestir de tejido de punto ncp"
    },
    {
        "code": "15110",
        "value": "Curtido y adobo de cueros; adobo y te�ido de pieles"
    },
    {
        "code": "15121",
        "value": "Fabricaci�n de maletas, bolsos de mano y otros art�culos de\nmarroquiner�a"
    },
    {
        "code": "15122",
        "value": "Fabricaci�n de monturas, accesorios y vainas talabarter�a"
    },
    {
        "code": "15123",
        "value": "Fabricaci�n de artesan�as principalmente de cuero natural y\nsint�tico"
    },
    {
        "code": "15128",
        "value": "Maquilado de art�culos de cuero natural, sint�tico y de otros\nmateriales"
    },
    {
        "code": "15201",
        "value": "Fabricaci�n de calzado"
    },
    {
        "code": "15202",
        "value": "Fabricaci�n de partes y accesorios de calzado"
    },
    {
        "code": "15208",
        "value": "Maquilado de partes y accesorios de calzado"
    },
    {
        "code": "16100",
        "value": "Aserradero y acepilladura de madera"
    },
    {
        "code": "16210",
        "value": "Fabricaci�n de madera laminada, terciada, enchapada y\ncontrachapada, paneles para la construcci�n"
    },
    {
        "code": "16220",
        "value": "Fabricaci�n de partes y piezas de carpinter�a para edificios y\nconstrucciones"
    },
    {
        "code": "16230",
        "value": "Fabricaci�n de envases y recipientes de madera"
    },
    {
        "code": "16292",
        "value": "Fabricaci�n de artesan�as de madera, semillas, materiales trenzables"
    },
    {
        "code": "16299",
        "value": "Fabricaci�n de productos de madera, corcho, paja y materiales\ntrenzables ncp"
    },
    {
        "code": "17010",
        "value": "Fabricaci�n de pasta de madera, papel y cart�n"
    },
    {
        "code": "17020",
        "value": "Fabricaci�n de papel y cart�n ondulado y envases de papel y cart�n"
    },
    {
        "code": "17091",
        "value": "Fabricaci�n de art�culos de papel y cart�n de uso personal y\ndom�stico"
    },
    {
        "code": "17092",
        "value": "Fabricaci�n de productos de papel ncp"
    },
    {
        "code": "18110",
        "value": "Impresi�n"
    },
    {
        "code": "18120",
        "value": "Servicios relacionados con la impresi�n"
    },
    {
        "code": "18200",
        "value": "Reproducci�n de grabaciones"
    },
    {
        "code": "19100",
        "value": "Fabricaci�n de productos de hornos de coque"
    },
    {
        "code": "19201",
        "value": "Fabricaci�n de combustible"
    },
    {
        "code": "19202",
        "value": "Fabricaci�n de aceites y lubricantes"
    },
    {
        "code": "20111",
        "value": "Fabricaci�n de materias primas para la fabricaci�n de colorantes"
    },
    {
        "code": "20112",
        "value": "Fabricaci�n de materiales curtientes"
    },
    {
        "code": "20113",
        "value": "Fabricaci�n de gases industriales"
    },
    {
        "code": "20114",
        "value": "Fabricaci�n de alcohol et�lico"
    },
    {
        "code": "20119",
        "value": "Fabricaci�n de sustancias qu�micas b�sicas"
    },
    {
        "code": "20120",
        "value": "Fabricaci�n de abonos y fertilizantes"
    },
    {
        "code": "20130",
        "value": "Fabricaci�n de pl�stico y caucho en formas primarias"
    },
    {
        "code": "20210",
        "value": "Fabricaci�n de plaguicidas y otros productos qu�micos de uso\nagropecuario"
    },
    {
        "code": "20220",
        "value": "Fabricaci�n de pinturas, barnices y productos de revestimiento\nsimilares; tintas de imprenta y masillas"
    },
    {
        "code": "20231",
        "value": "Fabricaci�n de jabones, detergentes y similares para limpieza"
    },
    {
        "code": "20232",
        "value": "Fabricaci�n de perfumes, cosm�ticos y productos de higiene y\ncuidado personal, incluyendo tintes, champ�, etc."
    },
    {
        "code": "20291",
        "value": "Fabricaci�n de tintas y colores para escribir y pintar; fabricaci�n de\ncintas para impresoras"
    },
    {
        "code": "20292",
        "value": "Fabricaci�n de productos pirot�cnicos, explosivos y municiones"
    },
    {
        "code": "20299",
        "value": "Fabricaci�n de productos qu�micos n.c.p."
    },
    {
        "code": "20300",
        "value": "Fabricaci�n de fibras artificiales"
    },
    {
        "code": "21001",
        "value": "Manufactura de productos farmac�uticos, sustancias qu�micas y\nproductos bot�nicos"
    },
    {
        "code": "21008",
        "value": "Maquilado de medicamentos"
    },
    {
        "code": "22110",
        "value": "Fabricaci�n de cubiertas y c�maras; renovaci�n y recauchutado de\ncubiertas"
    },
    {
        "code": "22190",
        "value": "Fabricaci�n de otros productos de caucho"
    },
    {
        "code": "22201",
        "value": "Fabricaci�n de envases pl�sticos"
    },
    {
        "code": "22202",
        "value": "Fabricaci�n de productos pl�sticos para uso personal o dom�stico"
    },
    {
        "code": "22208",
        "value": "Maquila de pl�sticos"
    },
    {
        "code": "22209",
        "value": "Fabricaci�n de productos pl�sticos n.c.p."
    },
    {
        "code": "23101",
        "value": "Fabricaci�n de vidrio"
    },
    {
        "code": "23102",
        "value": "Fabricaci�n de recipientes y envases de vidrio"
    },
    {
        "code": "23108",
        "value": "Servicio de maquilado"
    },
    {
        "code": "23109",
        "value": "Fabricaci�n de productos de vidrio ncp"
    },
    {
        "code": "23910",
        "value": "Fabricaci�n de productos refractarios"
    },
    {
        "code": "23920",
        "value": "Fabricaci�n de productos de arcilla para la construcci�n"
    },
    {
        "code": "23931",
        "value": "Fabricaci�n de productos de cer�mica y porcelana no refractaria"
    },
    {
        "code": "23932",
        "value": "Fabricaci�n de productos de cer�mica y porcelana ncp"
    },
    {
        "code": "23940",
        "value": "Fabricaci�n de cemento, cal y yeso"
    },
    {
        "code": "23950",
        "value": "Fabricaci�n de art�culos de hormig�n, cemento y yeso"
    },
    {
        "code": "23960",
        "value": "Corte, tallado y acabado de la piedra"
    },
    {
        "code": "23990",
        "value": "Fabricaci�n de productos minerales no met�licos ncp"
    },
    {
        "code": "24100",
        "value": "Industrias b�sicas de hierro y acero"
    },
    {
        "code": "24200",
        "value": "Fabricaci�n de productos primarios de metales preciosos y metales\nno ferrosos"
    },
    {
        "code": "24310",
        "value": "Fundici�n de hierro y acero"
    },
    {
        "code": "24320",
        "value": "Fundici�n de metales no ferrosos"
    },
    {
        "code": "25111",
        "value": "Fabricaci�n de productos met�licos para uso estructural"
    },
    {
        "code": "25118",
        "value": "Servicio de maquila para la fabricaci�n de estructuras met�licas"
    },
    {
        "code": "25120",
        "value": "Fabricaci�n de tanques, dep�sitos y recipientes de metal"
    },
    {
        "code": "25130",
        "value": "Fabricaci�n de generadores de vapor, excepto calderas de agua\ncaliente para calefacci�n central"
    },
    {
        "code": "25200",
        "value": "Fabricaci�n de armas y municiones"
    },
    {
        "code": "25910",
        "value": "Forjado, prensado, estampado y laminado de metales;\npulvimetalurgia"
    },
    {
        "code": "25920",
        "value": "Tratamiento y revestimiento de metales"
    },
    {
        "code": "25930",
        "value": "Fabricaci�n de art�culos de cuchiller�a, herramientas de mano y\nart�culos de ferreter�a"
    },
    {
        "code": "25991",
        "value": "Fabricaci�n de envases y art�culos conexos de metal"
    },
    {
        "code": "25992",
        "value": "Fabricaci�n de art�culos met�licos de uso personal y/o dom�stico"
    },
    {
        "code": "25999",
        "value": "Fabricaci�n de productos elaborados de metal ncp"
    },
    {
        "code": "26100",
        "value": "Fabricaci�n de componentes electr�nicos"
    },
    {
        "code": "26200",
        "value": "Fabricaci�n de computadoras y equipo conexo"
    },
    {
        "code": "26300",
        "value": "Fabricaci�n de equipo de comunicaciones"
    },
    {
        "code": "26400",
        "value": "Fabricaci�n de aparatos electr�nicos de consumo para audio, video\nradio y televisi�n"
    },
    {
        "code": "26510",
        "value": "Fabricaci�n de instrumentos y aparatos para medir, verificar,\nensayar, navegar y de control de procesos industriales"
    },
    {
        "code": "26520",
        "value": "Fabricaci�n de relojes y piezas de relojes"
    },
    {
        "code": "26600",
        "value": "Fabricaci�n de equipo m�dico de irradiaci�n y equipo electr�nico\nde uso m�dico y terap�utico"
    },
    {
        "code": "26700",
        "value": "Fabricaci�n de instrumentos de �ptica y equipo fotogr�fico"
    },
    {
        "code": "26800",
        "value": "Fabricaci�n de medios magn�ticos y �pticos"
    },
    {
        "code": "27100",
        "value": "Fabricaci�n de motores, generadores, transformadores el�ctricos,\naparatos de distribuci�n y control de electricidad"
    },
    {
        "code": "27200",
        "value": "Fabricaci�n de pilas, bater�as y acumuladores"
    },
    {
        "code": "27310",
        "value": "Fabricaci�n de cables de fibra �ptica"
    },
    {
        "code": "27320",
        "value": "Fabricaci�n de otros hilos y cables el�ctricos"
    },
    {
        "code": "27330",
        "value": "Fabricaci�n de dispositivos de cableados"
    },
    {
        "code": "27400",
        "value": "Fabricaci�n de equipo el�ctrico de iluminaci�n"
    },
    {
        "code": "27500",
        "value": "Fabricaci�n de aparatos de uso dom�stico"
    },
    {
        "code": "27900",
        "value": "Fabricaci�n de otros tipos de equipo el�ctrico"
    },
    {
        "code": "28110",
        "value": "Fabricaci�n de motores y turbinas, excepto motores para\naeronaves, veh�culos automotores y motocicletas"
    },
    {
        "code": "28120",
        "value": "Fabricaci�n de equipo hidr�ulico"
    },
    {
        "code": "28130",
        "value": "Fabricaci�n de otras bombas, compresores, grifos y v�lvulas"
    },
    {
        "code": "28140",
        "value": "Fabricaci�n de cojinetes, engranajes, trenes de engranajes y piezas\nde transmisi�n"
    },
    {
        "code": "28150",
        "value": "Fabricaci�n de hornos y quemadores"
    },
    {
        "code": "28160",
        "value": "Fabricaci�n de equipo de elevaci�n y manipulaci�n"
    },
    {
        "code": "28170",
        "value": "Fabricaci�n de maquinaria y equipo de oficina"
    },
    {
        "code": "28180",
        "value": "Fabricaci�n de herramientas manuales"
    },
    {
        "code": "28190",
        "value": "Fabricaci�n de otros tipos de maquinaria de uso general"
    },
    {
        "code": "28210",
        "value": "Fabricaci�n de maquinaria agropecuaria y forestal"
    },
    {
        "code": "28220",
        "value": "Fabricaci�n de m�quinas para conformar metales y maquinaria\nherramienta"
    },
    {
        "code": "28230",
        "value": "Fabricaci�n de maquinaria metal�rgica"
    },
    {
        "code": "28240",
        "value": "Fabricaci�n de maquinaria para la explotaci�n de minas y canteras\ny para obras de construcci�n"
    },
    {
        "code": "28250",
        "value": "Fabricaci�n de maquinaria para la elaboraci�n de alimentos,\nbebidas y tabaco"
    },
    {
        "code": "28260",
        "value": "Fabricaci�n de maquinaria para la elaboraci�n de productos\ntextiles, prendas de vestir y cueros"
    },
    {
        "code": "28291",
        "value": "Fabricaci�n de m�quinas para imprenta"
    },
    {
        "code": "28299",
        "value": "Fabricaci�n de maquinaria de uso especial ncp"
    },
    {
        "code": "29100",
        "value": "Fabricaci�n veh�culos automotores"
    },
    {
        "code": "29200",
        "value": "Fabricaci�n de carrocer�as para veh�culos automotores; fabricaci�n\nde remolques y semiremolques"
    },
    {
        "code": "29300",
        "value": "Fabricaci�n de partes, piezas y accesorios para veh�culos\nautomotores"
    },
    {
        "code": "30110",
        "value": "Fabricaci�n de buques"
    },
    {
        "code": "30120",
        "value": "Construcci�n y reparaci�n de embarcaciones de recreo"
    },
    {
        "code": "30200",
        "value": "Fabricaci�n de locomotoras y de material rodante"
    },
    {
        "code": "30300",
        "value": "Fabricaci�n de aeronaves y naves espaciales"
    },
    {
        "code": "30400",
        "value": "Fabricaci�n de veh�culos militares de combate"
    },
    {
        "code": "30910",
        "value": "Fabricaci�n de motocicletas"
    },
    {
        "code": "30920",
        "value": "Fabricaci�n de bicicletas y sillones de ruedas para inv�lidos"
    },
    {
        "code": "30990",
        "value": "Fabricaci�n de equipo de transporte ncp"
    },
    {
        "code": "31001",
        "value": "Fabricaci�n de colchones y somier"
    },
    {
        "code": "31002",
        "value": "Fabricaci�n de muebles y otros productos de madera a medida"
    },
    {
        "code": "31008",
        "value": "Servicios de maquilado de muebles"
    },
    {
        "code": "31009",
        "value": "Fabricaci�n de muebles ncp"
    },
    {
        "code": "32110",
        "value": "Fabricaci�n de joyas plater�as y joyer�as"
    },
    {
        "code": "32120",
        "value": "Fabricaci�n de joyas de imitaci�n (fantas�a) y art�culos conexos"
    },
    {
        "code": "32200",
        "value": "Fabricaci�n de instrumentos musicales"
    },
    {
        "code": "32301",
        "value": "Fabricaci�n de art�culos de deporte"
    },
    {
        "code": "32308",
        "value": "Servicio de maquila de productos deportivos"
    },
    {
        "code": "32401",
        "value": "Fabricaci�n de juegos de mesa y de sal�n"
    },
    {
        "code": "32402",
        "value": "Servicio de maquilado de juguetes y juegos"
    },
    {
        "code": "32409",
        "value": "Fabricaci�n de juegos y juguetes n.c.p."
    },
    {
        "code": "32500",
        "value": "Fabricaci�n de instrumentos y materiales m�dicos y odontol�gicos"
    },
    {
        "code": "32901",
        "value": "Fabricaci�n de l�pices, bol�grafos, sellos y art�culos de librer�a en\ngeneral"
    },
    {
        "code": "32902",
        "value": "Fabricaci�n de escobas, cepillos, pinceles y similares"
    },
    {
        "code": "32903",
        "value": "Fabricaci�n de artesan�as de materiales diversos"
    },
    {
        "code": "32904",
        "value": "Fabricaci�n de art�culos de uso personal y dom�sticos n.c.p."
    },
    {
        "code": "32905",
        "value": "Fabricaci�n de accesorios para las confecciones y la marroquiner�a\nn.c.p."
    },
    {
        "code": "32908",
        "value": "Servicios de maquila ncp"
    },
    {
        "code": "32909",
        "value": "Fabricaci�n de productos manufacturados n.c.p."
    },
    {
        "code": "33110",
        "value": "Reparaci�n y mantenimiento de productos elaborados de metal"
    },
    {
        "code": "33120",
        "value": "Reparaci�n y mantenimiento de maquinaria"
    },
    {
        "code": "33130",
        "value": "Reparaci�n y mantenimiento de equipo electr�nico y �ptico"
    },
    {
        "code": "33140",
        "value": "Reparaci�n y mantenimiento de equipo el�ctrico"
    },
    {
        "code": "33150",
        "value": "Reparaci�n y mantenimiento de equipo de transporte, excepto\nveh�culos automotores"
    },
    {
        "code": "33190",
        "value": "Reparaci�n y mantenimiento de equipos n.c.p."
    },
    {
        "code": "33200",
        "value": "Instalaci�n de maquinaria y equipo industrial"
    },
    {
        "code": "35101",
        "value": "Generaci�n de energ�a el�ctrica"
    },
    {
        "code": "35102",
        "value": "Transmisi�n de energ�a el�ctrica"
    },
    {
        "code": "35103",
        "value": "Distribuci�n de energ�a el�ctrica"
    },
    {
        "code": "35200",
        "value": "Fabricaci�n de gas, distribuci�n de combustibles gaseosos por\ntuber�as"
    },
    {
        "code": "35300",
        "value": "Suministro de vapor y agua caliente"
    },
    {
        "code": "36000",
        "value": "Captaci�n, tratamiento y suministro de agua"
    },
    {
        "code": "37000",
        "value": "Evacuaci�n de aguas residuales (alcantarillado)"
    },
    {
        "code": "38110",
        "value": "Recolecci�n y transporte de desechos s�lidos proveniente de\nhogares y sector urbano"
    },
    {
        "code": "38120",
        "value": "Recolecci�n de desechos peligrosos"
    },
    {
        "code": "38210",
        "value": "Tratamiento y eliminaci�n de desechos inicuos"
    },
    {
        "code": "38220",
        "value": "Tratamiento y eliminaci�n de desechos peligrosos"
    },
    {
        "code": "38301",
        "value": "Reciclaje de desperdicios y desechos textiles"
    },
    {
        "code": "38302",
        "value": "Reciclaje de desperdicios y desechos de pl�stico y caucho"
    },
    {
        "code": "38303",
        "value": "Reciclaje de desperdicios y desechos de vidrio"
    },
    {
        "code": "38304",
        "value": "Reciclaje de desperdicios y desechos de papel y cart�n"
    },
    {
        "code": "38305",
        "value": "Reciclaje de desperdicios y desechos met�licos"
    },
    {
        "code": "38309",
        "value": "Reciclaje de desperdicios y desechos no met�licos n.c.p."
    },
    {
        "code": "39000",
        "value": "Actividades de Saneamiento y otros Servicios de Gesti�n de\nDesechos"
    },
    {
        "code": "41001",
        "value": "Construcci�n de edificios residenciales"
    },
    {
        "code": "41002",
        "value": "Construcci�n de edificios no residenciales"
    },
    {
        "code": "42100",
        "value": "Construcci�n de carreteras, calles y caminos"
    },
    {
        "code": "42200",
        "value": "Construcci�n de proyectos de servicio p�blico"
    },
    {
        "code": "42900",
        "value": "Construcci�n de obras de ingenier�a civil n.c.p."
    },
    {
        "code": "43110",
        "value": "Demolici�n"
    },
    {
        "code": "43120",
        "value": "Preparaci�n de terreno"
    },
    {
        "code": "43210",
        "value": "Instalaciones el�ctricas"
    },
    {
        "code": "43220",
        "value": "Instalaci�n de fontaner�a, calefacci�n y aire acondicionado"
    },
    {
        "code": "43290",
        "value": "Otras instalaciones para obras de construcci�n"
    },
    {
        "code": "43300",
        "value": "Terminaci�n y acabado de edificios"
    },
    {
        "code": "43900",
        "value": "Otras actividades especializadas de construcci�n"
    },
    {
        "code": "43901",
        "value": "Fabricaci�n de techos y materiales diversos"
    },
    {
        "code": "45100",
        "value": "Venta de veh�culos automotores"
    },
    {
        "code": "45201",
        "value": "Reparaci�n mec�nica de veh�culos automotores"
    },
    {
        "code": "45202",
        "value": "Reparaciones el�ctricas del automotor y recarga de bater�as"
    },
    {
        "code": "45203",
        "value": "Enderezado y pintura de veh�culos automotores"
    },
    {
        "code": "45204",
        "value": "Reparaciones de radiadores, escapes y silenciadores"
    },
    {
        "code": "45205",
        "value": "Reparaci�n y reconstrucci�n de v�as, stop y otros art�culos de fibra\nde vidrio"
    },
    {
        "code": "45206",
        "value": "Reparaci�n de llantas de veh�culos automotores"
    },
    {
        "code": "45207",
        "value": "Polarizado de veh�culos (mediante la adhesi�n de papel especial a\nlos vidrios)"
    },
    {
        "code": "45208",
        "value": "Lavado y pasteado de veh�culos (carwash)"
    },
    {
        "code": "45209",
        "value": "Reparaciones de veh�culos n.c.p."
    },
    {
        "code": "45211",
        "value": "Remolque de veh�culos automotores"
    },
    {
        "code": "45301",
        "value": "Venta de partes, piezas y accesorios nuevos para veh�culos\nautomotores"
    },
    {
        "code": "45302",
        "value": "Venta de partes, piezas y accesorios usados para veh�culos\nautomotores"
    },
    {
        "code": "45401",
        "value": "Venta de motocicletas"
    },
    {
        "code": "45402",
        "value": "Venta de repuestos, piezas y accesorios de motocicletas"
    },
    {
        "code": "45403",
        "value": "Mantenimiento y reparaci�n de motocicletas"
    },
    {
        "code": "46100",
        "value": "Venta al por mayor a cambio de retribuci�n o por contrata"
    },
    {
        "code": "46201",
        "value": "Venta al por mayor de materias primas agr�colas"
    },
    {
        "code": "46202",
        "value": "Venta al por mayor de productos de la silvicultura"
    },
    {
        "code": "46203",
        "value": "Venta al por mayor de productos pecuarios y de granja"
    },
    {
        "code": "46211",
        "value": "Venta de productos para uso agropecuario"
    },
    {
        "code": "46291",
        "value": "Venta al por mayor de granos b�sicos (cereales, leguminosas)"
    },
    {
        "code": "46292",
        "value": "Venta al por mayor de semillas mejoradas para cultivo"
    },
    {
        "code": "46293",
        "value": "Venta al por mayor de caf� oro y uva"
    },
    {
        "code": "46294",
        "value": "Venta al por mayor de ca�a de az�car"
    },
    {
        "code": "46295",
        "value": "Venta al por mayor de flores, plantas y otros productos naturales"
    },
    {
        "code": "46296",
        "value": "Venta al por mayor de productos agr�colas"
    },
    {
        "code": "46297",
        "value": "Venta al por mayor de ganado bovino (vivo)"
    },
    {
        "code": "46298",
        "value": "Venta al por mayor de animales porcinos, ovinos, caprino,\ncan�culas, ap�colas, av�colas vivos"
    },
    {
        "code": "46299",
        "value": "Venta de otras especies vivas del reino animal"
    },
    {
        "code": "46301",
        "value": "Venta al por mayor de alimentos"
    },
    {
        "code": "46302",
        "value": "Venta al por mayor de bebidas"
    },
    {
        "code": "46303",
        "value": "Venta al por mayor de tabaco"
    },
    {
        "code": "46371",
        "value": "Venta al por mayor de frutas, hortalizas (verduras), legumbres y\ntub�rculos"
    },
    {
        "code": "46372",
        "value": "Venta al por mayor de pollos, gallinas destazadas, pavos y otras\naves"
    },
    {
        "code": "46373",
        "value": "Venta al por mayor de carne bovina y porcina, productos de carne y\nembutidos"
    },
    {
        "code": "46374",
        "value": "Venta al por mayor de huevos"
    },
    {
        "code": "46375",
        "value": "Venta al por mayor de productos l�cteos"
    },
    {
        "code": "46376",
        "value": "Venta al por mayor de productos farin�ceos de panader�a (pan\ndulce, cakes, reposter�a, etc.)"
    },
    {
        "code": "46377",
        "value": "Venta al por mayor de pastas alimenticias, aceites y grasas comestibles vegetal y animal"
    },
    {
        "code": "46378",
        "value": "Venta al por mayor de sal comestible"
    },
    {
        "code": "46379",
        "value": "Venta al por mayor de az�car"
    },
    {
        "code": "46391",
        "value": "Venta al por mayor de abarrotes (vinos, licores, productos\nalimenticios envasados, etc.)"
    },
    {
        "code": "46392",
        "value": "Venta al por mayor de aguas gaseosas"
    },
    {
        "code": "46393",
        "value": "Venta al por mayor de agua purificada"
    },
    {
        "code": "46394",
        "value": "Venta al por mayor de refrescos y otras bebidas, l�quidas o en polvo"
    },
    {
        "code": "46395",
        "value": "Venta al por mayor de cerveza y licores"
    },
    {
        "code": "46396",
        "value": "Venta al por mayor de hielo"
    },
    {
        "code": "46411",
        "value": "Venta al por mayor de hilados, tejidos y productos textiles de\nmercer�a"
    },
    {
        "code": "46412",
        "value": "Venta al por mayor de art�culos textiles excepto confecciones para\nel hogar"
    },
    {
        "code": "46413",
        "value": "Venta al por mayor de confecciones textiles para el hogar"
    },
    {
        "code": "46414",
        "value": "Venta al por mayor de prendas de vestir y accesorios de vestir"
    },
    {
        "code": "46415",
        "value": "Venta al por mayor de ropa usada"
    },
    {
        "code": "46416",
        "value": "Venta al por mayor de calzado"
    },
    {
        "code": "46417",
        "value": "Venta al por mayor de art�culos de marroquiner�a y talabarter�a"
    },
    {
        "code": "46418",
        "value": "Venta al por mayor de art�culos de peleter�a"
    },
    {
        "code": "46419",
        "value": "Venta al por mayor de otros art�culos textiles n.c.p."
    },
    {
        "code": "46471",
        "value": "Venta al por mayor de instrumentos musicales"
    },
    {
        "code": "46472",
        "value": "Venta al por mayor de colchones, almohadas, cojines, etc."
    },
    {
        "code": "46473",
        "value": "Venta al por mayor de art�culos de aluminio para el hogar y para\notros usos"
    },
    {
        "code": "46474",
        "value": "Venta al por mayor de dep�sitos y otros art�culos pl�sticos para el hogar y otros usos, incluyendo los desechables de durapax y no\ndesechables"
    },
    {
        "code": "46475",
        "value": "Venta al por mayor de c�maras fotogr�ficas, accesorios y materiales"
    },
    {
        "code": "46482",
        "value": "Venta al por mayor de medicamentos, art�culos y otros productos\nde uso veterinario"
    },
    {
        "code": "46483",
        "value": "Venta al por mayor de productos y art�culos de belleza y de uso\npersonal"
    },
    {
        "code": "46484",
        "value": "Venta de productos farmac�uticos y medicinales"
    },
    {
        "code": "46491",
        "value": "Venta al por mayor de productos medicinales, cosm�ticos,\nperfumer�a y productos de limpieza"
    },
    {
        "code": "46492",
        "value": "Venta al por mayor de relojes y art�culos de joyer�a"
    },
    {
        "code": "46493",
        "value": "Venta al por mayor de electrodom�sticos y art�culos del hogar\nexcepto bazar; art�culos de iluminaci�n"
    },
    {
        "code": "46494",
        "value": "Venta al por mayor de art�culos de bazar y similares"
    },
    {
        "code": "46495",
        "value": "Venta al por mayor de art�culos de �ptica"
    },
    {
        "code": "46496",
        "value": "Venta al por mayor de revistas, peri�dicos, libros, art�culos de\nlibrer�a y art�culos de papel y cart�n en general"
    },
    {
        "code": "46497",
        "value": "Venta de art�culos deportivos, juguetes y rodados"
    },
    {
        "code": "46498",
        "value": "Venta al por mayor de productos usados para el hogar o el uso\npersonal"
    },
    {
        "code": "46499",
        "value": "Venta al por mayor de enseres dom�sticos y de uso personal n.c.p."
    },
    {
        "code": "46500",
        "value": "Venta al por mayor de bicicletas, partes, accesorios y otros"
    },
    {
        "code": "46510",
        "value": "Venta al por mayor de computadoras, equipo perif�rico y\nprogramas inform�ticos"
    },
    {
        "code": "46520",
        "value": "Venta al por mayor de equipos de comunicaci�n"
    },
    {
        "code": "46530",
        "value": "Venta al por mayor de maquinaria y equipo agropecuario,\naccesorios, partes y suministros"
    },
    {
        "code": "46590",
        "value": "Venta de equipos e instrumentos de uso profesional y cient�fico y\naparatos de medida y control"
    },
    {
        "code": "46591",
        "value": "Venta al por mayor de maquinaria equipo, accesorios y materiales\npara la industria de la madera y sus productos"
    },
    {
        "code": "46592",
        "value": "Venta al por mayor de maquinaria, equipo, accesorios y materiales para la industria gr�fica y del papel, cart�n y productos de papel y\ncart�n"
    },
    {
        "code": "46593",
        "value": "Venta al por mayor de maquinaria, equipo, accesorios y materiales\npara la industria de productos qu�micos, pl�stico y caucho"
    },
    {
        "code": "46594",
        "value": "Venta al por mayor de maquinaria, equipo, accesorios y materiales\npara la industria met�lica y de sus productos"
    },
    {
        "code": "46595",
        "value": "Venta al por mayor de equipamiento para uso m�dico,\nodontol�gico, veterinario y servicios conexos"
    },
    {
        "code": "46596",
        "value": "Venta al por mayor de maquinaria, equipo, accesorios y partes para\nla industria de la alimentaci�n"
    },
    {
        "code": "46597",
        "value": "Venta al por mayor de maquinaria, equipo, accesorios y partes para\nla industria textil, confecciones y cuero"
    },
    {
        "code": "46598",
        "value": "Venta al por mayor de maquinaria, equipo y accesorios para la\nconstrucci�n y explotaci�n de minas y canteras"
    },
    {
        "code": "46599",
        "value": "Venta al por mayor de otro tipo de maquinaria y equipo con sus\naccesorios y partes"
    },
    {
        "code": "46610",
        "value": "Venta al por mayor de otros combustibles s�lidos, l�quidos,\ngaseosos y de productos conexos"
    },
    {
        "code": "46612",
        "value": "Venta al por mayor de combustibles para automotores, aviones,\nbarcos, maquinaria y otros"
    },
    {
        "code": "46613",
        "value": "Venta al por mayor de lubricantes, grasas y otros aceites para\nautomotores, maquinaria industrial, etc."
    },
    {
        "code": "46614",
        "value": "Venta al por mayor de gas propano"
    },
    {
        "code": "46615",
        "value": "Venta al por mayor de le�a y carb�n"
    },
    {
        "code": "46620",
        "value": "Venta al por mayor de metales y minerales metal�feros"
    },
    {
        "code": "46631",
        "value": "Venta al por mayor de puertas, ventanas, vitrinas y similares"
    },
    {
        "code": "46632",
        "value": "Venta al por mayor de art�culos de ferreter�a y pinturer�as"
    },
    {
        "code": "46633",
        "value": "Vidrier�as"
    },
    {
        "code": "46634",
        "value": "Venta al por mayor de maderas"
    },
    {
        "code": "46639",
        "value": "Venta al por mayor de materiales para la construcci�n n.c.p."
    },
    {
        "code": "46691",
        "value": "Venta al por mayor de sal industrial sin yodar"
    },
    {
        "code": "46692",
        "value": "Venta al por mayor de productos intermedios y desechos de origen\ntextil"
    },
    {
        "code": "46693",
        "value": "Venta al por mayor de productos intermedios y desechos de origen\nmet�lico"
    },
    {
        "code": "46694",
        "value": "Venta al por mayor de productos intermedios y desechos de papel\ny cart�n"
    },
    {
        "code": "46695",
        "value": "Venta al por mayor fertilizantes, abonos, agroqu�micos y productos\nsimilares"
    },
    {
        "code": "46696",
        "value": "Venta al por mayor de productos intermedios y desechos de origen\npl�stico"
    },
    {
        "code": "46697",
        "value": "Venta al por mayor de tintas para imprenta, productos curtientes y\nmaterias y productos colorantes"
    },
    {
        "code": "46698",
        "value": "Venta de productos intermedios y desechos de origen qu�mico y de\ncaucho"
    },
    {
        "code": "46699",
        "value": "Venta al por mayor de productos intermedios y desechos ncp"
    },
    {
        "code": "46701",
        "value": "Venta de algod�n en oro"
    },
    {
        "code": "46900",
        "value": "Venta al por mayor de otros productos"
    },
    {
        "code": "46901",
        "value": "Venta al por mayor de cohetes y otros productos pirot�cnicos"
    },
    {
        "code": "46902",
        "value": "Venta al por mayor de art�culos diversos para consumo humano"
    },
    {
        "code": "46903",
        "value": "Venta al por mayor de armas de fuego, municiones y accesorios"
    },
    {
        "code": "46904",
        "value": "Venta al por mayor de toldos y tiendas de campa�a de cualquier\nmaterial"
    },
    {
        "code": "46905",
        "value": "Venta al por mayor de exhibidores publicitarios y r�tulos"
    },
    {
        "code": "46906",
        "value": "Venta al por mayor de art�culos promocionales diversos"
    },
    {
        "code": "47111",
        "value": "Venta en supermercados"
    },
    {
        "code": "47112",
        "value": "Venta en tiendas de art�culos de primera necesidad"
    },
    {
        "code": "47119",
        "value": "Almacenes (venta de diversos art�culos)"
    },
    {
        "code": "47190",
        "value": "Venta al por menor de otros productos en comercios no\nespecializados"
    },
    {
        "code": "47199",
        "value": "Venta de establecimientos no especializados con surtido\ncompuesto principalmente de alimentos, bebidas y tabaco"
    },
    {
        "code": "47211",
        "value": "Venta al por menor de frutas y hortalizas"
    },
    {
        "code": "47212",
        "value": "Venta al por menor de carnes, embutidos y productos de granja"
    },
    {
        "code": "47213",
        "value": "Venta al por menor de pescado y mariscos"
    },
    {
        "code": "47214",
        "value": "Venta al por menor de productos l�cteos"
    },
    {
        "code": "47215",
        "value": "Venta al por menor de productos de panader�a, reposter�a y galletas"
    },
    {
        "code": "47216",
        "value": "Venta al por menor de huevos"
    },
    {
        "code": "47217",
        "value": "Venta al por menor de carnes y productos c�rnicos"
    },
    {
        "code": "47218",
        "value": "Venta al por menor de granos b�sicos y otros"
    },
    {
        "code": "47219",
        "value": "Venta al por menor de alimentos n.c.p."
    },
    {
        "code": "47221",
        "value": "Venta al por menor de hielo"
    },
    {
        "code": "47223",
        "value": "Venta de bebidas no alcoh�licas, para su consumo fuera del\nestablecimiento"
    },
    {
        "code": "47224",
        "value": "Venta de bebidas alcoh�licas, para su consumo fuera del\nestablecimiento"
    },
    {
        "code": "47225",
        "value": "Venta de bebidas alcoh�licas para su consumo dentro del\nestablecimiento"
    },
    {
        "code": "47230",
        "value": "Venta al por menor de tabaco"
    },
    {
        "code": "47300",
        "value": "Venta de combustibles, lubricantes y otros (gasolineras)"
    },
    {
        "code": "47411",
        "value": "Venta al por menor de computadoras y equipo perif�rico"
    },
    {
        "code": "47412",
        "value": "Venta de equipo y accesorios de telecomunicaci�n"
    },
    {
        "code": "47420",
        "value": "Venta al por menor de equipo de audio y video"
    },
    {
        "code": "47510",
        "value": "Venta al por menor de hilados, tejidos y productos textiles de\nmercer�a; confecciones para el hogar y textiles n.c.p."
    },
    {
        "code": "47521",
        "value": "Venta al por menor de productos de madera"
    },
    {
        "code": "47522",
        "value": "Venta al por menor de art�culos de ferreter�a"
    },
    {
        "code": "47523",
        "value": "Venta al por menor de productos de pinturer�as"
    },
    {
        "code": "47524",
        "value": "Venta al por menor en vidrier�as"
    },
    {
        "code": "47529",
        "value": "Venta al por menor de materiales de construcci�n y art�culos\nconexos"
    },
    {
        "code": "47530",
        "value": "Venta al por menor de tapices, alfombras y revestimientos de\nparedes y pisos en comercios especializados"
    },
    {
        "code": "47591",
        "value": "Venta al por menor de muebles"
    },
    {
        "code": "47592",
        "value": "Venta al por menor de art�culos de bazar"
    },
    {
        "code": "47593",
        "value": "Venta al por menor de aparatos electrodom�sticos, repuestos y\naccesorios"
    },
    {
        "code": "47594",
        "value": "Venta al por menor de art�culos el�ctricos y de iluminaci�n"
    },
    {
        "code": "47598",
        "value": "Venta al por menor de instrumentos musicales"
    },
    {
        "code": "47610",
        "value": "Venta al por menor de libros, peri�dicos y art�culos de papeler�a en\ncomercios especializados"
    },
    {
        "code": "47620",
        "value": "Venta al por menor de discos l�ser, cassettes, cintas de video y\notros"
    },
    {
        "code": "47630",
        "value": "Venta al por menor de productos y equipos de deporte"
    },
    {
        "code": "47631",
        "value": "Venta al por menor de bicicletas, accesorios y repuestos"
    },
    {
        "code": "47640",
        "value": "Venta al por menor de juegos y juguetes en comercios\nespecializados"
    },
    {
        "code": "47711",
        "value": "Venta al por menor de prendas de vestir y accesorios de vestir"
    },
    {
        "code": "47712",
        "value": "Venta al por menor de calzado"
    },
    {
        "code": "47713",
        "value": "Venta al por menor de art�culos de peleter�a, marroquiner�a y\ntalabarter�a"
    },
    {
        "code": "47721",
        "value": "Venta al por menor de medicamentos farmac�uticos y otros\nmateriales y art�culos de uso m�dico, odontol�gico y veterinario"
    },
    {
        "code": "47722",
        "value": "Venta al por menor de productos cosm�ticos y de tocador"
    },
    {
        "code": "47731",
        "value": "Venta al por menor de productos de joyer�a, bisuter�a, �ptica,\nrelojer�a"
    },
    {
        "code": "47732",
        "value": "Venta al por menor de plantas, semillas, animales y art�culos\nconexos"
    },
    {
        "code": "47733",
        "value": "Venta al por menor de combustibles de uso dom�stico (gas\npropano y gas licuado)"
    },
    {
        "code": "47734",
        "value": "Venta al por menor de artesan�as, art�culos cer�micos y recuerdos\nen general"
    },
    {
        "code": "47735",
        "value": "Venta al por menor de ata�des, l�pidas y cruces, trofeos, art�culos\nreligiosos en general"
    },
    {
        "code": "47736",
        "value": "Venta al por menor de armas de fuego, municiones y accesorios"
    },
    {
        "code": "47737",
        "value": "Venta al por menor de art�culos de coheter�a y pirot�cnicos"
    },
    {
        "code": "47738",
        "value": "Venta al por menor de art�culos desechables de uso personal y dom�stico (servilletas, papel higi�nico, pa�ales, toallas sanitarias,\netc.)"
    },
    {
        "code": "47739",
        "value": "Venta al por menor de otros productos n.c.p."
    },
    {
        "code": "47741",
        "value": "Venta al por menor de art�culos usados"
    },
    {
        "code": "47742",
        "value": "Venta al por menor de textiles y confecciones usados"
    },
    {
        "code": "47743",
        "value": "Venta al por menor de libros, revistas, papel y cart�n usados"
    },
    {
        "code": "47749",
        "value": "Venta al por menor de productos usados n.c.p."
    },
    {
        "code": "47811",
        "value": "Venta al por menor de frutas, verduras y hortalizas"
    },
    {
        "code": "47814",
        "value": "Venta al por menor de productos l�cteos"
    },
    {
        "code": "47815",
        "value": "Venta al por menor de productos de panader�a, galletas y similares"
    },
    {
        "code": "47816",
        "value": "Venta al por menor de bebidas"
    },
    {
        "code": "47818",
        "value": "Venta al por menor en tiendas de mercado y puestos"
    },
    {
        "code": "47821",
        "value": "Venta al por menor de hilados, tejidos y productos textiles de\nmercer�a en puestos de mercados y ferias"
    },
    {
        "code": "47822",
        "value": "Venta al por menor de art�culos textiles excepto confecciones para\nel hogar en puestos de mercados y ferias"
    },
    {
        "code": "47823",
        "value": "Venta al por menor de confecciones textiles para el hogar en\npuestos de mercados y ferias"
    },
    {
        "code": "47824",
        "value": "Venta al por menor de prendas de vestir, accesorios de vestir y\nsimilares en puestos de mercados y ferias"
    },
    {
        "code": "47825",
        "value": "Venta al por menor de ropa usada"
    },
    {
        "code": "47826",
        "value": "Venta al por menor de calzado, art�culos de marroquiner�a y\ntalabarter�a en puestos de mercados y ferias"
    },
    {
        "code": "47827",
        "value": "Venta al por menor de art�culos de marroquiner�a y talabarter�a en\npuestos de mercados y ferias"
    },
    {
        "code": "47829",
        "value": "Venta al por menor de art�culos textiles ncp en puestos de\nmercados y ferias"
    },
    {
        "code": "47891",
        "value": "Venta al por menor de animales, flores y productos conexos en\npuestos de feria y mercados"
    },
    {
        "code": "47892",
        "value": "Venta al por menor de productos medicinales, cosm�ticos, de\ntocador y de limpieza en puestos de ferias y mercados"
    },
    {
        "code": "47893",
        "value": "Venta al por menor de art�culos de bazar en puestos de ferias y\nmercados"
    },
    {
        "code": "47894",
        "value": "Venta al por menor de art�culos de papel, envases, libros, revistas y\nconexos en puestos de feria y mercados"
    },
    {
        "code": "47895",
        "value": "Venta al por menor de materiales de construcci�n, electrodom�sticos, accesorios para autos y similares en puestos de\nferia y mercados"
    },
    {
        "code": "47896",
        "value": "Venta al por menor de equipos accesorios para las comunicaciones\nen puestos de feria y mercados"
    },
    {
        "code": "47899",
        "value": "Venta al por menor en puestos de ferias y mercados n.c.p."
    },
    {
        "code": "47910",
        "value": "Venta al por menor por correo o Internet"
    },
    {
        "code": "47990",
        "value": "Otros tipos de venta al por menor no realizada, en almacenes,\npuestos de venta o mercado"
    },
    {
        "code": "49110",
        "value": "Transporte interurbano de pasajeros por ferrocarril"
    },
    {
        "code": "49120",
        "value": "Transporte de carga por ferrocarril"
    },
    {
        "code": "49211",
        "value": "Transporte de pasajeros urbanos e interurbano mediante buses"
    },
    {
        "code": "49212",
        "value": "Transporte de pasajeros interdepartamental mediante microbuses"
    },
    {
        "code": "49213",
        "value": "Transporte de pasajeros urbanos e interurbano mediante\nmicrobuses"
    },
    {
        "code": "49214",
        "value": "Transporte de pasajeros interdepartamental mediante buses"
    },
    {
        "code": "49221",
        "value": "Transporte internacional de pasajeros"
    },
    {
        "code": "49222",
        "value": "Transporte de pasajeros mediante taxis y autos con chofer"
    },
    {
        "code": "49223",
        "value": "Transporte escolar"
    },
    {
        "code": "49225",
        "value": "Transporte de pasajeros para excursiones"
    },
    {
        "code": "49226",
        "value": "Servicios de transporte de personal"
    },
    {
        "code": "49229",
        "value": "Transporte de pasajeros por v�a terrestre ncp"
    },
    {
        "code": "49231",
        "value": "Transporte de carga urbano"
    },
    {
        "code": "49232",
        "value": "Transporte nacional de carga"
    },
    {
        "code": "49233",
        "value": "Transporte de carga internacional"
    },
    {
        "code": "49234",
        "value": "Servicios de mudanza"
    },
    {
        "code": "49235",
        "value": "Alquiler de veh�culos de carga con conductor"
    },
    {
        "code": "49300",
        "value": "Transporte por oleoducto o gasoducto"
    },
    {
        "code": "",
        "value": "TRANSPORTE POR V�A ACU�TICA"
    },
    {
        "code": "50110",
        "value": "Transporte de pasajeros mar�timo y de cabotaje"
    },
    {
        "code": "50120",
        "value": "Transporte de carga mar�timo y de cabotaje"
    },
    {
        "code": "50211",
        "value": "Transporte de pasajeros por v�as de navegaci�n interiores"
    },
    {
        "code": "50212",
        "value": "Alquiler de equipo de transporte de pasajeros por v�as de\nnavegaci�n interior con conductor"
    },
    {
        "code": "50220",
        "value": "Transporte de carga por v�as de navegaci�n interiores"
    },
    {
        "code": "51100",
        "value": "Transporte a�reo de pasajeros"
    },
    {
        "code": "51201",
        "value": "Transporte de carga por v�a a�rea"
    },
    {
        "code": "51202",
        "value": "Alquiler de equipo de aerotransporte con operadores para el\nprop�sito de transportar carga"
    },
    {
        "code": "52101",
        "value": "Alquiler de instalaciones de almacenamiento en zonas francas"
    },
    {
        "code": "52102",
        "value": "Alquiler de silos para conservaci�n y almacenamiento de granos"
    },
    {
        "code": "52103",
        "value": "Alquiler de instalaciones con refrigeraci�n para almacenamiento y\nconservaci�n de alimentos y otros productos"
    },
    {
        "code": "52109",
        "value": "Alquiler de bodegas para almacenamiento y dep�sito n.c.p."
    },
    {
        "code": "52211",
        "value": "Servicio de garaje y estacionamiento"
    },
    {
        "code": "52212",
        "value": "Servicios de terminales para el transporte por v�a terrestre"
    },
    {
        "code": "52219",
        "value": "Servicios para el transporte por v�a terrestre n.c.p."
    },
    {
        "code": "52220",
        "value": "Servicios para el transporte acu�tico"
    },
    {
        "code": "52230",
        "value": "Servicios para el transporte a�reo"
    },
    {
        "code": "52240",
        "value": "Manipulaci�n de carga"
    },
    {
        "code": "52290",
        "value": "Servicios para el transporte ncp"
    },
    {
        "code": "52291",
        "value": "Agencias de tramitaciones aduanales"
    },
    {
        "code": "",
        "value": "ACTIVIDADES POSTALES Y DE MENSAJER�A"
    },
    {
        "code": "53100",
        "value": "Servicios de correo nacional"
    },
    {
        "code": "53200",
        "value": "Actividades de correo distintas a las actividades postales nacionales"
    },
    {
        "code": "53201",
        "value": "Agencia privada de correo y encomiendas"
    },
    {
        "code": "55101",
        "value": "Actividades de alojamiento para estancias cortas"
    },
    {
        "code": "55102",
        "value": "Hoteles"
    },
    {
        "code": "55200",
        "value": "Actividades de campamentos, parques de veh�culos de recreo y\nparques de caravanas"
    },
    {
        "code": "55900",
        "value": "Alojamiento n.c.p."
    },
    {
        "code": "56101",
        "value": "Restaurantes"
    },
    {
        "code": "56106",
        "value": "Pupuser�a"
    },
    {
        "code": "56107",
        "value": "Actividades varias de restaurantes"
    },
    {
        "code": "56108",
        "value": "Comedores"
    },
    {
        "code": "56109",
        "value": "Merenderos ambulantes"
    },
    {
        "code": "56210",
        "value": "Preparaci�n de comida para eventos especiales"
    },
    {
        "code": "56291",
        "value": "Servicios de provisi�n de comidas por contrato"
    },
    {
        "code": "56292",
        "value": "Servicios de concesi�n de cafetines y chalet en empresas e\ninstituciones"
    },
    {
        "code": "56299",
        "value": "Servicios de preparaci�n de comidas ncp"
    },
    {
        "code": "56301",
        "value": "Servicio de expendio de bebidas en salones y bares"
    },
    {
        "code": "56302",
        "value": "Servicio de expendio de bebidas en puestos callejeros, mercados y\nferias"
    },
    {
        "code": "58110",
        "value": "Edici�n de libros, folletos, partituras y otras ediciones distintas a\nestas"
    },
    {
        "code": "58120",
        "value": "Edici�n de directorios y listas de correos"
    },
    {
        "code": "58130",
        "value": "Edici�n de peri�dicos, revistas y otras publicaciones peri�dicas"
    },
    {
        "code": "58190",
        "value": "Otras actividades de edici�n"
    },
    {
        "code": "58200",
        "value": "Edici�n de programas inform�ticos (software)"
    },
    {
        "code": "59110",
        "value": "Actividades de producci�n cinematogr�fica"
    },
    {
        "code": "59120",
        "value": "Actividades de post producci�n de pel�culas, videos y programas de\ntelevisi�n"
    },
    {
        "code": "59130",
        "value": "Actividades de distribuci�n de pel�culas cinematogr�ficas, videos y\nprogramas de televisi�n"
    },
    {
        "code": "59140",
        "value": "Actividades de exhibici�n de pel�culas cinematogr�ficas y cintas de\nv�deo"
    },
    {
        "code": "59200",
        "value": "Actividades de edici�n y grabaci�n de m�sica"
    },
    {
        "code": "60100",
        "value": "Servicios de difusiones de radio"
    },
    {
        "code": "60201",
        "value": "Actividades de programaci�n y difusi�n de televisi�n abierta"
    },
    {
        "code": "60202",
        "value": "Actividades de suscripci�n y difusi�n de televisi�n por cable y/o\nsuscripci�n"
    },
    {
        "code": "60299",
        "value": "Servicios de televisi�n, incluye televisi�n por cable"
    },
    {
        "code": "60900",
        "value": "Programaci�n y transmisi�n de radio y televisi�n"
    },
    {
        "code": "61101",
        "value": "Servicio de telefon�a"
    },
    {
        "code": "61102",
        "value": "Servicio de Internet"
    },
    {
        "code": "61103",
        "value": "Servicio de telefon�a fija"
    },
    {
        "code": "61109",
        "value": "Servicio de Internet n.c.p."
    },
    {
        "code": "61201",
        "value": "Servicios de telefon�a celular"
    },
    {
        "code": "61202",
        "value": "Servicios de Internet inal�mbrico"
    },
    {
        "code": "61209",
        "value": "Servicios de telecomunicaciones inal�mbrico n.c.p."
    },
    {
        "code": "61301",
        "value": "Telecomunicaciones satelitales"
    },
    {
        "code": "61309",
        "value": "Comunicaci�n v�a sat�lite n.c.p."
    },
    {
        "code": "61900",
        "value": "Actividades de telecomunicaci�n n.c.p."
    },
    {
        "code": "62010",
        "value": "Programaci�n inform�tica"
    },
    {
        "code": "62020",
        "value": "Consultor�as y gesti�n de servicios inform�ticos"
    },
    {
        "code": "62090",
        "value": "Otras actividades de tecnolog�a de informaci�n y servicios de\ncomputadora"
    },
    {
        "code": "63110",
        "value": "Procesamiento de datos y actividades relacionadas"
    },
    {
        "code": "63120",
        "value": "Portales WEB"
    },
    {
        "code": "63910",
        "value": "Servicios de Agencias de Noticias"
    },
    {
        "code": "63990",
        "value": "Otros servicios de informaci�n n.c.p."
    },
    {
        "code": "64110",
        "value": "Servicios provistos por el Banco Central de El salvador"
    },
    {
        "code": "64190",
        "value": "Bancos"
    },
    {
        "code": "64192",
        "value": "Entidades dedicadas al env�o de remesas"
    },
    {
        "code": "64199",
        "value": "Otras entidades financieras"
    },
    {
        "code": "64200",
        "value": "Actividades de sociedades de cartera"
    },
    {
        "code": "64300",
        "value": "Fideicomisos, fondos y otras fuentes de financiamiento"
    },
    {
        "code": "64910",
        "value": "Arrendamientos financieros"
    },
    {
        "code": "64920",
        "value": "Asociaciones cooperativas de ahorro y cr�dito dedicadas a la\nintermediaci�n financiera"
    },
    {
        "code": "64921",
        "value": "Instituciones emisoras de tarjetas de cr�dito y otros"
    },
    {
        "code": "64922",
        "value": "Tipos de cr�dito ncp"
    },
    {
        "code": "64928",
        "value": "Prestamistas y casas de empe�o"
    },
    {
        "code": "64990",
        "value": "Actividades de servicios financieros, excepto la financiaci�n de\nplanes de seguros y de pensiones n.c.p."
    },
    {
        "code": "65110",
        "value": "Planes de seguros de vida"
    },
    {
        "code": "65120",
        "value": "Planes de seguro excepto de vida"
    },
    {
        "code": "65199",
        "value": "Seguros generales de todo tipo"
    },
    {
        "code": "65200",
        "value": "Planes se seguro"
    },
    {
        "code": "65300",
        "value": "Planes de pensiones"
    },
    {
        "code": "66110",
        "value": "Administraci�n de mercados financieros (Bolsa de value)"
    },
    {
        "code": "66120",
        "value": "Actividades burs�tiles (Corredores de Bolsa)"
    },
    {
        "code": "66190",
        "value": "Actividades auxiliares de la intermediaci�n financiera ncp"
    },
    {
        "code": "66210",
        "value": "Evaluaci�n de riesgos y da�os"
    },
    {
        "code": "66220",
        "value": "Actividades de agentes y corredores de seguros"
    },
    {
        "code": "66290",
        "value": "Otras actividades auxiliares de seguros y fondos de pensiones"
    },
    {
        "code": "66300",
        "value": "Actividades de administraci�n de fondos"
    },
    {
        "code": "",
        "value": "ACTIVIDADES INMOBILIARIAS"
    },
    {
        "code": "68101",
        "value": "Servicio de alquiler y venta de lotes en cementerios"
    },
    {
        "code": "68109",
        "value": "Actividades inmobiliarias realizadas con bienes propios o\narrendados n.c.p."
    },
    {
        "code": "68200",
        "value": "Actividades Inmobiliarias Realizadas a Cambio de una Retribuci�n o\npor Contrata"
    },
    {
        "code": "69100",
        "value": "Actividades jur�dicas"
    },
    {
        "code": "69200",
        "value": "Actividades de contabilidad, tenedur�a de libros y auditor�a;\nasesoramiento en materia de impuestos"
    },
    {
        "code": "70100",
        "value": "Actividades de oficinas centrales de sociedades de cartera"
    },
    {
        "code": "70200",
        "value": "Actividades de consultor�a en gesti�n empresarial"
    },
    {
        "code": "71101",
        "value": "Servicios de arquitectura y planificaci�n urbana y servicios conexos"
    },
    {
        "code": "71102",
        "value": "Servicios de ingenier�a"
    },
    {
        "code": "71103",
        "value": "Servicios de agrimensura, topograf�a, cartograf�a, prospecci�n y\ngeof�sica y servicios conexos"
    },
    {
        "code": "71200",
        "value": "Ensayos y an�lisis t�cnicos"
    },
    {
        "code": "72100",
        "value": "Investigaciones y desarrollo experimental en el campo de las\nciencias naturales y la ingenier�a"
    },
    {
        "code": "72199",
        "value": "Investigaciones cient�ficas"
    },
    {
        "code": "72200",
        "value": "Investigaciones y desarrollo experimental en el campo de las\nciencias sociales y las humanidades cient�fica y desarrollo"
    },
    {
        "code": "73100",
        "value": "Publicidad"
    },
    {
        "code": "73200",
        "value": "Investigaci�n de mercados y realizaci�n de encuestas de opini�n\np�blica"
    },
    {
        "code": "74100",
        "value": "Actividades de dise�o especializado"
    },
    {
        "code": "74200",
        "value": "Actividades de fotograf�a"
    },
    {
        "code": "74900",
        "value": "Servicios profesionales y cient�ficos ncp"
    },
    {
        "code": "75000",
        "value": "Actividades veterinarias"
    },
    {
        "code": "77101",
        "value": "Alquiler de equipo de transporte terrestre"
    },
    {
        "code": "77102",
        "value": "Alquiler de equipo de transporte acu�tico"
    },
    {
        "code": "77103",
        "value": "Alquiler de equipo de transporte por v�a a�rea"
    },
    {
        "code": "77210",
        "value": "Alquiler y arrendamiento de equipo de recreo y deportivo"
    },
    {
        "code": "77220",
        "value": "Alquiler de cintas de video y discos"
    },
    {
        "code": "77290",
        "value": "Alquiler de otros efectos personales y enseres dom�sticos"
    },
    {
        "code": "77300",
        "value": "Alquiler de maquinaria y equipo"
    },
    {
        "code": "77400",
        "value": "Arrendamiento de productos de propiedad intelectual"
    },
    {
        "code": "78100",
        "value": "Obtenci�n y dotaci�n de personal"
    },
    {
        "code": "78200",
        "value": "Actividades de las agencias de trabajo temporal"
    },
    {
        "code": "78300",
        "value": "Dotaci�n de recursos humanos y gesti�n; gesti�n de las funciones\nde recursos humanos"
    },
    {
        "code": "79110",
        "value": "Actividades de agencias de viajes y organizadores de viajes;\nactividades de asistencia a turistas"
    },
    {
        "code": "79120",
        "value": "Actividades de los operadores tur�sticos"
    },
    {
        "code": "79900",
        "value": "Otros servicios de reservas y actividades relacionadas"
    },
    {
        "code": "80100",
        "value": "Servicios de seguridad privados"
    },
    {
        "code": "80201",
        "value": "Actividades de servicios de sistemas de seguridad"
    },
    {
        "code": "80202",
        "value": "Actividades para la prestaci�n de sistemas de seguridad"
    },
    {
        "code": "80300",
        "value": "Actividades de investigaci�n"
    },
    {
        "code": "81100",
        "value": "Actividades combinadas de mantenimiento de edificios e\ninstalaciones"
    },
    {
        "code": "81210",
        "value": "Limpieza general de edificios"
    },
    {
        "code": "81290",
        "value": "Otras actividades combinadas de mantenimiento de edificios e\ninstalaciones ncp"
    },
    {
        "code": "81300",
        "value": "Servicio de jardiner�a"
    },
    {
        "code": "82110",
        "value": "Servicios administrativos de oficinas"
    },
    {
        "code": "82190",
        "value": "Servicio de fotocopiado y similares, excepto en imprentas"
    },
    {
        "code": "82200",
        "value": "Actividades de las centrales de llamadas (call center)"
    },
    {
        "code": "82300",
        "value": "Organizaci�n de convenciones y ferias de negocios"
    },
    {
        "code": "82910",
        "value": "Actividades de agencias de cobro y oficinas de cr�dito"
    },
    {
        "code": "82921",
        "value": "Servicios de envase y empaque de productos alimenticios"
    },
    {
        "code": "82922",
        "value": "Servicios de envase y empaque de productos medicinales"
    },
    {
        "code": "82929",
        "value": "Servicio de envase y empaque ncp"
    },
    {
        "code": "82990",
        "value": "Actividades de apoyo empresariales ncp"
    },
    {
        "code": "84110",
        "value": "Actividades de la Administraci�n P�blica en general"
    },
    {
        "code": "84111",
        "value": "Alcald�as municipales"
    },
    {
        "code": "84120",
        "value": "Regulaci�n de las actividades de prestaci�n de servicios sanitarios,\neducativos, culturales y otros servicios sociales, excepto seguridad social"
    },
    {
        "code": "84130",
        "value": "Regulaci�n y facilitaci�n de la actividad econ�mica"
    },
    {
        "code": "84210",
        "value": "Actividades de administraci�n y funcionamiento del Ministerio de\nRelaciones Exteriores"
    },
    {
        "code": "84220",
        "value": "Actividades de defensa"
    },
    {
        "code": "84230",
        "value": "Actividades de mantenimiento del orden p�blico y de seguridad"
    },
    {
        "code": "84300",
        "value": "Actividades de planes de seguridad social de afiliaci�n obligatoria"
    },
    {
        "code": "85101",
        "value": "Guarder�a educativa"
    },
    {
        "code": "85102",
        "value": "Ense�anza preescolar o parvularia"
    },
    {
        "code": "85103",
        "value": "Ense�anza primaria"
    },
    {
        "code": "85104",
        "value": "Servicio de educaci�n preescolar y primaria integrada"
    },
    {
        "code": "85211",
        "value": "Ense�anza secundaria tercer ciclo (7�, 8� y 9�)"
    },
    {
        "code": "85212",
        "value": "Ense�anza secundaria de formaci�n general bachillerato"
    },
    {
        "code": "85221",
        "value": "Ense�anza secundaria de formaci�n t�cnica y profesional"
    },
    {
        "code": "85222",
        "value": "Ense�anza secundaria de formaci�n t�cnica y profesional integrada\ncon ense�anza primaria"
    },
    {
        "code": "85301",
        "value": "Ense�anza superior universitaria"
    },
    {
        "code": "85302",
        "value": "Ense�anza superior no universitaria"
    },
    {
        "code": "85303",
        "value": "Ense�anza superior integrada a educaci�n secundaria y/o primaria"
    },
    {
        "code": "85410",
        "value": "Educaci�n deportiva y recreativa"
    },
    {
        "code": "85420",
        "value": "Educaci�n cultural"
    },
    {
        "code": "85490",
        "value": "Otros tipos de ense�anza n.c.p."
    },
    {
        "code": "85499",
        "value": "Ense�anza formal"
    },
    {
        "code": "85500",
        "value": "Servicios de apoyo a la ense�anza"
    },
    {
        "code": "86100",
        "value": "Actividades de hospitales"
    },
    {
        "code": "86201",
        "value": "Cl�nicas m�dicas"
    },
    {
        "code": "86202",
        "value": "Servicios de Odontolog�a"
    },
    {
        "code": "86203",
        "value": "Servicios m�dicos"
    },
    {
        "code": "86901",
        "value": "Servicios de an�lisis y estudios de diagn�stico"
    },
    {
        "code": "86902",
        "value": "Actividades de atenci�n de la salud humana"
    },
    {
        "code": "86909",
        "value": "Otros Servicio relacionados con la salud ncp"
    },
    {
        "code": "87100",
        "value": "Residencias de ancianos con atenci�n de enfermer�a"
    },
    {
        "code": "87200",
        "value": "Instituciones dedicadas al tratamiento del retraso mental,\nproblemas de salud mental y el uso indebido de sustancias nocivas"
    },
    {
        "code": "87300",
        "value": "Instituciones dedicadas al cuidado de ancianos y discapacitados"
    },
    {
        "code": "87900",
        "value": "Actividades de asistencia a ni�os y j�venes"
    },
    {
        "code": "87901",
        "value": "Otras actividades de atenci�n en instituciones"
    },
    {
        "code": "88100",
        "value": "Actividades de asistencia sociales sin alojamiento para ancianos y\ndiscapacitados"
    },
    {
        "code": "88900",
        "value": "Servicios sociales sin alojamiento ncp"
    },
    {
        "code": "90000",
        "value": "Actividades creativas art�sticas y de esparcimiento"
    },
    {
        "code": "91010",
        "value": "Actividades de bibliotecas y archivos"
    },
    {
        "code": "91020",
        "value": "Actividades de museos y preservaci�n de lugares y edificios\nhist�ricos"
    },
    {
        "code": "91030",
        "value": "Actividades de jardines bot�nicos, zool�gicos y de reservas naturales"
    },
    {
        "code": "92000",
        "value": "Actividades de juegos y apuestas"
    },
    {
        "code": "93110",
        "value": "Gesti�n de instalaciones deportivas"
    },
    {
        "code": "93120",
        "value": "Actividades de clubes deportivos"
    },
    {
        "code": "93190",
        "value": "Otras actividades deportivas"
    },
    {
        "code": "93210",
        "value": "Actividades de parques de atracciones y parques tem�ticos"
    },
    {
        "code": "93291",
        "value": "Discotecas y salas de baile"
    },
    {
        "code": "93298",
        "value": "Centros vacacionales"
    },
    {
        "code": "93299",
        "value": "Actividades de esparcimiento ncp"
    },
    {
        "code": "94110",
        "value": "Actividades de organizaciones empresariales y de empleadores"
    },
    {
        "code": "94120",
        "value": "Actividades de organizaciones profesionales"
    },
    {
        "code": "94200",
        "value": "Actividades de sindicatos"
    },
    {
        "code": "94910",
        "value": "Actividades de organizaciones religiosas"
    },
    {
        "code": "94920",
        "value": "Actividades de organizaciones pol�ticas"
    },
    {
        "code": "94990",
        "value": "Actividades de asociaciones n.c.p."
    },
    {
        "code": "95110",
        "value": "Reparaci�n de computadoras y equipo perif�rico"
    },
    {
        "code": "95120",
        "value": "Reparaci�n de equipo de comunicaci�n"
    },
    {
        "code": "95210",
        "value": "Reparaci�n de aparatos electr�nicos de consumo"
    },
    {
        "code": "95220",
        "value": "Reparaci�n de aparatos dom�stico y equipo de hogar y jard�n"
    },
    {
        "code": "95230",
        "value": "Reparaci�n de calzado y art�culos de cuero"
    },
    {
        "code": "95240",
        "value": "Reparaci�n de muebles y accesorios para el hogar"
    },
    {
        "code": "95291",
        "value": "Reparaci�n de Instrumentos musicales"
    },
    {
        "code": "95292",
        "value": "Servicios de cerrajer�a y copiado de llaves"
    },
    {
        "code": "95293",
        "value": "Reparaci�n de joyas y relojes"
    },
    {
        "code": "95294",
        "value": "Reparaci�n de bicicletas, sillas de ruedas y rodados n.c.p."
    },
    {
        "code": "95299",
        "value": "Reparaciones de enseres personales n.c.p."
    },
    {
        "code": "96010",
        "value": "Lavado y limpieza de prendas de tela y de piel, incluso la limpieza\nen seco"
    },
    {
        "code": "96020",
        "value": "Peluquer�a y otros tratamientos de belleza"
    },
    {
        "code": "96030",
        "value": "Pompas f�nebres y actividades conexas"
    },
    {
        "code": "96091",
        "value": "Servicios de sauna y otros servicios para la est�tica corporal n.c.p."
    },
    {
        "code": "96092",
        "value": "Servicios n.c.p."
    },
    {
        "code": "97000",
        "value": "Actividad de los hogares en calidad de empleadores de personal\ndom�stico"
    },
    {
        "code": "98100",
        "value": "Actividades indiferenciadas de producci�n de bienes de los hogares\nprivados para uso propio"
    },
    {
        "code": "98200",
        "value": "Actividades indiferenciadas de producci�n de servicios de los\nhogares privados para uso propio"
    },
    {
        "code": "99000",
        "value": "Actividades de organizaciones y �rganos extraterritoriales"
    },
    {
        "code": "10001",
        "value": "Empleados"
    },
    {
        "code": "10002",
        "value": "Pensionado"
    },
    {
        "code": "10003",
        "value": "Estudiante"
    },
    {
        "code": "10004",
        "value": "Desempleado"
    },
    {
        "code": "10005",
        "value": "Otros"
    },
    {
        "code": "10006",
        "value": "Comerciante"
    }
]

export const tipo_documento = [
    {
        "code": "36",
        "value": "NIT"
    },
    {
        "code": "13",
        "value": "DUI"
    },
    {
        "code": "37",
        "value": "Otro"
    },
    {
        "code": "03",
        "value": "Pasaporte"
    },
    {
        "code": "02",
        "value": "Carnet de Residente"
    }
]

export const paises = [
    {
        "code": "AF",
        "value": "AFGANIST�N"
    },
    {
        "code": "AX",
        "value": "ALAND"
    },
    {
        "code": "AL",
        "value": "ALBANIA"
    },
    {
        "code": "DE",
        "value": "ALEMANIA"
    },

    {
        "code": "AD",
        "value": "ANDORRA"
    },
    {
        "code": "AO",
        "value": "ANGOLA"
    },
    {
        "code": "AI",
        "value": "ANGUILA"
    },
    {
        "code": "AQ",
        "value": "ANTARTICA"
    },
    {
        "code": 9898,
        "value": "BONAIRE, SINT EUSTATIUS AND SABA"
    },
    {
        "code": "AG",
        "value": "ANTIG Y BARBUDA"
    },
    {
        "code": "SA",
        "value": "ARABIA SAUDITA"
    },
    {
        "code": "DZ",
        "value": "ARGELIA"
    },
    {
        "code": "AR",
        "value": "ARGENTINA"
    },
    {
        "code": "AW",
        "value": "ARUBA"
    },
    {
        "code": "AU",
        "value": "AUSTRALIA"
    },
    {
        "code": "AT",
        "value": "AUSTRIA"
    },
    {
        "code": "AZ",
        "value": "AZERBAIY�N"
    },
    {
        "code": "BS",
        "value": "BAHAMAS"
    },
    {
        "code": "BH",
        "value": "BAHR�IN"
    },
    {
        "code": "BD",
        "value": "BANGLADESH"
    },
    {
        "code": "BB",
        "value": "BARBADOS"
    },
    {
        "code": "BE",
        "value": "B�LGICA"
    },
    {
        "code": "BZ",
        "value": "BELICE"
    },
    {
        "code": "BJ",
        "value": "BEN�N"
    },
    {
        "code": "BM",
        "value": "BERMUDAS"
    },
    {
        "code": "BY",
        "value": "BIELORRUSIA"
    },
    {
        "code": "MM",
        "value": "MYANMAR"
    },
    {
        "code": "BO",
        "value": "BOLIVIA"
    },
    {
        "code": "BA",
        "value": "BOSNIA-HERZEGOVINA"
    },
    {
        "code": "BW",
        "value": "BOTSWANA"
    },
    {
        "code": "BR",
        "value": "BRASIL"
    },
    {
        "code": "BN",
        "value": "BRUN�I"
    },
    {
        "code": "BG",
        "value": "BULGARIA"
    },
    {
        "code": "BF",
        "value": "BURKINA FASO"
    },
    {
        "code": "BI",
        "value": "BURUNDI"
    },
    {
        "code": "BT",
        "value": "BUT�N"
    },
    {
        "code": "CV",
        "value": "CABO VERDE"
    },
    {
        "code": "KH",
        "value": "CAMBOYA"
    },
    {
        "code": "CM",
        "value": "CAMER�N"
    },
    {
        "code": "CA",
        "value": "CANAD�"
    },
    {
        "code": "TD",
        "value": "CHAD"
    },
    {
        "code": "CL",
        "value": "CHILE"
    },
    {
        "code": "CN",
        "value": "CHINA"
    },
    {
        "code": "CY",
        "value": "CHIPRE"
    },
    {
        "code": "CO",
        "value": "COLOMBIA"
    },
    {
        "code": "KM",
        "value": "COMORAS"
    },
    {
        "code": "CG",
        "value": "CONGO"
    },
    {
        "code": "CD",
        "value": "REPUBLICA DEMOCRATICA DEL"
    },
    {
        "code": "KP",
        "value": "REP. DEMOCRATICA POPULAR DE"
    },
    {
        "code": "KR",
        "value": "REPUBLICA DE COREA"
    },
    {
        "code": "CI",
        "value": "COSTA DE MARFIL"
    },
    {
        "code": "CR",
        "value": "COSTA RICA"
    },
    {
        "code": "HR",
        "value": "CROACIA"
    },
    {
        "code": "CF",
        "value": "CENTROAFRICANA, REPUBLICA"
    },
    {
        "code": "CU",
        "value": "CUBA"
    },
    {
        "code": "CW",
        "value": "CURAZAO"
    },
    {
        "code": "DK",
        "value": "DINAMARCA"
    },
    {
        "code": "DJ",
        "value": "DJIBOUTI"
    },
    {
        "code": "DM",
        "value": "DOMINICA"
    },
    {
        "code": "DO",
        "value": "REPUBLICA DOMINICANA"
    },
    {
        "code": "EC",
        "value": "ECUADOR"
    },
    {
        "code": "US",
        "value": "ESTADOS UNIDOS"
    },
    {
        "code": "EG",
        "value": "EGIPTO"
    },
    {
        "code": "SV",
        "value": "EL SALVADOR"
    },
    {
        "code": "AE",
        "value": "EMIRATOS ARABES UNIDOS"
    },
    {
        "code": "ER",
        "value": "ERITREA"
    },
    {
        "code": "SK",
        "value": "ESLOVAQUIA"
    },
    {
        "code": "SI",
        "value": "ESLOVENIA"
    },
    {
        "code": "ES",
        "value": "ESPA�A"
    },
    {
        "code": "EE",
        "value": "ESTONIA"
    },
    {
        "code": "ET",
        "value": "ETIOPIA"
    },
    {
        "code": "FJ",
        "value": "FIJI"
    },
    {
        "code": "PH",
        "value": "FILIPINAS"
    },
    {
        "code": "FI",
        "value": "FINLANDIA"
    },
    {
        "code": "FR",
        "value": "FRANCIA"
    },
    {
        "code": "GA",
        "value": "GAB�N"
    },
    {
        "code": "GM",
        "value": "GAMBIA"
    },
    {
        "code": "GE",
        "value": "GEORGIA"
    },
    {
        "code": "GH",
        "value": "GHANA"
    },
    {
        "code": "GI",
        "value": "GIBRALTAR"
    },
    {
        "code": "GR",
        "value": "GRECIA"
    },
    {
        "code": "GD",
        "value": "GRANADA"
    },
    {
        "code": "GL",
        "value": "GROENLANDIA"
    },
    {
        "code": "GP",
        "value": "GUADALUPE"
    },
    {
        "code": "GU",
        "value": "GUAM"
    },
    {
        "code": "GT",
        "value": "GUATEMALA"
    },
    {
        "code": "GF",
        "value": "GUAYANA FRANCESA"
    },
    {
        "code": "GG",
        "value": "GUERNSEY"
    },
    {
        "code": "GN",
        "value": "GUINEA"
    },
    {
        "code": "GQ",
        "value": "GUINEA ECUATORIAL"
    },
    {
        "code": "GW",
        "value": "GUINEA-BISSAU"
    },
    {
        "code": "GW",
        "value": "GUYANA"
    },
    {
        "code": "HT",
        "value": "HAIT�"
    },
    {
        "code": "NL",
        "value": "PAISES BAJOS"
    },
    {
        "code": "HN",
        "value": "HONDURAS"
    },
    {
        "code": "HK",
        "value": "HONG KONG"
    },
    {
        "code": "HU",
        "value": "HUNGR�A"
    },
    {
        "code": "IN",
        "value": "INDIA"
    },
    {
        "code": "ID",
        "value": "INDONESIA"
    },
    {
        "code": "IQ",
        "value": "IRAK"
    },
    {
        "code": "IR",
        "value": "REPUBLICA ISLAMICA DE IR�N"
    },
    {
        "code": "IE",
        "value": "IRLANDA"
    },
    {
        "code": "BV",
        "value": "ISLA BOUVET"
    },
    {
        "code": "CC",
        "value": "ISLAS COCOS"
    },
    {
        "code": "IM",
        "value": "ISLA DE MAN"
    },
    {
        "code": "CX",
        "value": "ISLAS NAVIDAD"
    },
    {
        "code": "IS",
        "value": "ISLANDIA"
    },
    {
        "code": 9530,
        "value": "ISLAS AZORES"
    },
    {
        "code": "CK",
        "value": "ISLAS COOK"
    },
    {
        "code": "FO",
        "value": "ISLAS FAROE"
    },
    {
        "code": "GS",
        "value": "ISLAS GEORGIAS D. S.-SANDWICH D. S."
    },
    {
        "code": "HM",
        "value": "ISLAS HEARD Y MCDONALD"
    },
    {
        "code": "KY",
        "value": "CAIMAN, ISLAS"
    },
    {
        "code": "FK",
        "value": "ISLAS MALVINAS (FALKLAND)"
    },
    {
        "code": "MP",
        "value": "ISLAS MARIANAS DEL NORTE"
    },
    {
        "code": "MH",
        "value": "ISLAS MARSHALL"
    },
    {
        "code": "PN",
        "value": "ISLAS PITCAIRN"
    },
    {
        "code": "SB",
        "value": "SALOM�N, ISLAS "
    },
    {
        "code": "TC",
        "value": "ISLAS TURCAS Y CAICOS"
    },
    {
        "code": "UM",
        "value": "ISLAS ULTRAMARINAS DE EE UU"
    },
    {
        "code": "VG",
        "value": "ISLAS V�RGENES BRIT�NICAS"
    },
    {
        "code": "VI",
        "value": "ISLAS V�RGENES"
    },
    {
        "code": "IL",
        "value": "ISRAEL"
    },
    {
        "code": "IT",
        "value": "ITALIA"
    },
    {
        "code": "JM",
        "value": "JAMAICA"
    },
    {
        "code": "JP",
        "value": "JAP�N"
    },
    {
        "code": "JE",
        "value": "JERSEY"
    },
    {
        "code": "JO",
        "value": "JORDANIA"
    },
    {
        "code": "KZ",
        "value": "KAZAJISTAN"
    },
    {
        "code": "KE",
        "value": "KENIA"
    },
    {
        "code": "KG",
        "value": "KIRGUIST�N"
    },
    {
        "code": "KI",
        "value": "KIRIBATI"
    },
    {
        "code": "KW",
        "value": "KUWAIT"
    },
    {
        "code": "LA",
        "value": "LAOS, REPUBLICA DEMOCRATICA"
    },
    {
        "code": "LS",
        "value": "LESOTHO"
    },
    {
        "code": "LV",
        "value": "LETONIA"
    },
    {
        "code": "LB",
        "value": "L�BANO"
    },
    {
        "code": "LR",
        "value": "LIBERIA"
    },
    {
        "code": "LY",
        "value": "LIBIA"
    },
    {
        "code": "LI",
        "value": "LIECHTENSTEIN"
    },
    {
        "code": "LT",
        "value": "LITUANIA"
    },
    {
        "code": "LU",
        "value": "LUXEMBURGO"
    },
    {
        "code": "MO",
        "value": "MACAO"
    },
    {
        "code": "MK",
        "value": "MACEDONIA"
    },
    {
        "code": "MG",
        "value": "MADAGASCAR"
    },
    {
        "code": "MY",
        "value": "MALASIA"
    },
    {
        "code": "MW",
        "value": "MALAWI"
    },
    {
        "code": "MV",
        "value": "MALDIVAS"
    },
    {
        "code": "ML",
        "value": "MALI"
    },
    {
        "code": "MT",
        "value": "MALTA"
    },
    {
        "code": "MA",
        "value": "MARRUECOS"
    },
    {
        "code": "MQ",
        "value": "MARTINICA E.A"
    },
    {
        "code": "OM",
        "value": "OMAN"
    },
    {
        "code": "MU",
        "value": "MAURICIO"
    },
    {
        "code": "MR",
        "value": "MAURITANIA"
    },
    {
        "code": "YT",
        "value": "MAYOTTE"
    },
    {
        "code": "MX",
        "value": "M�XICO"
    },
    {
        "code": "FM",
        "value": "MICRONESIA"
    },
    {
        "code": "MD",
        "value": "MOLDAVIA, REPUBLICA DE"
    },
    {
        "code": "MC",
        "value": "M�NACO"
    },
    {
        "code": "MN",
        "value": "MONGOLIA"
    },
    {
        "code": "MS",
        "value": "MONSERRAT"
    },
    {
        "code": "ME",
        "value": "MONTENEGRO"
    },
    {
        "code": "MZ",
        "value": "MOZAMBIQUE"
    },
    {
        "code": "NA",
        "value": "NAMIBIA"
    },
    {
        "code": "NR",
        "value": "NAURU"
    },
    {
        "code": "NP",
        "value": "NEPAL"
    },
    {
        "code": "NI",
        "value": "NICARAGUA"
    },
    {
        "code": "NE",
        "value": "N�GER"
    },
    {
        "code": "NG",
        "value": "NIGERIA"
    },
    {
        "code": "NU",
        "value": "NIUE"
    },
    {
        "code": "NF",
        "value": "ISLA NORFOLK"
    },
    {
        "code": "NO",
        "value": "NORUEGA"
    },
    {
        "code": "NC",
        "value": "NUEVA CALEDONIA"
    },
    {
        "code": "NZ",
        "value": "NUEVA ZELANDIA"
    },
    {
        "code": "PK",
        "value": "PAKIST�N"
    },
    {
        "code": "PW",
        "value": "PALAOS"
    },
    {
        "code": "PS",
        "value": "PALESTINA"
    },
    {
        "code": "PA",
        "value": "PANAM�"
    },
    {
        "code": "PG",
        "value": "PAPUA, NUEVA GUINEA"
    },
    {
        "code": "PY",
        "value": "PARAGUAY"
    },
    {
        "code": "PE",
        "value": "PER�"
    },
    {
        "code": "PF",
        "value": "POLINESIA FRANCESA"
    },
    {
        "code": "PL",
        "value": "POLONIA"
    },
    {
        "code": "PT",
        "value": "PORTUGAL"
    },
    {
        "code": "PR",
        "value": "PUERTO RICO"
    },
    {
        "code": "QA",
        "value": "QATAR"
    },
    {
        "code": "GB",
        "value": "REINO UNIDO"
    },
    {
        "code": "CZ",
        "value": "REPUBLICA CHECA"
    },
    {
        "code": "AM",
        "value": "ARMENIA"
    },
    {
        "code": "RE",
        "value": "REUNI�N"
    },
    {
        "code": "RW",
        "value": "RUANDA"
    },
    {
        "code": "RO",
        "value": "RUMANIA"
    },
    {
        "code": "RU",
        "value": "RUSIA"
    },
    {
        "code": "EH",
        "value": "SAHARA OCCIDENTAL"
    },
    {
        "code": "BL",
        "value": "SAINT BARTHELEMY"
    },
    {
        "code": "MF",
        "value": "SAINT MARTIN (FRENCH PART)"
    },
    {
        "code": "KN",
        "value": "SAN CRISTOBAL Y NIEVES"
    },
    {
        "code": "AS",
        "value": "SAMOA AMERICANA"
    },
    {
        "code": "WS",
        "value": "SAMOA"
    },
    {
        "code": "SX",
        "value": "SINT MAARTEN (DUTCH PART)"
    },
    {
        "code": "SM",
        "value": "SAN MARINO"
    },
    {
        "code": "PM",
        "value": "SAN PEDRO Y MIQUEL�N"
    },
    {
        "code": "SH",
        "value": "SANTA ELENA"
    },
    {
        "code": "LC",
        "value": "SANTA LUCIA"
    },
    {
        "code": "ST",
        "value": "SANTO TOME Y PRINCIPE"
    },
    {
        "code": "SN",
        "value": "SENEGAL"
    },
    {
        "code": "RS",
        "value": "SERBIA"
    },
    {
        "code": "SC",
        "value": "SEYCHELLES"
    },
    {
        "code": "SL",
        "value": "SIERRA LEONA"
    },
    {
        "code": "SG",
        "value": "SINGAPUR"
    },
    {
        "code": "SY",
        "value": "SIRIA"
    },
    {
        "code": "VC",
        "value": "SAN VICENTE Y LAS GRANADINAS"
    },
    {
        "code": "SO",
        "value": "SOMALIA"
    },
    {
        "code": "SS",
        "value": "SOUTH SUDAN"
    },
    {
        "code": "LK",
        "value": "SRI LANKA"
    },
    {
        "code": "ZA",
        "value": "SUD�FRICA"
    },
    {
        "code": "SD",
        "value": "SUDAN"
    },
    {
        "code": "SE",
        "value": "SUECIA"
    },
    {
        "code": "SZ",
        "value": "SWAZILANDIA"
    },
    {
        "code": "CH",
        "value": "SUIZA"
    },
    {
        "code": "SR",
        "value": "SURINAM"
    },
    {
        "code": "SJ",
        "value": "SVALBARD Y JAN MAYEN"
    },
    {
        "code": "TH",
        "value": "TAILANDIA"
    },
    {
        "code": "TW",
        "value": "TAIW�N, PROVINCIA DE CHINA"
    },
    {
        "code": "TZ",
        "value": "TANZANIA, REPUBLICA UNIDA DE"
    },
    {
        "code": "TJ",
        "value": "TAYIKIST�N"
    },
    {
        "code": "IO",
        "value": "TERRITORIO BRIT�NICO DEL OC�ANO INDICO"
    },
    {
        "code": "TF",
        "value": "TERRITORIOS AUSTRALES FRANCESES"
    },
    {
        "code": "TL",
        "value": "TIMOR ORIENTAL"
    },
    {
        "code": "TG",
        "value": "TOGO"
    },
    {
        "code": "TK",
        "value": "TOKELAU"
    },
    {
        "code": "TO",
        "value": "TONGA"
    },
    {
        "code": "TT",
        "value": "TRINIDAD Y TOBAGO"
    },
    {
        "code": "TN",
        "value": "T�NEZ"
    },
    {
        "code": "TM",
        "value": "TURKMENIST�N"
    },
    {
        "code": "TR",
        "value": "TURQU�A"
    },
    {
        "code": "TV",
        "value": "TUVALU"
    },
    {
        "code": "UA",
        "value": "UCRANIA"
    },
    {
        "code": "UG",
        "value": "UGANDA"
    },
    {
        "code": "UY",
        "value": "URUGUAY"
    },
    {
        "code": "UZ",
        "value": "UZBEKIST�N"
    },
    {
        "code": "VU",
        "value": "VANUATU"
    },
    {
        "code": "VA",
        "value": "CIUDAD DEL VATICANO"
    },
    {
        "code": "VE",
        "value": "VENEZUELA"
    },
    {
        "code": "VN",
        "value": "VIETNAM"
    },
    {
        "code": "WF",
        "value": "WALLIS Y FUTUNA, ISLAS"
    },
    {
        "code": "YE",
        "value": "YEMEN"
    },
    {
        "code": "ZM",
        "value": "ZAMBIA"
    },
    {
        "code": "ZW",
        "value": "ZIMBABUE"
    }
]